import {
  SET_CURRENT_TAB,
  SET_FILTERS,
  SET_PAGE,
  SET_SORT_TYPE
} from "fleksRedux/types";

const initialState = {
  clients: {
    filters: false,
    page: { totalPages: 1, top: 1, current: 1 },
    sortType: "sort",
    sortBy: "name"
  },
  projects: {
    currentTab: "running",
    filters: false,
    page: { totalPages: 1, top: 1, current: 1 },
    sortType: "sort",
    sortBy: "title"
  },
  jobs: {
    currentTab: "pending",
    filters: false,
    page: { totalPages: 1, top: 1, current: 1 },
    sortType: "sort",
    sortBy: "start_date"
  },
  shifts: {
    currentTab: "upcoming",
    filters: false,
    page: { totalPages: 1, top: 1, current: 1 },
    sortType: "sort",
    sortBy: "start_date"
  },
  employees: {
    filters: false,
    page: { totalPages: 1, top: 1, current: 1 },
    sortType: "sort",
    sortBy: "first_name"
  },
  applicants: {
    currentTab: "accepted",
    filters: false,
    page: { totalPages: 1, top: 1, current: 1 },
    sortType: "sort",
    sortBy: "date_created"
  },
  hours: {
    currentTab: "open",
    filters: false,
    page: { totalPages: 1, top: 1, current: 1 },
    sortType: "sort",
    sortBy: "start_date"
  },
  totals: {
    currentTab: "open",
    filters: false,
    page: { totalPages: 1, top: 1, current: 1 },
    sortType: "sort",
    sortBy: "first_name"
  }
};

const listViews = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CURRENT_TAB:
      if (action.view) {
        return Object.assign({}, state, {
          [action.view]: Object.assign({}, state[action.view], {
            currentTab: action.tab,
            filters: state[action.view].filters
          })
        });
      } else {
        return state;
      }

    case SET_FILTERS:
      if (action.view) {
        return Object.assign({}, state, {
          [action.view]: Object.assign({}, state[action.view], {
            currentTab: state[action.view].currentTab,
            filters: action.filters
          })
        });
      } else {
        return state;
      }

    case SET_PAGE:
      if (action.view) {
        return Object.assign({}, state, {
          [action.view]: Object.assign({}, state[action.view], {
            page: {
              current: action.page,
              top: action.top ? action.top : state[action.view].page.top,
              totalPages: action.totalPages
                ? action.totalPages
                : state[action.view].page.totalPages
            }
          })
        });
      } else {
        return state;
      }

    case SET_SORT_TYPE:
      if (action.view) {
        let newState = Object.assign({}, state, {
          [action.view]: Object.assign({}, state[action.view], {
            sortType: action.sortType
          })
        });
        if (action.sortBy) {
          newState[action.view].sortBy = action.sortBy;
        }
        return newState;
      } else {
        return state;
      }

    default:
      return state;
  }
};

export default listViews;
