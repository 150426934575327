import { SHOW_NEW_JOB, SET_STEP_ONE_COMPLETED } from "fleksRedux/types";
import { combineReducers } from "redux";
import jobInfo from "./jobInfo";

const show = (state = false, action = {}) => {
  switch (action.type) {
    case SHOW_NEW_JOB:
      return action.data;

    default:
      return state;
  }
};

const stepOneCompleted = (state = false, action = {}) => {
  switch (action.type) {
    case SET_STEP_ONE_COMPLETED:
      return action.data;

    default:
      return state;
  }
};

const newJob = combineReducers({
  show,
  stepOneCompleted,
  jobInfo
});

export default newJob;
