import {SET_DATABASE_TABLE_VALUES, SET_SHARED_TYPE, USER_LOGIN} from "../../../types";

export const rebuildSortKey = (tableName) => {
    return (state = '', action = {}) => {
        switch (action.type) {
            case SET_DATABASE_TABLE_VALUES:
                return action?.data?.table === tableName && action?.data?.rebuildSortKey
                    ? action.data.rebuildSortKey
                    : state;

            case USER_LOGIN:
                return action?.data?.preferences?.database &&
                typeof action?.data?.preferences?.database?.[tableName]?.rebuildSortKey !== "undefined"
                    ? action.data.preferences.database[tableName]?.rebuildSortKey
                    : state;

            default:
                return state;
        }
    };
};

export const sharedType = (state = 'combined', action = {}) => {
    switch (action.type) {
        case SET_SHARED_TYPE:
            if(!isValidSharedType(action?.data?.sharedType)){
                return state
            }
            return action?.data?.sharedType

        default:
            return state;
    }
};
const validSharedTypes = ['internal', 'shared', 'combined']

function isValidSharedType(sharedType){
    if (typeof sharedType !== 'string') {
        return false;
    }
    return validSharedTypes.includes(sharedType)
}

