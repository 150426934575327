import {useEffect, useState, useRef, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
/* Custom Hooks */
import useActions, {useCancellablePromise} from "hooks";
/* Redux Actions */
import {
    setPlannerFilters,
    setShowFilters,
    resetFilters,
    setTemporalPreferences,
    setPreferencesFromTemp,
    setShowSidebar,
    setSelectedJob,
    setSelectedCluster,
    setClusters,
    setShowNewJob,
    setStepOneCompleted,
    setNewJobData,
    setJobOnPlanner,
    setPlannerSidebarReload,
    checkifData,
} from "fleksRedux/actions/planner";
/* Services */
import {plannerService} from "services";
/* Utils */
import {makeCancelable} from "utils/helpers";
import {changeTzTo} from "utils/helpers";
import getTypeOfUser from "./getTypeOfUser/getTypeOfUser";
import reformatToOldJob from "../services/rebuild/reformat/reformatToOldJob";
import _ from "lodash";

export function useCurrentDate() {
    const userLangCode = useSelector((state) => {
        return state.user.authenticated &&
        state.user.data &&
        state.user.data.current_language
            ? state.user.data.current_language.code
            : "nl";
    });
    const currentDay = useSelector((state) => {
        return state.planner.filters.week;
    });
    const setPlannerFiltersAction = useActions(setPlannerFilters);

    return {
        currentDay: moment(currentDay).locale(userLangCode),
        currentWeek: moment(currentDay).isoWeek(),
        setCurrentDay: (day) => setPlannerFiltersAction({week: day}),
        isToday: moment().isoWeek() === moment(currentDay).isoWeek(),
    };
}

export function useSearchFilters(newPlannerFilters = false) {
    const {client, project, city, projectManager} = useSelector((state) => {
        return state.planner.filters;
    });
    const [setPlannerFiltersAction, setResetFiltersAction] = useActions([
        setPlannerFilters,
        resetFilters,
    ]);

    return {
        jobsFilters: newPlannerFilters ?
            {
                client: {
                    key: "clientName",
                    value: client,
                    intlName: "Planner.filters.client",
                },
                project: {
                    key: "projectTitle",
                    value: project,
                    intlName: "Planner.filters.project",
                },
                projectManager: {
                    key: "projectManager",
                    value: projectManager,
                    intlName: "Planner.filters.projectManager",
                },
                city: {
                    key: "address",
                    value: city,
                    intlName: "Planner.filters.city",
                },
            } : {
                client: {
                    key: "brand__name",
                    value: client,
                    prefix: "job__",
                    suffix: "__icontains",
                    intlName: "Planner.filters.client",
                },
                project: {
                    key: "title",
                    value: project,
                    prefix: "job__",
                    suffix: "__icontains",
                    intlName: "Planner.filters.project",
                },
                projectManager: {
                    key: "project_manager",
                    value: projectManager,
                    prefix: "job__",
                    suffix: "__icontains",
                    intlName: "Planner.filters.projectManager",
                },
                city: {
                    key: "full_address",
                    value: city,
                    prefix: "",
                    suffix: "__icontains",
                    intlName: "Planner.filters.city",
                },
            },
        setSearchFilters: ({client, project, projectManager, city, week, sharedType}) =>
            setPlannerFiltersAction({client, project, projectManager, city, week, sharedType}),
        resetFilters: (partialFilters) => setResetFiltersAction(partialFilters),
    };
}

export function useShowFilters() {
    const showFilters = useSelector((state) => {
        return state.planner.showFilters;
    });
    const setShowFiltersAction = useActions(setShowFilters);

    return {
        showFilters,
        setShowFilters: (showFilters) => setShowFiltersAction(showFilters),
    };
}

export function useGetJobGroups(selectedGrouping) {
    const [jobGroups, setJobGroups] = useState([])
    const jobShareType = useSelector(state => state.database?.sharedType);
    const user = useSelector((state) => {
        return state.user;
    });
    let {jobsFilters} = useSearchFilters(true);

    const {currentDay} = useCurrentDate();
    const currentDayClone = currentDay.clone();
    useEffect(() => {
        if (!user.authenticated) return;
        plannerService.getJobGroups(
          user.data.token,
          selectedGrouping,
          currentDayClone.startOf("isoWeek").format("DD-MM-YYYY"),
          currentDayClone.endOf("isoWeek").format("DD-MM-YYYY"),
          {
              ...jobsFilters,
              ...{sharedType: {value: jobShareType, key: 'sharedType'}}
          }
        ).then((res) => {
           setJobGroups(res?.data?.data)
        })
    }, [
        user.authenticated,
        currentDay.isoWeek(),
        selectedGrouping,
        jobsFilters.client.value,
        jobsFilters.project.value,
        jobsFilters.projectManager.value,
        jobsFilters.city.value]
    );

    return { jobGroups }
}

export function useJobsGroupData(extraFilters) {
    const user = useSelector((state) => {
        return state.user;
    });
    const [clusterJobs, setClusterJobs] = useState({open:[], planned: []});
    const jobShareType = useSelector(state => state.database?.sharedType);
    const setReduxClusterJobs = useActions(setClusters);
    const {currentDay} = useCurrentDate();
    const {jobsFilters} = useSearchFilters(true);

    let filters = jobsFilters
    if (extraFilters) {
        filters = {...jobsFilters, ...extraFilters}
    }

    const [loading, setLoading] = useState(false);
    let getJobWithRange = null;
    let getJobWithRangeV2 = null;

    function cancelRequest() {
        if (getJobWithRange) {
            getJobWithRange.cancel();
        } else if (getJobWithRangeV2) {
            getJobWithRangeV2.cancel();
        }
    }

    useEffect(() => {
        if (!user.authenticated) return;
        const currentDayClone = currentDay.clone();

        cancelRequest();

        getJobWithRangeV2 = makeCancelable(
          plannerService.getJobWithRangeV2(
            user.data.token,
            currentDayClone.startOf("isoWeek").format("DD-MM-YYYY"),
            currentDayClone.endOf("isoWeek").format("DD-MM-YYYY"),
            true,
            {
                ...filters,
                ...{sharedType: {value: jobShareType, key: 'sharedType'}}
            }
          )
        )

        setClusterJobs({open:[], planned: []});
        setLoading(true);

        getJobWithRangeV2.promise.then((jobs) => {
            const mapNewJobsToOldClusters = (jobs) => {
                const lastIndex = jobs.length - 1
                let dayJobs = [];
                let currentDayDate = null;
                let currentDayObject = null;
                let dayTotals = {
                    totalFilledJobs: 0,
                    totalAcceptedUsers: 0,
                    totalAppliedUsers: 0,
                    totalInvitedUsers: 0,
                    totalEmployees: 0,
                }

                return jobs.reduce((acc, newJob, index) => {
                    //TODO: Refactor this

                    // Pushes new day with jobs and resets all values
                    const pushNewJobDay = () => {
                        currentDayObject = {
                            category: null,
                            client: null,
                            day: moment(currentDayDate, 'DD-MM-YYYY').format("YYYY-MM-DD"),
                            jobs: dayJobs,
                            pending_spots: dayTotals.totalEmployees - dayTotals.totalAcceptedUsers,
                            project: null,
                            time: null,
                            total: dayJobs.length,
                            total_accepted_users: dayTotals.totalAcceptedUsers,
                            total_applied_users: dayTotals.totalAppliedUsers,
                            total_filled_jobs: dayTotals.totalFilledJobs,
                            total_invited_users: dayTotals.totalInvitedUsers
                        }
                        acc.push(currentDayObject);

                        // Reset dayJobs and refill it for the new job
                        dayJobs = []

                        // Reset total accepted users as well
                        dayTotals.totalAcceptedUsers = 0;
                        //Reset total applied user as well
                        dayTotals.totalAppliedUsers = 0;
                        // Reset total filled jobs as well
                        dayTotals.totalFilledJobs = 0;
                        // Total employees of day set to 0
                        dayTotals.totalEmployees = 0;
                        //Total invited users
                        dayTotals.totalInvitedUsers = 0;
                    }
                    const oldJob = reformatToOldJob(newJob)
                    const jobStartDate = oldJob.start_date.split(" ")[0]

                    // Fist day is equal to job start date
                    if (currentDayDate === null) {
                        currentDayDate = jobStartDate;
                    }

                    if (index === lastIndex) {
                        if (jobStartDate !== currentDayDate) {
                            pushNewJobDay();
                        }
                        currentDayDate = jobStartDate;
                        dayJobs.push(oldJob);
                        pushNewJobDay();
                        return acc;
                    } else {
                        if (jobStartDate === currentDayDate) {
                            // Count total job values
                            dayJobs.push(oldJob);
                        } else {
                            pushNewJobDay();
                            // Setting the currentDate to the new date
                            currentDayDate = jobStartDate
                            dayJobs.push(oldJob);
                        }
                    }

                    dayTotals.totalFilledJobs = newJob.acceptedUsers === newJob.numberOfEmployees ? dayTotals.totalFilledJobs + 1 : dayTotals.totalFilledJobs;
                    dayTotals.totalAcceptedUsers = dayTotals.totalAcceptedUsers + newJob.acceptedUsers;
                    dayTotals.totalEmployees = dayTotals.totalEmployees + newJob.numberOfEmployees;
                    dayTotals.totalAppliedUsers = dayTotals.totalAppliedUsers + newJob.appliedUsers;
                    dayTotals.totalInvitedUsers = dayTotals.totalInvitedUsers + newJob.totalInvitedUsers;

                    return acc;
                }, [])

            }

            const plannedJobs =  mapNewJobsToOldClusters(jobs?.data?.data?.filter((job) => job.isFull === true) ?? [])
            const openJobs = mapNewJobsToOldClusters(jobs?.data?.data?.filter((job) => job.isFull === false) ?? [])

            setLoading(false);
            setReduxClusterJobs(openJobs);
            setClusterJobs({open: openJobs, planned: plannedJobs});
        }).catch((err) => {
            if (err.isCanceled) return;

            setLoading(false);
        })


        return () => {
            cancelRequest();
        };
    }, [
        currentDay.isoWeek(),
        jobsFilters.client.value,
        jobsFilters.project.value,
        jobsFilters.projectManager.value,
        jobsFilters.city.value
    ]);

    return {currentDay, loading, clusterJobs: clusterJobs};
}

export function useJobsClusters(newJobsEnabled = false) {
    const user = useSelector((state) => {
        return state.user;
    });
    const new_type_of_user = user.data && getTypeOfUser(user.data.roles[0]);
    const showClusterView = useSelector((state) => {
        return state.planner.preferences.showClusterView;
    });
    const includeFullJobs = useSelector((state) => {
        return state.planner.preferences.includeFullJobs;
    });
    const clusterJobs = useSelector((state) => {
        return state.planner.clusters;
    });
    const jobShareType = useSelector(state => state.database?.sharedType);
    const setClusterJobs = useActions(setClusters);
    const {currentDay} = useCurrentDate();
    const {jobsFilters} = useSearchFilters(newJobsEnabled);
    const [loading, setLoading] = useState(false);
    let getJobWithRange = null;
    let getJobWithRangeV2 = null;

    function cancelRequest() {
        if (getJobWithRange) {
            getJobWithRange.cancel();
        } else if (getJobWithRangeV2) {
            getJobWithRangeV2.cancel();
        }
    }

    useEffect(() => {
        if (!user.authenticated) return;
        const currentDayClone = currentDay.clone();

        cancelRequest();

        if (newJobsEnabled) {
            getJobWithRangeV2 = makeCancelable(
                plannerService.getJobWithRangeV2(
                    user.data.token,
                    currentDayClone.startOf("isoWeek").format("DD-MM-YYYY"),
                    currentDayClone.endOf("isoWeek").format("DD-MM-YYYY"),
                    includeFullJobs,
                    {
                        ...jobsFilters,
                        ...{sharedType: {value: jobShareType, key: 'sharedType'}}
                    }
                )
            )
        } else {
            getJobWithRange = makeCancelable(
                plannerService.getJobWithRange(
                    user.data.token,
                    user.data.tenant.uuid,
                    false,
                    currentDayClone.startOf("isoWeek").format("DD-MM-YYYY"),
                    currentDayClone.endOf("isoWeek").format("DD-MM-YYYY"),
                    {
                        clusters: showClusterView,
                        filters: {
                            ...jobsFilters,
                            fullJobs: {
                                key: "is_full",
                                value: includeFullJobs ? "" : "False",
                            },
                        },
                        grouped: true,
                        includeShared: !new_type_of_user ? true : false,
                    }
                )
            )
        }

        setClusterJobs([]);
        setLoading(true);

        if (newJobsEnabled) {
            getJobWithRangeV2.promise.then((jobs) => {
                let dayJobs = [];
                let currentDayDate = null;
                let currentDayObject = null;
                let dayTotals = {
                    totalFilledJobs: 0,
                    totalAcceptedUsers: 0,
                    totalAppliedUsers: 0,
                    totalInvitedUsers: 0,
                    totalEmployees: 0,
                }


                const lastIndex = jobs.data.data.length - 1;
                const groupedJobs = jobs?.data?.data.reduce((acc, newJob, index) => {
                    //TODO: Refactor this

                    // Pushes new day with jobs and resets all values
                    const pushNewJobDay = () => {
                        currentDayObject = {
                            category: null,
                            client: null,
                            day: moment(currentDayDate, 'DD-MM-YYYY').format("YYYY-MM-DD"),
                            jobs: dayJobs,
                            pending_spots: dayTotals.totalEmployees - dayTotals.totalAcceptedUsers,
                            project: null,
                            time: null,
                            total: dayJobs.length,
                            total_accepted_users: dayTotals.totalAcceptedUsers,
                            total_applied_users: dayTotals.totalAppliedUsers,
                            total_filled_jobs: dayTotals.totalFilledJobs,
                            total_invited_users: dayTotals.totalInvitedUsers
                        }
                        acc.push(currentDayObject);

                        // Reset dayJobs and refill it for the new job
                        dayJobs = []

                        // Reset total accepted users as well
                        dayTotals.totalAcceptedUsers = 0;
                        //Reset total applied user as well
                        dayTotals.totalAppliedUsers = 0;
                        // Reset total filled jobs as well
                        dayTotals.totalFilledJobs = 0;
                        // Total employees of day set to 0
                        dayTotals.totalEmployees = 0;
                        //Total invited users
                        dayTotals.totalInvitedUsers = 0;
                    }
                    const oldJob = reformatToOldJob(newJob)
                    const jobStartDate = oldJob.start_date.split(" ")[0]

                    // Fist day is equal to job start date
                    if (currentDayDate === null) {
                        currentDayDate = jobStartDate;
                    }

                    if (index === lastIndex) {
                        if (jobStartDate !== currentDayDate) {
                            pushNewJobDay();
                        }
                        currentDayDate = jobStartDate;
                        dayJobs.push(oldJob);
                        pushNewJobDay();
                        return acc;
                    } else {
                        if (jobStartDate === currentDayDate) {
                            // Count total job values
                            dayJobs.push(oldJob);
                        } else {
                            pushNewJobDay();
                            // Setting the currentDate to the new date
                            currentDayDate = jobStartDate
                            dayJobs.push(oldJob);
                        }
                    }

                    dayTotals.totalFilledJobs = newJob.acceptedUsers === newJob.numberOfEmployees ? dayTotals.totalFilledJobs + 1 : dayTotals.totalFilledJobs;
                    dayTotals.totalAcceptedUsers = dayTotals.totalAcceptedUsers + newJob.acceptedUsers;
                    dayTotals.totalEmployees = dayTotals.totalEmployees + newJob.numberOfEmployees;
                    dayTotals.totalAppliedUsers = dayTotals.totalAppliedUsers + newJob.appliedUsers;
                    dayTotals.totalInvitedUsers = dayTotals.totalInvitedUsers + newJob.totalInvitedUsers;

                    return acc;
                }, [])
                setLoading(false);
                setClusterJobs(groupedJobs);
            }).catch((err) => {
                if (err.isCanceled) return;

                setLoading(false);
            })
        } else {
            getJobWithRange.promise
                .then((clusters) => {
                    setLoading(false);
                    setClusterJobs(clusters);
                })
                .catch((err) => {
                    if (err.isCanceled) return;

                    setLoading(false);
                });
        }


        return () => {
            cancelRequest();
        };
    }, [
        currentDay.isoWeek(),
        jobShareType,
        user.authenticated,
        jobsFilters.client.value,
        jobsFilters.project.value,
        jobsFilters.projectManager.value,
        jobsFilters.city.value,
    ]);

    return {currentDay, loading, clusterJobs};
}

export function useGetJobsClusters({
                                       grouped = true,
                                       includeShared = true,
                                       includeFullJobs = false,
                                       initialRequest = true,
                                       initialClusterJobs = null,
                                       startDate = null,
                                       endDate = null,
                                       isDashboard = false
                                   } = {}) {
    const user = useSelector((state) => {
        return state.user;
    });

    const {currentDay} = useCurrentDate();
    const {jobsFilters} = useSearchFilters();
    const [loading, setLoading] = useState(false);
    const [clusterJobs, setClusterJobs] = useState(initialClusterJobs || []);
    const firstRender = useRef(true);
    let getJobWithRange = null;
    const dispatch = useDispatch();

    function cancelRequest() {
        if (getJobWithRange) {
            getJobWithRange.cancel();
        }
    }

    useEffect(() => {
        if (
            !user.authenticated ||
            !initialRequest ||
            (firstRender.current && !!initialClusterJobs)
        )
            return;

        const currentDayClone = currentDay.clone();

        cancelRequest();

        getJobWithRange = makeCancelable(
            plannerService.getJobWithRange(
                user.data.token,
                user.data.tenant.uuid,
                isDashboard,
                startDate
                    ? startDate
                    : currentDayClone.startOf("isoWeek").format("DD-MM-YYYY"),
                endDate
                    ? endDate
                    : currentDayClone.endOf("isoWeek").format("DD-MM-YYYY"),
                {
                    grouped: false,
                    includeShared,
                    clusters: grouped,
                    filters: {
                        ...jobsFilters,
                        fullJobs: {
                            key: "is_full",
                            value: includeFullJobs ? "" : "False",
                        },
                    },
                }
            )
        );

        setClusterJobs([]);
        setLoading(true);

        getJobWithRange.promise
            .then((clusters) => {
                if (clusters.length === 0) {
                    dispatch(checkifData(true))
                } else {
                    dispatch(checkifData(false))
                }
                setLoading(false);
                setClusterJobs(clusters);
            })
            .catch((err) => {
                if (err.isCanceled) return;

                setLoading(false);
            });
        return () => {
            cancelRequest();
        };
    }, [
        currentDay.isoWeek(),
        user.authenticated,
        jobsFilters.client.value,
        jobsFilters.project.value,
        jobsFilters.city.value,
        grouped,
        includeShared,
    ]);

    useEffect(() => {
        firstRender.current = false;
    }, []);

    return {currentDay, loading, clusterJobs};
}

export function usePlannerPreferences() {
    const plannerPreferences = useSelector((state) => {
        return state.planner.preferences;
    });

    const [temporalPreferencesAction, setPreferencesAction] = useActions([
        setTemporalPreferences,
        setPreferencesFromTemp,
    ]);

    return {
        plannerPreferences,
        setTemporalPreferences: (preferences) =>
            temporalPreferencesAction(preferences),
        setPreferences: () => setPreferencesAction(),
    };
}

export function useSidebar() {
    const show = useSelector((state) => state.planner.sidebar.show);
    const selectedJob = useSelector((state) => state.planner.sidebar.selectedJob);
    const selectedCluster = useSelector(
        (state) => state.planner.sidebar.selectedCluster
    );
    const reload = useSelector((state) => state.planner.sidebar.reload);
    const [
        setShowSidebarAction,
        setSelectedJobAction,
        setSelectedClusterAction,
        setReload,
    ] = useActions([
        setShowSidebar,
        setSelectedJob,
        setSelectedCluster,
        setPlannerSidebarReload,
    ]);

    return {
        show,
        selectedJob,
        selectedCluster,
        reload,
        setReload,
        hideSidebar: () => setShowSidebarAction(false),
        setSelectedJob: (job, options) => setSelectedJobAction(job, options),
        setSelectedCluster: (cluster) => setSelectedClusterAction(cluster),
    };
}

export function useNewJobFromPlanner({step = 0, currentDay = null} = {}) {
    const show = useSelector((state) => state.planner.newJob.show);
    const jobInfo = useSelector((state) => state.planner.newJob.jobInfo);
    const stepOneCompleted = useSelector(
        (state) => state.planner.newJob.stepOneCompleted
    );
    const [disableCreateButton, setDisableCreateButton] = useState(true);
    const [
        setShowNewJobAction,
        setStepOneCompletedAction,
        setNewJobDataAction,
    ] = useActions([setShowNewJob, setStepOneCompleted, setNewJobData]);

    useEffect(() => {
        if (!jobInfo.date && currentDay) {
            jobInfo.date = currentDay;
        }
    }, []);

    useEffect(() => {
        setDisableCreateButton(
            !(
                jobInfo.jobCategory &&
                jobInfo.startTime &&
                jobInfo.endTime &&
                jobInfo.available &&
                jobInfo.workflow
            )
        );
    }, [jobInfo]);

    /**
     * Sets the data to be stored in fleksRedux
     * @param {string} field
     * @param {any} value
     */
    function setNewJobDataField(field, value) {
        if (step === 1) {
            switch (field) {
                /**
                 * Handles the onSelectClient event
                 */
                case "client":
                    const clearProject =
                        jobInfo.client && jobInfo.client.uuid !== value.uuid
                            ? {project: null}
                            : {};
                    setNewJobDataAction({
                        client: value,
                        ...clearProject,
                    });
                    break;

                /**
                 * Handles the onSelectProject event
                 */
                case "project":
                    const clearStepTwoData =
                        jobInfo.project && jobInfo.project.uuid !== value.uuid
                            ? {
                                standardJob: null,
                                jobCategory: null,
                                pools: null,
                                street: null,
                                houseNumber: null,
                                additionalInfo: null,
                                postalCode: null,
                                city: null,
                                startTime: null,
                                endTime: null,
                                available: null,
                                workflow: null,
                            }
                            : {};
                    setNewJobDataAction({
                        project: value,
                        ...clearStepTwoData,
                    });
                    break;

                default:
                    setNewJobDataAction({
                        [field]: value,
                    });
                    break;
            }
        } else if (step === 2) {
            switch (field) {
                default:
                    setNewJobDataAction({
                        [field]: value,
                    });
                    break;
            }
        }
    }

    /**
     * Marks the step n as completed
     */
    function setStepAsCompleted() {
        if (step === 1) {
            setStepOneCompletedAction(true);
        } else if (step === 2) {
        }
    }

    /**
     * Sets everything to their initial values
     */
    function setDefaultData() {
        setStepOneCompletedAction(false);
        setNewJobDataAction({
            date: null,
            client: null,
            project: null,
            standardJob: null,
            jobCategory: null,
            pools: null,
            street: null,
            houseNumber: null,
            additionalInfo: null,
            postalCode: null,
            city: null,
            startTime: null,
            endTime: null,
            available: null,
            workflow: null,
        });
        setDisableCreateButton(true);
    }

    function minutesOfDay(moment) {
        return moment.minutes() + moment.hours() * 60;
    }

    /**
     * Sets the payload of the job creation request
     * @param {any} item
     */
    function setBulkData(item) {
        let bulkData = [];

        const {date, startTime, endTime, pools} = item;

        const startEndDate = date.format("DD-MM-YYYY");
        const time = [startTime.format("HH:mm"), endTime.format("HH:mm")];

        const startDateWithTime = changeTzTo(
            "server",
            `${startEndDate} ${time[0]}`,
            "date"
        );

        let endDateWithTime;
        if (startTime && endTime) {
            if (minutesOfDay(endTime) < minutesOfDay(startTime)) {
                let formatStartDate = moment(startTime).format("DD-MM-YYYY");
                let formatEndDate = moment(endTime).format("DD-MM-YYYY");
                if (formatStartDate === formatEndDate) {
                    endDateWithTime = changeTzTo(
                        "server",
                        `${moment(date)
                            .add(1, "days")
                            .format("DD-MM-YYYY")} ${time[1]}`,
                        "date"
                    );
                }
            } else {
                endDateWithTime = changeTzTo(
                    "server",
                    `${startEndDate} ${time[1]}`,
                    "date"
                );
            }
        }

        const poolsJoined =
            (item.pools && Array.isArray(item.pools) && item.pools.join()) || "";

        const newItem = {
            pools: poolsJoined,
            job_category: item.jobCategory,
            address: item.street,
            house_number: item.houseNumber,
            house_number_extension: item.additionalInfo,
            zipcode: item.postalCode,
            city: item.city,
            start_date: startDateWithTime,
            end_date: endDateWithTime,
            number_of_employees: parseInt(item.available, 10) || 1,
            sub_job_type: item.workflow,
            applicants: parseInt(item.available, 10) || 1,
            branch_number: "",
            host_number: "",
            place: item.city,
            title: "",
        };

        bulkData.push(newItem);

        return bulkData;
    }

    return {
        show,
        jobInfo,
        stepOneCompleted,
        disableCreateButton,
        setShowNewJob: (show) => setShowNewJobAction(show),
        setDefaultData: () => setDefaultData(),
        setNewJobData: (field, value) => setNewJobDataField(field, value),
        setStepAsCompleted: () => setStepAsCompleted(),
        adjustInfo: () => setStepOneCompletedAction(false),
        getRequestPayload: (item) => setBulkData(item),
    };
}

export function useSetJobOnPlanner() {
    const setClusterJobsAction = useActions(setJobOnPlanner);

    return {setJobOnPlanner: setClusterJobsAction};
}

export function useGetStats(weekDays) {
    const [loading, setLoading] = useState(false);
    const [weekStats, setWeekStats] = useState([]);
    const user = useSelector((state) => state.user.data);

    const getStats = useCallback((weekDays) => {
        if (!(weekDays.length > 0)) return;

        const fromDate = weekDays[0].format("DD-MM-YYYY");
        const toDate = weekDays[weekDays.length - 1].format("DD-MM-YYYY");
        setLoading(true);

        plannerService
            .getStats(user.token, fromDate, toDate)
            .then((weekStats) => {
                setLoading(false);
                setWeekStats(
                    weekStats.map((stats) => ({
                        day: stats.day,
                        filledJobs: stats.total_filled_jobs,
                        totalJobs: stats.total_jobs,
                        filledPositions: stats.total_filled_spots,
                        totalPositions: stats.total_spots,
                    }))
                );
                return;
            })
            .catch((err) => {
                if (err.isCanceled) return;

                setLoading(false);

                throw err;
            });
    }, []);

    useEffect(() => {
        getStats(weekDays);
    }, [weekDays]);

    return {weekStats, loading};
}
