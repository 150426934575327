import React, { Suspense, useEffect, useState, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { IntlProvider } from "react-intl";
import moment from "moment";
import axios from "axios";
import { Helmet } from "react-helmet";
/* Antd */
import { Layout, ConfigProvider } from "antd";
/* Actions */
import { logout } from "fleksRedux/actions/user";
import { initUIVars } from "fleksRedux/actions/ui";
import { changeLanguage } from "fleksRedux/actions/data";
/* Helpers */
import {
  saveInLocalStorage,
  pushPath,
  isNmbrsPlannerUser,
} from "utils/helpers";
/* Hooks */
import {useDisableEnterForArchive, useResizeWindow} from "hooks";
import { useFindConnections } from "hooks/sharedJobs";
import { FeatureFlagsProvider } from "contexts/FeatureFlagsContext";
//import { useGetMarketplaceJobApplicationInvalidStates } from "hooks/marketplace";
// import { useIntercomUserRegister } from "hooks/user";

/* Page meta */
import Page from "utils/pages/Page";
import { title as metaTitle, meta, link } from "utils/pages/assets";
/* Config */
import config from "./app.conf";
/* translations */
import translations from "utils/translations";
import "moment/locale/nl";
import nl from "antd/es/locale/nl_NL";
import en from "antd/es/locale/en_US";
/*Services*/
import { userService } from "services";
/* Context */
import SchedulerContextProvider from "components/Scheduler/Context";
import DatabaseContext from "contexts/DatabaseContext";
/* Locales */
import "moment/locale/nl";
import "moment/locale/en-gb";
import getTypeOfUser from "hooks/getTypeOfUser/getTypeOfUser";
import useActions from "../../hooks";
import {setSharedType} from "../../fleksRedux/actions/rebuildDatabase";
import {intlLogs, reduxDebugger} from "../../config";

moment.locale("nl");

/* Lazy imports */
const CommentsDrawer = React.lazy(() => import("./CommentsDrawer"));
const NotificationModal = React.lazy(() => import("./NotificationModal"));
const NavBar = React.lazy(() => import("./NavBar.comp"));
const Sidebar = React.lazy(() => import("./Sidebar.comp"));
const Breadcrumb = React.lazy(() => import("../../components/Breadcrumb"));

const languages = {
  nl,
  en,
};
/* Styles */
if (typeof window === "object") {
  require("assets/styles/main.scss");
  require("./style.scss");
}

function App({
  children,
  user,
  actions,
  language,
  location,
  colors,
  commentsDrawer,
  notificationModal,
}) {
  useDisableEnterForArchive()
  const pathname = useSelector(
    (state) =>
      state.routing.locationBeforeTransitions &&
      state.routing.locationBeforeTransitions.pathname
  );
  const [isMarketplace, setIsMarketplace] = useState(false);
  const [isMarketplaceEmployer, setIsMarketplaceEmployer] = useState(false);
  const [isMarketplaceAdmin, setIsMarketplaceAdmin] = useState(false);
  const [hasMarketplaceConnection, setHasMarketplaceConnection] = useState(
    null
  );
  const [intlMessages, setIntlMessages] = useState({});

  const [currentSidebarItems, setCurrentSidebarItems] = useState([]);
  const isArchive = location.pathname.indexOf("archive") !== -1;
  //const [jobInvalidStates, setJobInvalidStates] = useState([]);

  /*const {
    getJobApplicationInvalidStates,
  } = useGetMarketplaceJobApplicationInvalidStates();
*/
  const { currentHeight } = useResizeWindow(".page-container", {
    deps: [pathname],
    initialHeight: "100%",
  });
  const isLoginPage =
    location.pathname == "/" ||
    location.pathname.includes("login") ||
    !user.authenticated;
  const isConnections =
    null !==
    location.pathname.match(
      /\b(\w*(new\-connection|accepted\-connection|rejected\-connection)\w*)\b/gi
    );
  const crumbs = generateBreadcrumb(location.pathname.split("/"));
  const [isShowingBreadcrumbs, setIsShowingBreadcrumbs] = useState(false);

  const isAdmin = user.authenticated
    ? user.data.roles.some((rol) => rol.match(/dashboard_ftp_user_group/g))
    : undefined;

  const setSharedTypeAction = useActions(setSharedType);

  const areLegacyTablesActive = useMemo(() => {
    try {
      const tenant = JSON.parse(localStorage.getItem("Fl-u-data"));
      const tenantFeatureFlagSettings = tenant?.tenant_settings?.settings_variables

      setSharedTypeAction(tenantFeatureFlagSettings?.default_shared_status) // is being done here too limit the amount of jsonparse not the best place..

      return tenantFeatureFlagSettings?.areLegacyTablesActive ?? false
    } catch (error) { // json parse can throw a error if its not a valid object
      return false
    }
  }, [user.authenticated]);

  const isBetaPlannerActive = useMemo( () => {
    try {
      const tenant = JSON.parse(localStorage.getItem("Fl-u-data"));
      const plannerActive = tenant?.tenant_settings?.settings_variables?.svens_planner
      return plannerActive ?? false
    } catch (error) { // json parse can throw a error if its not a valid object
      return false
    }
  })

  const tenantSlug = user.data?.tenant.slug;
  const language_labels = user.data?.tenant.language_labels;
  const {
    connectionsData,
    loading: loadingConnectionsData,
  } = useFindConnections();

  useEffect(() => {
    if (typeof window === "object") {
      actions.initUIVars(
        window.innerWidth,
        window.innerHeight,
        window.innerWidth < 460
      );
    }
    addAxiosInterceptor();
  }, []);

  useEffect(() => {
    if (!user.authenticated) return;

    moment.locale(language === "nl" ? "nl" : "en-gb");

    updateUserLanguage(language);
  }, [language]);

  useEffect(() => {
    const pageContainer = document.querySelector(".page-container");

    if (pageContainer) {
      pageContainer.style.height = currentHeight;

      const layoutContent = pageContainer.querySelector(".ant-layout-content");

      if (layoutContent) {
        layoutContent.style.paddingTop = isShowingBreadcrumbs ? 0 : 29;
      }
    }
  });

  // useIntercomUserRegister();

  useEffect(() => {
    if (user.authenticated) {
      setIsMarketplace(
        Boolean(
          user.data &&
            (user.data.plan === "marketplace_client" ||
              user.data.plan === "marketplace_admin")
        )
      );

      setIsMarketplaceEmployer(
        Boolean(user.data && user.data.plan === "marketplace_client")
      );

      setIsMarketplaceAdmin(
        Boolean(user.data && user.data.plan === "marketplace_admin")
      );

      const marketplaceConnection =
        connectionsData &&
        Array.isArray(connectionsData) &&
        connectionsData.find((connection) => connection.tenant.is_marketplace);

      setHasMarketplaceConnection(marketplaceConnection);
    }
  }, [user.authenticated, JSON.stringify(connectionsData)]);

  useEffect(() => {
    setCurrentSidebarItems(getSidebarItems(areLegacyTablesActive, isBetaPlannerActive));
  }, [
    JSON.stringify(connectionsData),
    hasMarketplaceConnection,
    loadingConnectionsData,
  ]);

  useEffect(() => {
    const commonMessages = translations[language].messages;

    import(`utils/translations/${tenantSlug}`)
      .then((module) => {
        const customMessages = module.default[language].messages;
        /**
         * Set the messages object for the IntlProvider
         */
        setIntlMessages(
          !!language_labels && !!customMessages
            ? { ...commonMessages, ...customMessages }
            : commonMessages
        );
      })
      .catch((err) => setIntlMessages(commonMessages));
  }, [translations, language, tenantSlug, JSON.stringify(language_labels)]);

  /*useEffect(() => {
    if (isMarketplace) {
      getJobApplicationInvalidStates()
        .then((data) => {
          setJobInvalidStates(data);
        })
        .catch((e) => {});
    }
  }, [isMarketplace]);*/
  return (
    <ConfigProvider locale={languages[language]}>
      <IntlProvider
        onError={(err) => {
          if (intlLogs === 'warning') {
            console.warn(err)
          } else if (intlLogs === 'error'){
            console.error(err)
          }
        }}
        locale={language}
        messages={intlMessages}>
        <SchedulerContextProvider location={location}>
          <DatabaseContext.Provider
            value={{
              isArchive,
              isAdmin,
              isMarketplace,
              isMarketplaceEmployer,
              isMarketplaceAdmin,
              hasMarketplaceConnection,
            }}
          >
            <FeatureFlagsProvider>
              <Page title={metaTitle} meta={meta} link={link}>
                <Helmet htmlAttributes={{ lang: language }} />
                {isLoginPage || isConnections ? (
                  children
                ) : (
                  <Suspense fallback={null}>
                    <Layout className="full-height">
                      <Sidebar
                        items={currentSidebarItems}
                        user={user}
                        location={location}
                      />
                      <Layout>
                        <NavBar
                          user={user}
                          language={language}
                          colors={colors}
                          logout={actions.logout}
                          changeLanguage={(language) => {
                            actions.changeLanguage(language);
                          }}
                        />
                        {isShowingBreadcrumbs && (
                          <Breadcrumb className="breadcrumbs" crumbs={crumbs} />
                        )}
                        <Suspense fallback={null}>{children}</Suspense>
                        {/* Comments Drawer */}
                        <Suspense fallback={null}>
                          <CommentsDrawer />
                          {notificationModal.showModal && (
                            <NotificationModal
                              notificationModal={notificationModal}
                            />
                          )}
                        </Suspense>
                      </Layout>
                    </Layout>
                  </Suspense>
                )}
              </Page>
            </FeatureFlagsProvider>
          </DatabaseContext.Provider>
        </SchedulerContextProvider >
      </IntlProvider>
    </ConfigProvider>
  );

  /**
   * Add a hook to axios, when you receive an error evaluate if this was because of the session
   */
  function addAxiosInterceptor() {
    axios.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        if (error && error.response) {
          const response = error.response;
          if (
              (response.status == 403 &&
            response.data.message_code.indexOf(
              "generic_api_authorization_handler"
            ) != -1) || response.status == 401
          ) {
            saveInLocalStorage(null);
            pushPath("/", { redirect_to: `${location.pathname}` });
          }
        }
        return Promise.reject(error);
      }
    );
  }

  /**
   * Generate the breadcrumbs from to paths array
   * @param {string[]} pathnames
   */
  function generateBreadcrumb(pathnames) {
    return pathnames.map((pathname, i, fullArray) => {
      if (pathname == "") return null;

      const restPathname = fullArray.slice(0, i).join("/");

      return {
        path: `${restPathname}/${pathname}`,
        text: pathname,
      };
    });
  }

  /**
   * Change the current language by user
   * @param {string} language
   */

  function updateUserLanguage(language) {
    userService
      .setProfile(user.data.token, user.data.uuid, {
        current_language: language,
      })
      .then((response) => {})
      .catch((error) => {});
  }

  function getSidebarItems(areLegacyTablesActive, showBetaPlanner = false) {
    if (!user.authenticated || !user.data.plan) return {};
    const planID = user.data.plan.replace(" ", "_").toLowerCase();
    const role = getTypeOfUser(user.data.roles[0]);
    let sidebarItems;
    if (role) {
      const value = "new_type_of_user";
      sidebarItems = { ...config.sideMenu[value] };
    } else {
      sidebarItems = { ...config.sideMenu[planID] };
    }

    const hasNormalConnection = user.data.tenant.connections?.some(
      (connection) => !connection.tenant.is_marketplace
    );

    sidebarItems = alterSideMenuWithFeatureFlag(areLegacyTablesActive, showBetaPlanner, sidebarItems)

    if (
      !user.data.tenant.billableHoursEnabled &&
      !hasNormalConnection &&
      sidebarItems.hours?.options["hours/externals"]
    ) {
      delete sidebarItems.hours.options["hours/externals"];
    }

    if (
      !user.data.tenant.billableHoursEnabled &&
      !hasNormalConnection &&
      sidebarItems.hours?.options["hours/all-hours"]
    ) {
      delete sidebarItems.hours.options["hours/all-hours"];
    }

    if (
      sidebarItems.database &&
      sidebarItems.database.options["database/job-requests"]
    ) {
      if (!user.data.tenant.connections) {
        delete sidebarItems.database.options["database/job-requests"];
      }
    }

    if (
      !loadingConnectionsData &&
      !!!hasMarketplaceConnection &&
      sidebarItems.hours?.options["hours/invoices"]
    ) {
      const newSidebarItems = {};

      for (let key in sidebarItems.hours.options) {
        if (
          sidebarItems.hours.options.hasOwnProperty(key) &&
          key != "hours/invoices"
        ) {
          newSidebarItems[key] = sidebarItems.hours.options[key];
        }
      }

      return {
        ...sidebarItems,
        hours: {
          ...sidebarItems.hours,
          options: newSidebarItems,
        },
      };
    }

    if (isNmbrsPlannerUser(user)) {
      delete sidebarItems.database.options["database/applicants"];
    }

    /* if (
      Array.isArray(user.data.tenant.connections) &&
      user.data.tenant.connections.length > 0
    ) {
      if (sidebarItems.hours?.options["hours/singles"]) {
        sidebarItems.hours.options["hours/singles"].title =
          "Sidebar.singlesInternal";
      }
      if (sidebarItems.hours?.options["hours/totals"]) {
        sidebarItems.hours.options["hours/totals"].title =
          "Sidebar.totalsInternal";
      }
    } */
    return sidebarItems || {};
  }
}

function alterSideMenuWithFeatureFlag(areLegacyTablesActive, showBetaPlanner, sidebarItems){
    if(!areLegacyTablesActive){
      delete sidebarItems["legacy/planner"]
      delete sidebarItems.legacyDatabase
      delete sidebarItems.legacyHours;
    }

    if (!showBetaPlanner) {
      delete sidebarItems["beta/planner"]
    }


    return sidebarItems;
}

function mapStateToProps(state) {
  return {
    language: state.data.language,
    user: state.user,
    colors: state.ui.colors,
    commentsDrawer: state.ui.commentsDrawer,
    notificationModal: state.ui.notificationModal,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { logout, initUIVars, changeLanguage },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
