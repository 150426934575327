import {
  SET_IS_OVERLAPPING,
  SET_SHOW_NOTIFICATION,
  SET_JOB_LIST_AFTER,
  SET_JOB_LIST_BEFORE,
  SET_JOB_LIST_EQUAL,
  SET_REST_TIME_AFTER_SMALLEST,
  SET_REST_TIME_BEFORE_SMALLEST,
  RESET_REST_TIME,
  SHOW_RESCHEDULE_STEP,
  SET_REST_TIME_BEFORE_VAL,
  SET_REST_TIME_AFTER_VAL,
  SET_OVERLAPPING_BEFORE_ITEMS,
  SET_OVERLAPPING_AFTER_ITEMS,
  REST_TIME_OVERLAPPNG_BEFORE_SMALLEST,
  REST_TIME_OVERLAPPNG_AFTER_SMALLEST
} from "fleksRedux/types";


const employeeRestTime = (
  state = {
    jobListBefore: [],
    jobListAfter: [],
    jobListEqual: [],
    restTimeBeforeSmallest: 0,
    restTimeAfterSmallest: 0,
    restTimeBeforeValue: [],
    restTimeAfterValue: [],
    isOverlapping: null,
    showNotification: false,
    showRescheduleStep: false,
    overlappingBefore: [],
    overlappingAfter: [],
    overlappingRestTimeBeforeSmallest: 0,
    overlappingRestTimeAfterSmallest: 0
  },
  action = {}
) => {
  switch (action.type) {
    case SET_IS_OVERLAPPING:
      return {
        ...state,
        isOverlapping: action.data
      }
    case SET_SHOW_NOTIFICATION:
      return {
        ...state,
        showNotification: action.data
      }
    case SET_JOB_LIST_AFTER:
      return {
        ...state,
        jobListAfter: action.data
      }
    case SET_JOB_LIST_BEFORE:
      return {
        ...state,
        jobListBefore: action.data
      }
    case SET_JOB_LIST_EQUAL:
      return {
        ...state,
        jobListEqual: action.data
      }
    case SET_REST_TIME_BEFORE_VAL: 
      return {
        ...state, 
        restTimeBeforeValue: action.data
      }
    case SET_REST_TIME_AFTER_VAL: 
      return {
        ...state, 
        restTimeAfterValue: action.data
      }
    case SET_REST_TIME_AFTER_SMALLEST:
      return {
        ...state,
        restTimeAfterSmallest: action.data
      }
    case SET_REST_TIME_BEFORE_SMALLEST:
      return {
        ...state,
        restTimeBeforeSmallest: action.data
      }
    case SHOW_RESCHEDULE_STEP:
      return {
        ...state,
        showRescheduleStep: action.data
      }
    case SET_OVERLAPPING_BEFORE_ITEMS: 
      return {
        ...state, 
        overlappingBefore: action.data
      }
    case SET_OVERLAPPING_AFTER_ITEMS: 
      return {
        ...state,
        overlappingAfter: action.data
      }
    case REST_TIME_OVERLAPPNG_BEFORE_SMALLEST: 
      return {
        ...state,
        overlappingRestTimeBeforeSmallest: action.data
      }
    case REST_TIME_OVERLAPPNG_AFTER_SMALLEST: 
      return {
        ...state,
        overlappingRestTimeAfterSmallest: action.data
      }
    case RESET_REST_TIME:
      return {
        ...state,
        jobListBefore: [],
        jobListAfter: [],
        jobListEqual: [],
        overlappingBefore: [],
        overlappingAfter: [],
        restTimeBeforeSmallest: 0,
        restTimeAfterSmallest: 0,
        overlappingRestTimeBeforeSmallest: 0,
        overlappingRestTimeAfterSmallest: 0,
        isOverlapping: null,
        showNotification: false
      }
    default:
      return state;
  }
}

export default employeeRestTime;