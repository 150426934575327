import { combineReducers } from "redux";
import { LIST_OF_MATERIALS,  
         ADD_CATEGORIES, 
         EDIT_MATERIAL, 
         DELETE_MATERIAL,
         CATEGORY_LIST,
         ADD_MATERIAL
        } from "fleksRedux/types";

const initialState = {
   categories: [],
   materialsList: [],
   loading: true
}

const materialList = (state = initialState, action = {})  => {
    switch(action.type) {
        case LIST_OF_MATERIALS: 
            return {
                ...state,
                materialsList: action.payload,
                loading: false
                
            }
        case CATEGORY_LIST: 
            return {
                ...state,
                categories: action.payload
            }
        case ADD_CATEGORIES: 
            return {
                ...state,
                categories: [...state.categories, action.payload]
            }
        case ADD_MATERIAL: 
            return {
                ...state,
                materialsList: [...state.materialsList, action.payload[0]]
            }
        case EDIT_MATERIAL:
            let findIndex = state.materialsList.findIndex(el => el.uuid === action.payload.uuid);
            let newMaterialList = [...state.materialsList];
            newMaterialList[findIndex] = {
                uuid: action.payload.uuid,
                ...action.payload.data
            }
            return {
                ...state,
                materialsList: newMaterialList
            }
        case DELETE_MATERIAL: 
            return {
                ...state,
                materialsList: state.materialsList.filter(el => el.uuid !== action.payload)
            }
        default: 
            return state;

    }
}

const materials = combineReducers({
    materialList,
});

export default materials;