import axios from "axios";
import { serverConfig } from "config";
import { createRebuildFilterString } from "utils/rebuild/api";

const apiUrl = serverConfig.rebuildApiUrl;

export const jobsService = {
  getJobGroups: (token, filters) => {
    const url = new URL(`${apiUrl}jobs/grouped`)
    const filtersString = createRebuildFilterString(filters)

    return axios.get(`${url.toString()}?${filtersString}`, {
      headers: { Authorization: `JWT ${token}` }
    });
  }
}