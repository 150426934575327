/* eslint-disable */

import React from "react";
import { Route, IndexRoute, IndexRedirect } from "react-router";
import moment from "moment";
/* Actions */
import { getUserProfile } from "fleksRedux/actions/user";
/* Components */
import App from "containers/App";
import Page404 from "./containers/Page404";
/* Helpers */
import { getDataInStorage, saveInLocalStorage } from "utils/helpers";

const ServicesReportsUnsubscribe = React.lazy(() =>
  import("containers/Services/Reports/Unsubscribe")
);

/*
 * @param {Redux Store}
 * We require store as an argument here because we wish to get
 * state from the store after it has been authenticated.
 */
export default (store) => {
  /* If you are not logged in, redirect to the root */
  const requireAuth = (nextState, replace, callback) => {
    if (typeof window === "object") {
      const { location } = nextState;
      const { user } = store.getState();
      let pathname = location.pathname;
      let userData = getDataInStorage();
      /* If there is data in localStorage, it starts session */
      if (userData && userData != "null" && !user.authenticated) {
        userData = JSON.parse(userData);
        let expire_in = userData.expire_in.split("-");
        let year = expire_in[2].split(" ");
        if (
          moment().isSameOrBefore(
            `${year[0]}-${expire_in[1]}-${expire_in[0]} ${year[1]}`
          )
        ) {
          let path =
            location.pathname != "/" &&
            !location.pathname.includes("login") &&
            !location.pathname.includes("forgot") &&
            !location.pathname.includes("reset-password")
              ? location.pathname + location.search
              : "/dashboard";
          store.dispatch(getUserProfile(userData, path));
        } else {
          saveInLocalStorage(null);
          replace({ pathname: "/", search: `?redirect_to=${pathname}` });
        }
      } else if (
        pathname !== "/" &&
        !user.authenticated &&
        !location.pathname.includes("login") &&
        !location.pathname.includes("forgot") &&
        !location.pathname.includes("reset-password")
      ) {
        replace({ pathname: "/", search: `?redirect_to=${pathname}` });
      }
    }
    callback();
  };

  return (
    <Route>
      <Route
        path="/"
        component={App}
        onEnter={requireAuth}
        ignoreScrollBehavior
      >
        <IndexRoute
          component={React.lazy(() => import("containers/Auth/Login"))}
        />

        <Route
          path="forgot"
          component={React.lazy(() => import("containers/Auth/Login/Forgot"))}
        />

        <Route
          path="login/:tenant"
          component={React.lazy(() => import("containers/Auth/Login"))}
        />

        <Route
          path="login/:tenant/forgot"
          component={React.lazy(() => import("containers/Auth/Login/Forgot"))}
        />

        <Route
          path=":tenant/reset-password"
          component={React.lazy(() => import("containers/Auth/ResetPassword"))}
        />

        <Route
          key="notifications"
          path="notifications"
          component={React.lazy(() => import("containers/App/Notifications"))}
        />
        <Route
          path="my-organization"
          component={React.lazy(() => import("containers/MyOrganization"))}
        />
        <Route
          path="planning/progress"
          component={React.lazy(() => import("containers/PlanningProgress"))}
        />
        <Route
          key="dashboard"
          path="dashboard"
          component={React.lazy(() => import("containers/Dashboard"))}
        />
        <Route key="root-old" path="old">
          <IndexRedirect key="index-redirect" to="dashboard" />
          <Route
            key="dashboard"
            path="dashboard"
            component={React.lazy(() => import("containers/Dashboard/Old"))}
          />
        </Route>
        <Route
          key="betaPlanner"
          path="beta/planner"
          component={React.lazy(() => import("containers/DetailedPlanner"))}
        >
        </Route>
        <Route
          key="legacyPlanner"
          path="legacy/planner"
          component={React.lazy(() => import("containers/Planner"))}
        />
        <Route
          key="planner/settings"
          path="planner/settings"
          component={React.lazy(() => import("containers/Planner/Settings"))}
        />
        <Route
            key="planner"
            path="planner"
            component={React.lazy(() => import("containers/NewPlanner"))}
        >
          <Route
              key="planner/settings"
              path="settings"
              component={React.lazy(() => import("containers/Planner/Settings"))}
          />
        </Route>
        {/* <Route
          key="planner/old"
          path="planner/old"
          component={React.lazy(() => import("containers/Planner/Old"))}
        /> */}
        <Route
          path="scheduler"
          component={React.lazy(() => import("containers/Scheduler"))}
        />
        <Route
          path="scheduler/settings"
          component={React.lazy(() => import("containers/Scheduler/Settings"))}
        />
        <Route key="database" path="database">
          {getCommonRoutes()}
        </Route>
        <Route key="rebuildDatabase" path="legacy/database">
          {getLegacyRoutes()}
        </Route>
        <Route key="hours" path="hours">
          <IndexRedirect to="singles" />
          <Route path="new">
            <IndexRedirect to="singles" />
            <Route
              path="singles"
              component={React.lazy(() => import("containers/Hours/Singles"))}
            />

            <Route
              key="hours/singles/single"
              path="singles/:hour"
              component={React.lazy(() =>
                import("containers/Hours/Singles/Single")
              )}
            />
          </Route>
          {/* Old BETA tables routes*/}
          <Route key="hours-old" path="old">
            <IndexRedirect key="index-redirect" to="singles" />
            {/* <Route
              key="hours/singles"
              path="singles"
              component={React.lazy(() =>
                import("containers/Hours/Old/Singles")
              )}
            >
              <Route
                key="hours/singles/single"
                path=":hour"
                component={React.lazy(() =>
                  import("containers/Hours/Old/Singles/Single")
                )}
              />
            </Route> */}
            {/* <Route
              key="hours/totals"
              path="totals"
              component={React.lazy(() =>
                import("containers/Hours/Old/Totals")
              )}
            >
              <Route
                key="hours/totals/single"
                path=":hour"
                component={React.lazy(() =>
                  import("containers/Hours/Singles/Single")
                )}
              />
            </Route> */}
            <Route
              key="hours/salary-run"
              path="make-salary-run"
              component={React.lazy(() => import("containers/Hours/SalaryRun"))}
            />
          </Route>
          {/* Current OFFICIAL tables routes */}
          <IndexRedirect key="index-redirect" to="singles" />
          <Route key="hours/invoices" path="invoices">
            <IndexRoute
              component={React.lazy(() => import("containers/Hours/Invoices"))}
            />
            <Route
              key="hours/invoices/single"
              path=":invoice"
              component={React.lazy(() =>
                import("containers/Hours/Invoices/Single")
              )}
            />
          </Route>

          <Route key="hours/singles" path="singles">
            <IndexRoute
              component={React.lazy(() => import("containers/Hours/rebuild/Singles"))}
            />
            <Route
              key="hours/singles/single"
              path=":hour"
              component={React.lazy(() =>
                import("containers/Hours/Singles/Single")
              )}
            />
          </Route>

          <Route key="hours/all-hours" path="all-hours">
            <IndexRoute
              component={React.lazy(() => import("containers/Hours/rebuild/AllHours"))}
            />
          </Route>

          <Route
            key="hours/totals"
            path="totals"
            component={React.lazy(() => import("containers/Hours/Totals"))}
          >
            <Route
              key="hours/totals/single"
              path=":hour"
              component={React.lazy(() =>
                import("containers/Hours/Singles/Single")
              )}
            />
          </Route>

          {false && (
            <Route path="externals">
              <IndexRoute
                component={React.lazy(() =>
                  import("containers/Hours/Externals")
                )}
              />
              <Route
                key="externals/single"
                path=":single"
                component={React.lazy(() =>
                  import("containers/Hours/Externals/Single")
                )}
              />
            </Route>
          )}
          <Route
            key="hours/salary-run"
            path="make-salary-run"
            component={React.lazy(() => import("containers/Hours/SalaryRun"))}
          />
        </Route>
        <Route
          key="archive"
          path="archive"
          component={React.lazy(() => import("containers/Archive"))}
        >
          {getCommonRoutes()}
          <Route key="hours" path="hours">
            <IndexRedirect key="index-redirect" to="singles" />
            <Route path="new">
              <IndexRedirect to="singles" />
              <Route
                path="singles"
                component={React.lazy(() => import("containers/Hours/Singles"))}
              />

              <Route
                key="hours/singles/single"
                path="singles/:hour"
                component={React.lazy(() =>
                  import("containers/Hours/Singles/Single")
                )}
              />
            </Route>
            <Route
              key="hours/singles"
              path="singles"
              component={React.lazy(() => import("containers/Hours/rebuild/Singles"))}
            >
              <Route
                key="hours/singles/single"
                path=":hour"
                component={React.lazy(() =>
                  import("containers/Hours/Singles/Single")
                )}
              />
            </Route>
            <Route
              key="hours/totals"
              path="totals"
              component={React.lazy(() => import("containers/Hours/Totals"))}
            >
              <Route
                key="hours/totals/single"
                path=":hour"
                component={React.lazy(() =>
                  import("containers/Hours/Singles/Single")
                )}
              />
            </Route>
          </Route>
        </Route>
        <Route key="settings" path="settings">
          <IndexRoute
            component={React.lazy(() => import("containers/Settings"))}
          />
          <Route
            key="settings/connection"
            path="connection/:connection"
            component={React.lazy(() =>
              import("containers/Settings/Connections")
            )}
          />
        </Route>
        {/* <Route
          key="profile-old"
          path="profile-old"
          component={React.lazy(() => import("containers/Profile/Old"))}
        /> */}
        <Route
          key="profile"
          path="profile"
          component={React.lazy(() => import("containers/Profile"))}
        />
        <Route key="connections" path="connections">
          <IndexRedirect key="index-redirect" to="/" />
          <Route
            key="connections/new-connection"
            path="new-connection"
            component={React.lazy(() =>
              import("containers/SharedJobs/NewConnection")
            )}
          >
            <Route
              key="connections/new-connection/pin"
              path=":pin"
              component={React.lazy(() =>
                import("containers/SharedJobs/NewConnection")
              )}
            />
          </Route>
          <Route
            key="connections/accepted-connection"
            path="accepted-connection"
            component={React.lazy(() =>
              import("containers/SharedJobs/AcceptedConnection")
            )}
          >
            <Route
              key="connections/accepted-connection/pin"
              path=":pin"
              component={React.lazy(() =>
                import("containers/SharedJobs/AcceptedConnection")
              )}
            />
          </Route>
          <Route
            key="connections/rejected-connection"
            path="rejected-connection"
            component={React.lazy(() =>
              import("containers/SharedJobs/RejectedConnection")
            )}
          >
            <Route
              key="connections/rejected-connection/pin"
              path=":pin"
              component={React.lazy(() =>
                import("containers/SharedJobs/RejectedConnection")
              )}
            />
          </Route>
        </Route>
      </Route>
      <Route
        path="services/reports/unsubscribe"
        component={ServicesReportsUnsubscribe}
      />
      <Route
        exact
        key="applicant-invitation"
        path="applicants/new-applicant/:tenant"
        component={React.lazy(() =>
          import("containers/Database/Applicants/NewApplicant")
        )}
      />
      <Route
        exact
        key="employee-invitation"
        path=":slug/:employee/:emailUuid"
        component={React.lazy(() => import("containers/OnboardingInvitation"))}
      />
      <Route path="*" component={Page404} />
    </Route>
  );
};

function getLegacyRoutes(){
  return [
    <Route key="legacy-clients" path="clients">
      <IndexRoute
          component={React.lazy(() => import("containers/Database/Clients"))}
      />
    </Route>,
    <Route key="legacy-projects" path="projects">
      <IndexRoute
          component={React.lazy(() => import("containers/Database/Projects"))}
      />
    </Route>,
    <Route key="legacy-jobs" path="jobs">
      <IndexRoute
          component={React.lazy(() => import("containers/Database/Jobs"))}
      />
    </Route>,
    <Route key="legacy-shifts" path="shifts">
      <IndexRoute
          component={React.lazy(() => import("containers/Database/Shifts"))}
      />
    </Route>,
    <Route key="legacy-employees" path="employees">
      <IndexRoute
          component={React.lazy(() => import("containers/Database/Employees"))}
      />
    </Route>,
    <Route key="legacy-applicants" path="applicants">
      <IndexRoute
          component={React.lazy(() => import("containers/Database/Applicants"))}
      />
    </Route>,
    <Route key="legacy-hours-single" path="hours/singles">
      <IndexRoute
          component={React.lazy(() => import("containers/Hours/Singles"))}
      />
    </Route>,
    <Route key="legacy-hours-allHours" path="hours/allHours">
      <IndexRoute
          component={React.lazy(() => import("containers/Hours/AllHours"))}
      />
    </Route>,
  ]
}


function getCommonRoutes() {
  return [
    <Route
      key="settings"
      path="settings"
      component={React.lazy(() => import("containers/Database/Settings"))}
    />,
    /* New BETA tables routes */
    <Route key="database-new" path="new">
      <IndexRedirect key="index-redirect" to="clients" />
      <Route key="clients" path="clients" />
      <Route key="projects" path="projects">
        <Route
          key="projects/single"
          path=":project"
          component={React.lazy(() =>
            import("containers/Database/Projects/Single")
          )}
        />
      </Route>
      <Route key="jobs" path="jobs">
        <IndexRoute
          component={React.lazy(() => import("containers/Database/rebuild/Jobs"))}
        />
        <Route
          key="jobs/single"
          path=":job"
          component={React.lazy(() =>
            import("containers/Database/Jobs/Single")
          )}
        />
      </Route>
      <Route
        key="shifts"
        path="shifts"
        component={React.lazy(() => import("containers/Database/rebuild/Shifts"))}
      />
      <Route key="employees" path="employees">
        <Route
          key="employees/single"
          path=":employee"
          component={React.lazy(() =>
            import("containers/Database/Employees/Single")
          )}
        />
      </Route>
      <Route key="new-employers" path="new-employers">
        <IndexRoute
          component={React.lazy(() =>
            import("containers/Database/NewEmployers")
          )}
        />
        <Route
          key="new-employers/single"
          path=":employer"
          component={React.lazy(() =>
            import("containers/Database/Employers/Single")
          )}
        />
      </Route>
      <Route key="employers" path="employers">
        <IndexRoute
          component={React.lazy(() => import("containers/Database/Employers"))}
        />
        <Route
          key="employers/single"
          path=":employer"
          component={React.lazy(() =>
            import("containers/Database/Employers/Single")
          )}
        />
      </Route>
      <Route key="new-freelancers" path="new-freelancers">
        <IndexRoute
          component={React.lazy(() =>
            import("containers/Database/NewFreelancers")
          )}
        />
        <Route
          key="new-freelancers/single"
          path=":freelancer"
          component={React.lazy(() =>
            import("containers/Database/Freelancers/Single")
          )}
        />
      </Route>
      <Route key="freelancers" path="freelancers">
        <IndexRoute
          component={React.lazy(() =>
            import("containers/Database/Freelancers")
          )}
        />
        <Route
          key="freelancers/single"
          path=":freelancer"
          component={React.lazy(() =>
            import("containers/Database/Freelancers/Single")
          )}
        />
      </Route>
    </Route>,
    /* Old DEPRECATED tables routes */
    <Route key="database-old" path="old">
      <IndexRedirect key="index-redirect" to="shifts" />
      {/* <Route key="clients" path="clients">
        <IndexRoute
          component={React.lazy(() =>
            import("containers/Database/Old/Clients")
          )}
        />
        <Route
          key="clients/single"
          path=":client"
          component={React.lazy(() =>
            import("containers/Database/Old/Clients/Single")
          )}
        />
      </Route>
      <Route path="jobs">
        <IndexRoute
          component={React.lazy(() => import("containers/Database/Old/Jobs"))}
        />
        <Route
          key="jobs/single"
          path=":job"
          component={React.lazy(() =>
            import("containers/Database/Old/Jobs/Single")
          )}
        />
      </Route>
      <Route key="projects" path="projects">
        <IndexRoute
          component={React.lazy(() =>
            import("containers/Database/Old/Projects")
          )}
        />
        <Route
          key="projects/single"
          path=":project"
          component={React.lazy(() =>
            import("containers/Database/Old/Projects/Single")
          )}
        />
      </Route>
      <Route key="employees" path="employees">
        <IndexRoute
          component={React.lazy(() =>
            import("containers/Database/Old/Employees")
          )}
        />
        <Route
          path=":employee"
          component={React.lazy(() =>
            import("containers/Database/Old/Employees/Single")
          )}
        />
      </Route>
      <Route
        key="applicants"
        path="applicants"
        component={React.lazy(() =>
          import("containers/Database/Old/Applicants")
        )}
      /> */}
      <Route path="shifts">
        <IndexRoute
          component={React.lazy(() => import("containers/Database/Old/Shifts"))}
        />
      </Route>
    </Route>,
    /* Current OFFICIAL tables routes */
    <IndexRedirect key="index-redirect" to="clients" />,
    <Route key="clients" path="clients">
      <IndexRoute
        component={React.lazy(() => import("containers/Database/rebuild/Clients"))}
      />
      <Route
        key="create-client"
        path="create-client"
        component={React.lazy(() =>
          import("containers/Database/Clients/NewClient")
        )}
      />
      <Route
        key="clients/single"
        path=":client"
        component={React.lazy(() =>
          import("containers/Database/Clients/Single")
        )}
      />
      <Route
        key="template-project"
        path=":client/template-project"
        component={React.lazy(() =>
          import("containers/Database/Old/Clients/Single/Templates")
        )}
      />
      <Route
        key="templates"
        path=":client/:template"
        component={React.lazy(() =>
          import(
            "containers/Database/Old/Clients/Single/Details/StandardProject"
          )
        )}
      >
        <Route
          key="create-standard-job"
          path="create-standard-job"
          component={React.lazy(() =>
            import("containers/Database/Jobs/NewJob")
          )}
        />
      </Route>
    </Route>,
    <Route key="projects" path="projects">
      <IndexRoute
        component={React.lazy(() => import("containers/Database/rebuild/Projects"))}
      />
      <Route
        key="create-project"
        path="create-project"
        component={React.lazy(() =>
          import("containers/Database/Projects/NewProject")
        )}
      />
      <Route
        path="creation"
        component={React.lazy(() =>
          import("containers/Database/Projects/Creation")
        )}
      />
      <Route
        key="projects/single"
        path=":project"
        component={React.lazy(() =>
          import("containers/Database/Projects/Single")
        )}
      >
        <Route
          key="create-quiz"
          path="create-quiz"
          component={React.lazy(() =>
            import("containers/Database/Projects/CreateQuiz")
          )}
        />
      </Route>
    </Route>,
    <Route key="jobs" path="jobs">
      <IndexRoute
        component={React.lazy(() => import("containers/Database/rebuild/Jobs"))}
      />
      <Route
        key="create-job"
        path="create-job"
        component={React.lazy(() => import("containers/Database/Jobs/NewJob"))}
      />
      <Route
        path="creation"
        component={React.lazy(() =>
          import("containers/Database/Jobs/Creation")
        )}
      />
      <Route
        key="jobs/single"
        path=":job"
        component={React.lazy(() => import("containers/Database/Jobs/Single"))}
      />
    </Route>,
    <Route
      key="job-requests"
      path="job-requests"
      component={React.lazy(() => import("containers/Database/JobRequests"))}
    />,

    <Route
      key="shifts"
      path="shifts"
      component={React.lazy(() => import("containers/Database/rebuild/Shifts"))}
    />,
    <Route key="employees" path="employees">
      <IndexRoute
        component={React.lazy(() => import("containers/Database/rebuild/Employees"))}
      />
      <Route
        key="create-employee"
        path="create-employee"
        component={React.lazy(() =>
          import("containers/Database/Employees/NewEmployee")
        )}
      />
      <Route
        path="creation"
        component={React.lazy(() =>
          import("containers/Database/Employees/Creation")
        )}
      />
      <Route
        key="employees/single"
        path=":employee"
        component={React.lazy(() =>
          import("containers/Database/Employees/Single")
        )}
      />
    </Route>,
    <Route key="applicants" path="applicants">
      <IndexRoute
        component={React.lazy(() => import("containers/Database/rebuild/Applicants"))}
      />
      <Route
        key="applicants/single"
        path=":employee"
        component={React.lazy(() =>
          import("containers/Database/Employees/Single")
        )}
      />
    </Route>,
  ];
}
