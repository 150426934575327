/* eslint-disable */

import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useMemo, useRef, useEffect, useCallback, useState } from "react";
/* Utils */
import { makeCancelable } from "utils/helpers";
import { INIT_THEME } from "fleksRedux/types";
/* Redux Actions */
import { setDisableKeyCallback } from "fleksRedux/actions/ui";

export function useActions(actions, deps) {
  const dispatch = useDispatch();

  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map(a => bindActionCreators(a, dispatch));
      }
      return bindActionCreators(actions, dispatch);
    },
    deps ? [dispatch, ...deps] : [dispatch]
  );
}

export function useCancellablePromise(cancelable = makeCancelable) {
  const emptyPromise = Promise.resolve(true);

  // test if the input argument is a cancelable promise generator
  if (cancelable(emptyPromise).cancel === undefined) {
    throw new Error(
      "promise wrapper argument must provide a cancel() function"
    );
  }

  const promises = useRef([]);

  useEffect(() => {
    promises.current = promises.current || [];

    return cancel;
  }, []);

  function cancellablePromise(p) {
    const cPromise = cancelable(p);
    promises.current.push(cPromise);
    return cPromise.promise;
  }

  function cancel() {
    promises.current.forEach(p => p.cancel());
    promises.current = [];
  }

  return { cancellablePromise, cancelPromises: () => cancel() };
}

export function useResizeWindow(
  elementID,
  { initialHeight = "60vh", deps = undefined } = {}
) {
  const [currentHeight, setCurrentHeight] = useState(initialHeight);
  const elementRef = useRef(null);

  const updateHeight = useCallback(() => {
    const element = document.querySelector(elementID);
    const height = element ? element.offsetHeight : 0;

    if (
      null !== height &&
      undefined !== height &&
      height !== currentHeight &&
      height > 0
    ) {
      elementRef.current = element;
      setCurrentHeight(height);
    }
  }, [elementID, initialHeight, currentHeight]);

  useEffect(() => {
    updateHeight();
  }, deps);

  useEffect(() => {
    window.removeEventListener("resize", updateHeight);
    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  });

  return { currentHeight, elementRef };
}

export function useDelay({ delay = 500 } = {}) {
  const delayRef = useRef(null);

  return { addDelay };

  function addDelay(cb) {
    return (...params) => {
      if (delayRef.current) {
        clearTimeout(delayRef.current);
      }

      delayRef.current = setTimeout(() => {
        cb(...params);
      }, delay);
    };
  }
}

export function useDispatchEvent({
  eventToCall,
  value,
  source,
  canDispatch = true
}) {
  const elementRef = useRef(null);
  useEffect(() => {
    canDispatch &&
      (value || value === "") &&
      elementRef &&
      elementRef.current &&
      elementRef.current.props[eventToCall] &&
      elementRef.current.props[eventToCall](value);
  }, [value, source]);

  return { elementRef };
}

export function usePagination() {
  const [hasNext, setHasNext] = useState(true);
  const [page, setPage] = useState(0);

  /**
   * Returns a promise with the next page of a request
   */
  function getNextPage() {
    let nextPage = page + 1;
    return new Promise((resolve, reject) => {
      if (hasNext) {
        setPage(nextPage);
        resolve(nextPage);
      } else {
        setPage(0);
        reject();
      }
    });
  }

  return {
    hasNext,
    setHasNext,
    getPagination: () => getNextPage()
  };
}

/**
 * @param {number} totalItems
 * @param {(page: number, size: number, ...args: any[]) => Promise<any>} getItemsRequest
 * @param {{ size?: number; errorHandler?: (error: any) => void; }} [options]
 */
export function usePaginationRequest(
  totalItems,
  getItemsRequest,
  { size = 25, errorHandler } = {}
) {
  const [currentPage, setCurrentPage] = useState(0);
  const maxPage = useMemo(() => {
    return Math.ceil(totalItems / size);
  }, [totalItems, size]);

  const getItems = useCallback(
    /**
     * @param {number} nextPage
     * @param {...any[]} [args]
     */
    (nextPage, ...args) => {
      if (nextPage !== 1 && nextPage > maxPage) {
        return Promise.reject(new Error("Max page size"));
      }
      setCurrentPage(nextPage);

      return getItemsRequest(nextPage, size, ...args).then(() => {});
    },
    [getItemsRequest, maxPage]
  );

  return {
    getNextPage: (...args) =>
      getItems(currentPage + 1, ...args).catch(error => {
        errorHandler && errorHandler(error);
      }),
    resetPagination: (...args) =>
      getItems(1, ...args).catch(error => {
        errorHandler && errorHandler(error);
      })
  };
}

export function useTheme() {
  const dispatch = useDispatch();
  const theme = useSelector(state => state.theme);
  const isValidTheme = useMemo(() => {
    return getIsValidTheme();
  }, [theme.primaryColor, theme.actionColor]);

  return {
    theme,
    isValidTheme,
    initTheme: tenant => {
      dispatch({
        tenant,
        type: INIT_THEME
      });
    }
  };

  function getIsValidTheme() {
    const hexColorRegex = /[A-Fa-f0-9]{3,6}/g;

    return (
      theme.actionColor &&
      theme.actionColor.match(hexColorRegex) &&
      theme.primaryColor &&
      theme.primaryColor.match(hexColorRegex)
    );
  }
}

export function useDisableEnterForArchive() {
  const isArchivePage = window.location.href.includes('archive');
  useEffect(() => {


    function handleKeydown(event) {
      if (isArchivePage && event.keyCode === 13) {
        event.preventDefault();
        event.stopPropagation();
      }
    }
    if (isArchivePage) {
      window.addEventListener('keydown', handleKeydown, true);
    }
    return () => {
      window.removeEventListener('keydown', handleKeydown, true);
    };
  }, [isArchivePage]);

}

export function useKeyCallback(
    { keyCode = undefined } = {},
    callback,
    callEffect = true
) {
  const disableKeyCallback = useSelector(state => state.ui.disableKeyCallback);
  /* ReduxActions */
  const setDisableKeyCallbackAction = useActions(setDisableKeyCallback);

  const lastPressed = useRef(0)

  function detectEscape(e) {
    const now = Date.now();
    if (e.keyCode === keyCode && now - lastPressed.current > 1000) {
      lastPressed.current = now;
      callback();
    }
  }

  useEffect(() => {
    if(!callEffect){
      return
    }
    if(!disableKeyCallback){
      document.addEventListener("keydown", detectEscape, true)
    }

    return function() {
      document.removeEventListener("keydown", detectEscape, true);
    };
  }, [disableKeyCallback]);

  return { setDisableKeyCallback: setDisableKeyCallbackAction };
}

export function useDropdown() {
  const [dropdownVisible, setDropdownVisible] = useState(null);
  useEffect(() => {
    if (dropdownVisible === false) setDropdownVisible(null);
  }, [dropdownVisible]);

  return { dropdownVisible, setDropdownVisible };
}

/**
 * Accepts a media query string then uses the
 * [window.matchMedia](https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia) API to determine if it
 * matches with the current document.<br />
 * It also monitor the document changes to detect when it matches or stops matching the media query.<br />
 * Returns the validity state of the given media query.
 *
 */
export const useMediaQuery = mediaQuery => {
  const errorMessage =
    "matchMedia is not supported, this could happen both because window.matchMedia is not supported by" +
    " your current browser or you're using the useMediaQuery hook whilst server side rendering.";

  if (!(typeof window === "object" && "matchMedia" in window)) {
    // eslint-disable-next-line no-console
    console.warn(errorMessage);
    return null;
  }

  const [isVerified, setIsVerified] = useState(
    !!window.matchMedia(mediaQuery).matches
  );

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery);
    const documentChangeHandler = () => setIsVerified(!!mediaQueryList.matches);

    mediaQueryList.addListener(documentChangeHandler);

    documentChangeHandler();
    return () => {
      mediaQueryList.removeListener(documentChangeHandler);
    };
  }, [mediaQuery]);

  return isVerified;
};

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default useActions;
