import {
  USER_LOGIN,
  SET_DATABASE_TABLE_VALUES,
  SET_DATABASE_TABLE_FILTERS,
  SET_DATABASE_TABLE_APPLIED_FILTERS
} from "fleksRedux/types";
import { combineReducers } from "redux";
import moment from "moment";

const INITIAL_FILTERS = {
  startDate: moment().format("DD-MM-YYYY"),
  endDate: moment()
    .add(30, "d")
    .format("DD-MM-YYYY")
};

const columns = (
  state = [
    "title",
    "project_manager",
    "times",
    "progress",
    "total_positions",
    "last_comment"
  ],
  action = {}
) => {
  switch (action.type) {
    case USER_LOGIN:
      return state /* action.data.preferences.database &&
        typeof action.data.preferences.database.planningProgress !== "undefined"
        ? action.data.preferences.database.planningProgress.columns
        :  */;

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "planningProgress" && action.data.columns
        ? action.data.columns
        : state;

    default:
      return state;
  }
};

const selectedTab = (state = "projects", action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return state;
    /* action.data.preferences.database &&
      typeof action.data.preferences.database.planningProgress !== "undefined"
        ? action.data.preferences.database.planningProgress.selectedTab
        : state; */

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "planningProgress" && action.data.tab
        ? action.data.tab
        : state;

    default:
      return state;
  }
};

const page = (state = 1, action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.planningProgress !== "undefined"
        ? action.data.preferences.database.planningProgress.page
        : state;

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "planningProgress" && action.data.page
        ? action.data.page
        : state;

    case SET_DATABASE_TABLE_APPLIED_FILTERS:
      return 1;

    default:
      return state;
  }
};

const size = (state = 25, action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.planningProgress !==
        "undefined" &&
        action.data.preferences.database.planningProgress.size <= 100
        ? action.data.preferences.database.planningProgress.size
        : state;

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "planningProgress" && action.data.size
        ? action.data.size
        : state;

    default:
      return state;
  }
};

const sortKey = (state = "title", action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "planningProgress" && action.data.sortKey
        ? action.data.sortKey
        : state;

    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.planningProgress !== "undefined"
        ? action.data.preferences.database.planningProgress.sortKey
        : state;


    default:
      return state;
  }
};

const filters = (state = INITIAL_FILTERS, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_FILTERS:
      return action.data.table === "planningProgress"
        ? { ...state, ...action.data.filters }
        : state;

    case SET_DATABASE_TABLE_APPLIED_FILTERS:
      return action.data.table === "planningProgress"
        ? { ...state, ...action.data.filters }
        : state;

    default:
      return state;
  }
};

const appliedFilters = (state = INITIAL_FILTERS, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_APPLIED_FILTERS:
      return action.data.table === "planningProgress"
        ? { ...state, ...action.data.filters }
        : state;

    default:
      return state;
  }
};

const selectedItems = (state = [], action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "planningProgress" && action.data.items
        ? action.data.items
        : state;

    default:
      return state;
  }
};

const reset = (state = false, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "planningProgress" &&
        typeof action.data.reset === "boolean"
        ? action.data.reset
        : state;

    default:
      return state;
  }
};

const allListSelected = (state = false, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "planningProgress" &&
        typeof action.data.allListSelected === "boolean"
        ? action.data.allListSelected
        : state;

    default:
      return state;
  }
};

const total = (state = 0, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "planningProgress" &&
        typeof action.data.total === "number"
        ? action.data.total
        : state;

    default:
      return state;
  }
};

const listConnections = (state = false, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "planningProgress" &&
        typeof action.data.listConnections === "boolean"
        ? action.data.listConnections
        : state;

    default:
      return state;
  }
};

export const planningProgress = combineReducers({
  columns,
  selectedTab,
  size,
  page,
  sortKey,
  filters,
  appliedFilters,
  selectedItems,
  reset,
  allListSelected,
  total,
  listConnections
});

export default planningProgress;
