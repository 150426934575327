import {
  SET_EMPLOYEES_POOLS_FILTERS,
  SET_EMPLOYEES_POOLS_APPLIED_FILTERS,
} from "fleksRedux/types";
import { combineReducers } from "redux";

const filters = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_EMPLOYEES_POOLS_FILTERS:
      return action.data.table === "jobs"
        ? { ...state, ...action.data.filters }
        : state;

    case SET_EMPLOYEES_POOLS_APPLIED_FILTERS:
      return action.data.table === "jobs"
        ? { ...state, ...action.data.filters }
        : state;

    default:
      return state;
  }
};

const appliedFilters = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_EMPLOYEES_POOLS_APPLIED_FILTERS:
      return action.data.table === "jobs"
        ? { ...state, ...action.data.filters }
        : state;

    default:
      return state;
  }
};

export const jobs = combineReducers({
  filters,
  appliedFilters,
});

export default jobs;
