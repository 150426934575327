import { CHANGE_LANGUAGE, LOAD_CATEGORIES, USER_LOGIN, LOAD_LANGUAGES } from "fleksRedux/types";

const data = (
  state = {
    language: "nl",
    categories: { regular: [], trainings: [], qualifications: [] },
    languages: []
  },
  action
) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return Object.assign({}, state, { language: action.language });

    case LOAD_CATEGORIES:
      if (action.categories) {
        let regular = [],
          trainings = [],
          qualifications = [];
        for (const category of action.categories) {
          switch (category.job_category_type) {
            case "regular":
              if (!regular) {
                regular = [];
              }
              regular.push(category);
              break;

            case "trainings":
              if (!trainings) {
                trainings = [];
              }
              trainings.push(category);
              break;

            case "qualifications":
              if (!qualifications) {
                qualifications = [];
              }
              qualifications.push(category);
              break;
          }
        }
        return Object.assign({}, state, {
          categories: { regular, trainings, qualifications }
        });
      }
      return state;

    case USER_LOGIN:
      return {
        ...state,
        language:
          (action.data &&
            action.data.current_language &&
            action.data.current_language.code) ||
          "nl"
      };

    case LOAD_LANGUAGES:
      if (action.languages) {
        return {...state, languages: action.languages}
      }


    default:
      return state;
  }
};

export default data;
