import { SET_PREFERENCES_WITH_TEMP, USER_LOGIN } from "fleksRedux/types";
import { combineReducers } from "redux";

const excludeNoValidContract = (state = false, action = {}) => {
  switch (action.type) {
    case SET_PREFERENCES_WITH_TEMP:
      return typeof action.data.excludeNoValidContract !== "undefined"
        ? action.data.excludeNoValidContract
        : state;

    case USER_LOGIN:
      return action.data.preferences.planner &&
        action.data.preferences.planner.complications &&
        typeof action.data.preferences.planner.complications
          .excludeNoValidContract !== "undefined"
        ? action.data.preferences.planner.complications.excludeNoValidContract
        : state;

    default:
      return state;
  }
};

const excludeInvitedToAnotherJob = (state = false, action = {}) => {
  switch (action.type) {
    case SET_PREFERENCES_WITH_TEMP:
      return typeof action.data.excludeInvitedToAnotherJob !== "undefined"
        ? action.data.excludeInvitedToAnotherJob
        : state;

    case USER_LOGIN:
      return action.data.preferences.planner &&
        action.data.preferences.planner.complications &&
        typeof action.data.preferences.planner.complications
          .excludeInvitedToAnotherJob !== "undefined"
        ? action.data.preferences.planner.complications
            .excludeInvitedToAnotherJob
        : state;

    default:
      return state;
  }
};

const excludeScheduledToAnotherJob = (state = false, action = {}) => {
  switch (action.type) {
    case SET_PREFERENCES_WITH_TEMP:
      return typeof action.data.excludeScheduledToAnotherJob !== "undefined"
        ? action.data.excludeScheduledToAnotherJob
        : state;

    case USER_LOGIN:
      return action.data.preferences.planner &&
        action.data.preferences.planner.complications &&
        typeof action.data.preferences.planner.complications
          .excludeScheduledToAnotherJob !== "undefined"
        ? action.data.preferences.planner.complications
            .excludeScheduledToAnotherJob
        : state;

    default:
      return state;
  }
};

const complications = combineReducers({
  excludeNoValidContract,
  excludeInvitedToAnotherJob,
  excludeScheduledToAnotherJob
});

export default complications;
