import { findLastIndex } from "lodash";
import { GET_EMPLOYEE_IMAGES, SET_ATTRIBUTES, SELECTED_IMAGES, SET_SELECTED_EMPLOYEE, SET_CROPPED_AREA, SET_IMAGE_SELECTED, DELETE_SELECTED_IMAGE, SET_FREE_DROPDOWN, SET_GENERAL_DROPDOWN, SET_CHAR_DROPDOWN, SET_DROPDOWN, SET_IMAGE_URL_LIST, SET_CROPPING, SET_ZOOM, SET_IMAGE_LOADING, SET_ROTATION } from "fleksRedux/types";


const employeeSetCards = (
    state = {
        employees: [],
        attributes: [],
        dropdownValues: {
            general: [],
            characteristics: [],
            free: []
        }
    },
    action = {}
) => {
    switch (action.type) {
        case SET_FREE_DROPDOWN:
            return {
                ...state,
                dropdownValues: {
                    ...state.dropdownValues,
                    free: action.data
                }
            }
        case SET_DROPDOWN:
            return {
                ...state,
                dropdownValues: action.data
            }
        case SET_GENERAL_DROPDOWN:
            return {
                ...state,
                dropdownValues: {
                    ...state.dropdownValues,
                    general: action.data
                }
            }
        case SET_CHAR_DROPDOWN:
            return {
                ...state,
                dropdownValues: {
                    ...state.dropdownValues,
                    characteristics: action.data
                }
            }
        case SET_SELECTED_EMPLOYEE:
            return {
                ...state,
                employees: [...state.employees, action.data],
            }
        case SET_IMAGE_LOADING: {
            let findIndexLoading = state.employees.findIndex(el => el.employee_uuid === action.uuid)
            if (findIndexLoading !== -1) {
                state.employees[findIndexLoading].imageLoading = action.data
            }
            return {
                ...state,
                employees: [...state.employees],
            }
        }
        case SET_IMAGE_URL_LIST:
            let findIndex = state.employees.findIndex(el => el.employee_uuid === action.uuid)
            if (findIndex !== -1) {
                state.employees[findIndex].imageUrlsList = action.data;
                state.employees[findIndex].imageLoading = false
            }
            return {
                ...state,
                employees: [...state.employees],
            }
        case SET_ATTRIBUTES:
            return {
                ...state,
                attributes: action.data,
            };
        case GET_EMPLOYEE_IMAGES:
            return {
                ...state,
                employees: action.data,
            };
        case SET_IMAGE_SELECTED:
            const findEmployeeIndexValue = state.employees.findIndex(el => el.employee_uuid === action.uuid);
            if (findEmployeeIndexValue !== -1) {
                let findImageIndex = state.employees[findEmployeeIndexValue].imageUrlsList.findIndex(el => el.uuid === action.imageuuid);
                state.employees[findEmployeeIndexValue].imageUrlsList[findImageIndex].selected = !state.employees[findEmployeeIndexValue].imageUrlsList[findImageIndex].selected
            }
            return {
                ...state,
                employees: [...state.employees],
            }
        case SELECTED_IMAGES:
            const findIndexValue = state.employees.findIndex(el => el.employee_uuid === action.uuid);
            if (findIndexValue !== -1) {
                if (state.employees[findIndexValue].model_images[0] === null) {
                    state.employees[findIndexValue].model_images[0] = action.data
                } else if (state.employees[findIndexValue].model_images[1] === null) {
                    state.employees[findIndexValue].model_images[1] = action.data
                } else if (state.employees[findIndexValue].model_images[2] === null) {
                    state.employees[findIndexValue].model_images[2] = action.data
                }
            }
            return {
                ...state,
                employees: [...state.employees]
            }
        case SET_CROPPING:
            const findEmpIndexCrop = state.employees.findIndex(el => el.employee_uuid === action.uuid);
            if (findEmpIndexCrop != -1) {
                state.employees[findEmpIndexCrop].cropping[action.actionType] = action.data
            }
            return {
                ...state,
                employees: [...state.employees],
            }
        case SET_ZOOM:
            const findEmpIndexZoom = state.employees.findIndex(el => el.employee_uuid === action.uuid);
            if (findEmpIndexZoom != -1) {
                state.employees[findEmpIndexZoom].cropping[action.actionType] = action.data
            }
            return {
                ...state,
                employees: [...state.employees],
            }
        case SET_ROTATION:
            const findEmpIndexRotation = state.employees.findIndex(el => el.employee_uuid === action.uuid);
            if (findEmpIndexRotation != -1) {
                state.employees[findEmpIndexRotation].cropping[action.actionType] = action.data
            }
            return {
                ...state,
                employees: [...state.employees],
            }
        case DELETE_SELECTED_IMAGE:
            const findEmpiIndexValue = state.employees.findIndex(el => el.employee_uuid === action.uuid);
            if (findEmpiIndexValue !== -1) {
                let findImageIndex = state.employees[findEmpiIndexValue].model_images.findIndex(el => el.uuid === action.imageuuid)
                if (findImageIndex !== -1) {
                    state.employees[findEmpiIndexValue].model_images[findImageIndex] = null
                    if(findImageIndex === 0) {
                        state.employees[findEmpiIndexValue].cropping.rotation1 = 0
                    }else if(findImageIndex === 1) {
                        state.employees[findEmpiIndexValue].cropping.rotation2 = 0
                    }else if(findImageIndex === 2) {
                        state.employees[findEmpiIndexValue].cropping.rotation3 = 0
                    }
                }
            }
            return {
                ...state,
                employees: [...state.employees],
            }
        case SET_CROPPED_AREA:
            const findEmpIndexValue = state.employees.findIndex(el => el.employee_uuid === action.uuid);
            if (findEmpIndexValue !== -1) {
                const findUrlModal = state.employees[findEmpIndexValue].model_images.findIndex(el => el !== null ? el.uuid === action.data.uuid : null);
                if (findUrlModal != -1) {
                    state.employees[findEmpIndexValue].model_images[findUrlModal] = {
                        ...state.employees[findEmpIndexValue].model_images[findUrlModal],
                        croppedArea: action.data.croppedArea
                    }
                }
            }
            return {
                ...state,
                employees: [...state.employees],
            }
        default:
            return state;
    }
};

export default employeeSetCards;