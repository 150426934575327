import { combineReducers } from "redux";
import { SET_TABLE_TO_CUSTOMIZE, SET_SELECTED_COLUMNS } from "fleksRedux/types";

const table = (state = null, action = {}) => {
  switch (action.type) {
    case SET_TABLE_TO_CUSTOMIZE:
      return action.data.table;

    default:
      return state;
  }
};

const selectedColumns = (state = [], action = {}) => {
  switch (action.type) {
    case SET_TABLE_TO_CUSTOMIZE:
      return action.data.selectedColumns;

    case SET_SELECTED_COLUMNS:
      return action.data;

    default:
      return state;
  }
};

export const customizationModal = combineReducers({
  table,
  selectedColumns
});

export default customizationModal;
