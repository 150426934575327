import axios from "axios";
import FileSaver from "file-saver";
import { serverConfig } from "../config";
import { correctResults, getFiltersString } from "utils/helpers";
const apiUrl = serverConfig.apiUrl;

const hourService = {
  getAllHours: (
    token,
    tenantUuid,
    key,
    limit = 25,
    page = 1,
    filtersString,
    isArchive = "False",
    sortType = "sort",
    completed,
    approved,
    exported,
    isExport, //Is necessary for export excel
    query = {}
  ) => {
    let headers = { headers: { Authorization: `JWT ${token}` } };
    if (isExport && (isExport == "True" || isExport == "False"))
      headers.responseType = "blob";

    return axios.get(
      `${apiUrl}/api/jobs/application-list/all/${tenantUuid}/?${
        key ? `${sortType}=${key}&` : ""
      }invalid_state__isnull=True&is_archived=${isArchive}${
        completed ? `&is_completed=${completed}` : ""
      }${approved ? `&is_approved=${approved}` : ""}${
        exported ? `&is_exported=${exported}` : ""
      }&page_size=${limit}&page=${page}${filtersString ? filtersString : ""}` +
        `${isExport ? `&is_excel_export=${isExport}` : ""}` +
        `${query.columns ? `&excel_properties=${query.columns}` : ``}` +
        `${
          query.columnHeaders
            ? `&excel_column_headers=${query.columnHeaders}`
            : ``
        }`,
      headers
    );
  },
  getAllHoursLight: (
    token,
    tenantUuid,
    key,
    limit = 25,
    page = 1,
    filtersString,
    isArchive = "False",
    sortType = "sort",
    completed,
    approved,
    exported,
    isExport,
    query = {}
  ) => {
    let headers = { headers: { Authorization: `JWT ${token}` } };
    if (isExport && (isExport == "True" || isExport == "False"))
      headers.responseType = "blob";

    return axios.get(
      `${apiUrl}/api/jobs/application-list/all-light/${tenantUuid}/?${
        key ? `${sortType}=${key}&` : ""
      }invalid_state__isnull=True&is_archived=${isArchive}${
        completed ? `&is_completed=${completed}` : ""
      }${approved ? `&is_approved=${approved}` : ""}${
        exported ? `&is_exported=${exported}` : ""
      }${limit ? `&page_size=${limit}` : ""}${page ? `&page=${page}` : ""}${
        filtersString ? filtersString : ""
      }` +
        `${isExport ? `&is_excel_export=${isExport}` : ""}` +
        `${query.columns ? `&excel_properties=${query.columns}` : ``}` +
        `${
          query.columnHeaders
            ? `&excel_column_headers=${query.columnHeaders}`
            : ``
        }`,
      headers
    );
  },
  getAllHoursLight2: (
    token,
    tenantUuid,
    key,
    limit = 25,
    page = 1,
    filtersString,
    isArchive = "False",
    sortType = "sort",
    completed,
    approved,
    exported,
    isExport,
    query = {}
  ) => {
    let headers = { headers: { Authorization: `JWT ${token}` } };
    if (isExport && (isExport == "True" || isExport == "False"))
      headers.responseType = "blob";

    return axios.get(
      `${apiUrl}/api/jobs/application-list/all-light/${tenantUuid}/?${
        key ? `${sortType}=${key}` : ""
      }${isArchive ? `&is_archived=${isArchive}` : ""}${
        completed ? `&is_completed=${completed}` : ""
      }${approved ? `&is_approved=${approved}` : ""}${
        exported ? `&is_exported=${exported}` : ""
      }&page_size=${limit}&page=${page}${filtersString ? filtersString : ""}` +
        `${isExport ? `&is_excel_export=${isExport}` : ""}` +
        `${query.columns ? `&excel_properties=${query.columns}` : ``}` +
        `${
          query.columnHeaders
            ? `&excel_column_headers=${query.columnHeaders}`
            : ``
        }`,
      headers
    );
  },
  getOneHour: (token, tenantUuid, hourUuid) => {
    return axios.get(
      `${apiUrl}/api/jobs/application-list/all/${tenantUuid}/?uuid=${hourUuid}`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getCheckInCheckOut: (token, jobUuid) => {
    return axios.get(`${apiUrl}/api/jobs/application/${jobUuid}`, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  updateHourStatus: (token, hourUuid, newStatus) => {
    const payload = {};
    // console.log("Here is the payload", payload);

    return axios.put(
      `${apiUrl}/api/jobs/application/${hourUuid}/update/${newStatus}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getTenantFromJaps: (token, japListUuid) => {
    return axios.post(`${apiUrl}/api/jobs/tenants-from-japs/`, japListUuid, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  createFeedbackLoops: (token, japListUuid) => {
    return axios.post(`${apiUrl}/api/jobs/feedback/`, japListUuid, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  updateFeedbackLoop: (token, feedbackUuid, payload) => {
    return axios.put(
      `${apiUrl}/api/jobs/feedback-update/${feedbackUuid}/`,
      payload,
      {
        headers: {
          Authorization: `JWT ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
  },
  sendReviewedHours: (token, feedbackUuidList) => {
    return axios.put(`${apiUrl}/api/jobs/feedback-send/`, feedbackUuidList, {
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    });
  },
  setHourProperty: (token, hourUuid, property, newValue, isSharedEmployee) => {
    let payloadValue = null;
    // if (!newValue.includes("null")) payloadValue = newValue;
    const payload = JSON.stringify(property);
    // console.log("Here is the payload", payload);

    return axios.put(
      `${apiUrl}/api/${
        isSharedEmployee ? "shared/job-application/" : "jobs/application/"
      }${isSharedEmployee ? "" : "update/"}${hourUuid}/`,
      payload,
      {
        headers: {
          Authorization: `JWT ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
  },
  setHourProperties: (token, hourUuid, payload) => {
    return axios.put(
      `${apiUrl}/api/jobs/application/update/${hourUuid}/`,
      payload,
      {
        headers: {
          Authorization: `JWT ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
  },
  setHourCorrection: (token, hourUuid, payload) => {
    return axios.put(
      `${apiUrl}/api/jobs/workflow-fields/${hourUuid}/`,
      payload,
      {
        headers: {
          Authorization: `JWT ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
  },
  createCorrection: (token, hourUuid, payload) => {
    return axios.post(
        `${apiUrl}/api/jobs/corrections/`,
        payload,
        {
          headers: {
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
        }
    );
  },
  bulkUpdateHourProperties: (token, payload) => {
    return axios.put(`${apiUrl}/api/jobs/application/update/hours/`, payload, {
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    });
  },
  setDeclarationProperties: (token, tenant, hourUuid, payload) => {
    return axios.put(
      `${apiUrl}/api/jobs/declaration/${tenant}/${hourUuid}/`,
      payload,
      {
        headers: {
          Authorization: `JWT ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
  },
  setApproveStatus: (token, payload) => {
    return axios.put(`${apiUrl}/api/jobs/application/approve/`, payload, {
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
    });
  },
  setHour: (token, tenantUuid, hourUuid, payload) => {
    return axios.put(
      `${apiUrl}/api/jobs/declaration/${tenantUuid}/${hourUuid}/`,
      payload,
      {
        headers: {
          Authorization: `JWT ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
  },
  setCheckInCheckOut: (token, tenant, hourUuid, property, newValue) => {
    const payload = JSON.stringify({ [property]: newValue });
    // console.log("Here is the payload", payload);

    return axios.put(
      `${apiUrl}/api/jobs/declaration/${tenant}/${hourUuid}/`,
      payload,
      {
        headers: {
          Authorization: `JWT ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
  },
  createHours: (token, jobUuid, employeesUuids, callbackError) => {
    return Promise.all(
      employeesUuids.map((employeeUuid) =>
        axios
          .post(
            `${apiUrl}/api/jobs/application/${jobUuid}/${employeeUuid}/`,
            {},
            { headers: { Authorization: `JWT ${token}` } }
          )
          // .then(response => {
          //   return axios.put(
          //     `${apiUrl}/api/jobs/application/${
          //       response.data.results.uuid
          //     }/update/job_accepted/`,
          //     {},
          //     {
          //       headers: { Authorization: `JWT ${token}` }
          //     }
          //   );
          // })
          .catch((err) => {
            callbackError(err, employeeUuid);
            return err.response;
          })
      )
    ).then((response) => correctResults(response));
  },
  createHoursBulk: (token, jobUuid, payload) => {
    return axios.post(
      `${apiUrl}/api/jobs/application/${jobUuid}/bulk/`,
      payload,
      { headers: { Authorization: `JWT ${token}` } }
    );
  },
  createHourSingle: (token, jobUuid, employeeUuid, payload) => {
    return axios.post(
      `${apiUrl}/api/jobs/application/${jobUuid}/${employeeUuid}/`,
      payload,
      { headers: { Authorization: `JWT ${token}` } }
    );
  },
  updateHourImage: (token, tenantUuid, hourUuid, payload) => {
    return axios.put(
      `${apiUrl}/api/jobs/declaration/image/${tenantUuid}/${hourUuid}/`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  deleteHourImage: (token, imageUuid, hourUuid) => {
    return axios.delete(
      `${apiUrl}/api/jobs/declaration/image/delete/${hourUuid}/${imageUuid}/`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  updateShiftCheckinCheckOut: (token, check_in, checkout, uuid) => {
    let payload = {
      check_in2: check_in,
    };
    if (checkout) {
      payload = {
        ...payload,
        check_out: checkout,
      };
    }
    return axios.put(
      `${apiUrl}/api/jobs/check-in-check-out/update/${uuid}/`,
      payload,
      {
        headers: {
          Authorization: `JWT ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
  },

  /**
   *
   * Returns the list of users with their total costs filtered by from-date and to-date.
   */
  getEmployeesByDates: (
    token,
    tenantUuid,
    from,
    to,
    key,
    limit = 25,
    page = 1,
    filtersString,
    sortType = "sort",
    isExport,
    query = {}
  ) => {
    let headers = { headers: { Authorization: `JWT ${token}` } };
    if (isExport && (isExport == "True" || isExport == "False"))
      headers.responseType = "blob";
    return axios.get(
      `${apiUrl}/api/jobs/job-application/employee/${tenantUuid}/${from}/${to}/?${
        key ? `${sortType}=${key}&` : ""
      }page_size=${limit}&page=${page}${
        filtersString
          ? typeof filtersString === "object"
            ? getFiltersString(filtersString)
            : filtersString
          : ""
      }${isExport ? `&is_excel_export=${isExport}` : ""}${
        query.columns ? `&excel_properties=${query.columns}` : ""
      }${
        query.columnHeaders
          ? `&excel_column_headers=${query.columnHeaders}`
          : ""
      }`,
      headers
    );
  },
  createBulk: (token, employeeUuid, jobsUuid) => {
    return axios.post(
      `${apiUrl}/api/jobs/bulk-application/${employeeUuid}/`,
      { jobs_uuid: jobsUuid },
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getHoursFilterByEmployeeAndJobs: (
    token,
    tenantUuid,
    employeeUuid,
    jobUuid, //Support multiples jobs separate by commas
    options = {}
  ) => {
    const { filters = {} } = options;

    return axios.get(
      `${apiUrl}/api/jobs/application-list/all-light/${tenantUuid}/?invalid_state__isnull=True${
        filters.archived ? `&is_archived=${filters.archived}` : ""
      }&employee__uuid=${employeeUuid}&job__uuid__in=${jobUuid}`,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  acceptHour: (token, hourUuid) => {
    return axios.put(
      `${apiUrl}/api/jobs/application/${hourUuid}/update/job_accepted/`,
      {},
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  downloadReceipts(
    token,
    tenantUuid,
    {
      filters = {},
      includeInvalidHours = false,
      filename = "receipt-images",
    } = {}
  ) {
    const filterString =
      typeof filters === "object" ? getFiltersString(filters) : filters;

    return axios
      .post(
        `${apiUrl}/api/jobs/download/receipts/${tenantUuid}/?${
          includeInvalidHours ? "" : "is_archived=False"
        }${filterString}`,
        null,
        {
          headers: { Authorization: `JWT ${token}` },
          responseType: "blob",
        }
      )
      .then(({ data }) => {
        return FileSaver.saveAs(
          data instanceof Blob ? data : new Blob([data]),
          filename
        );
      });
  },
  downloadAllMedia: (
    token,
    tenantUuid,
    { filters = {}, includeInvalidHours = false } = {}
  ) => {
    const filterString =
      typeof filters === "object" ? getFiltersString(filters) : filters;

    return axios.post(
      `${apiUrl}/api/jobs/images/download/${tenantUuid}/` +
        `?${
          includeInvalidHours ? "" : "is_archived=False"
        }${filterString}&is_payed_out=True`,
      {
        photo_types: ["other_costs", "public_transport"],
      },
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  getTableShifts(
    token,
    {
      sortKey = "start_date",
      pagination = { page: 1, size: 25 },
      isArchive = false,
      filters = {},
      exportOptions = null,
    }
  ) {
    const filtersString = getFiltersString(filters);

    return axios.get(
      `${apiUrl}/api/jobs/job-applications/list/?sort=${sortKey}${
        pagination
          ? `&page=${pagination.page}&page_size=${pagination.size}`
          : ""
      }&is_archived=${isArchive ? "True" : "False"}${filtersString}${
        exportOptions
          ? `&is_excel_export=True&excel_properties=${exportOptions.columns}&excel_column_headers=${exportOptions.columnsNames}`
          : ""
      }&shared_statuses=${"accepted,pending,rejected,withdrawn"}`,
      {
        headers: { Authorization: `JWT ${token}` },
        responseType: exportOptions ? "blob" : "json",
      }
    );
  },
  getAllHoursShifts(
    token,
    {
      sortKey = "start_date",
      pagination = { page: 1, size: 25 },
      isArchive = false,
      filters = {},
      exportOptions = null,
    }
  ) {
    const filtersString = getFiltersString(filters);

    return axios.get(
      `${apiUrl}/api/jobs/applications/?sort=${sortKey}${
        pagination
          ? `&page=${pagination.page}&page_size=${pagination.size}`
          : ""
      }&is_archived=${isArchive ? "True" : "False"}${filtersString}${
        exportOptions
          ? `&is_excel_export=True&excel_properties=${exportOptions.columns}&excel_column_headers=${exportOptions.columnsNames}`
          : ""
      }&shared_statuses=${"accepted,pending,rejected,withdrawn"}`,
      {
        headers: { Authorization: `JWT ${token}` },
        responseType: exportOptions ? "blob" : "json",
      }
    );
  },
  exportAllShifts: (
    token,
    key,
    filtersString = "",
    isArchive = "False",
    sortType = "sort",
    payload,
    exportType = "hours",
    type = "all",
    exportAll = true
  ) => {
    function padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    }
    const date = new Date();
    const endDate = [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("-");
    const noEndDateFilter =
      type !== "past"
        ? filtersString.replace(/end_date__lte=([^&]+)/, "")
        : filtersString;
    return axios.post(
      `${apiUrl}/api/jobs/applications/export/?export_all=${exportAll}&${
        key ? `${sortType}=${key}&` : ""
      }shared_statuses=${"accepted,pending,rejected,withdrawn"}&is_archived=${isArchive}${
        filtersString
          ? exportType === "shifts"
            ? noEndDateFilter
            : filtersString
          : ""
      }`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  bulkUpdateJobApplications: (token, payload) => {
    return axios.put(`${apiUrl}/api/jobs/application/bulk-update/`, payload, {
      headers: { Authorization: `JWT ${token}` },
    });
  },
  exportAllSingles: (
    token,
    key,
    filtersString,
    isArchive = "False",
    sortType = "sort",
    completed,
    approved,
    exported
  ) => {
    return axios.post(
      `${apiUrl}/api/jobs/declarations/export/?${
        key ? `${sortType}=${key}&` : ""
      }shared_statuses=${"accepted,pending,rejected,withdrawn"}&is_archived=${isArchive}${
        completed ? `&is_completed=${completed}` : ""
      }${approved ? `&is_approved=${approved}` : ""}${
        exported ? `&is_exported=${exported}` : ""
      }${filtersString ? filtersString : ""}`,
      {},
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  /**
   * @param {string} token
   * @param {{
   *  sortKey?: string;
   *  pagination?: { page?: number; size?: number; };
   *  filters?: { [key: string]: { key: string; value: string; suffix?: string; prefix?: string; } };
   *  isArchive?: boolean;
   * }} options
   */
  getTableSingles(
    token,
    {
      sortKey = "start_date",
      pagination = { page: 1, size: 25 },
      filters = {},
      isArchive = false,
    }
  ) {
    const filtersString = getFiltersString(filters);

    return axios.get(
      `${apiUrl}/api/jobs/declarations/?sort=${sortKey}${
        pagination
          ? `&page=${pagination.page}&page_size=${pagination.size}`
          : ""
      }&is_archived=${isArchive ? "True" : "False"}${filtersString}`,
      {
        headers: { Authorization: `JWT ${token}` },
        responseType: "json",
      }
    );
  },
  /**
   * @param {string} token
   * @param {{
   *  sortKey?: string;
   *  archived?: boolean;
   *  payload?: any;
   *  filters?: { [key: string]: { key: string; value: string; suffix?: string; prefix?: string; } };
   * }} options
   */
  exportSingles(
    token,
    {
      sortKey = "start_date",
      archived = false,
      payload = "",
      filters = {},
    } = {},
    tableType
  ) {
    const filtersString = getFiltersString(filters);

    return axios.post(
      `${apiUrl}/api/jobs/declarations/export/?${
        sortKey ? `sort=${sortKey}&` : ""
      }shared_statuses=${"accepted,pending,rejected,withdrawn"}&is_archived=${archived ? "True" : "False"}${
        filtersString ? filtersString : ""
      }&export_all=${(tableType === "allHours") ? "True" : "False" }`,
      payload,
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  },
  sendToFinqle(token, system, job_application_uuids){
    return axios.post(
      `${apiUrl}/api/external/send_hours/`,
      {system, job_application_uuids},
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
  }
};

export default hourService;
