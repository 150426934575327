import { combineReducers } from "redux";
import {
  SET_SHOW_SIDEBAR,
  SET_SELECTED_CLUSTER,
  SET_SELECTED_JOB,
  SET_APPLICATIONS,
  ADD_APPLICATION,
  SET_INVITATIONS,
  ADD_INVITATIONS,
  SET_REGISTERED,
  ADD_REGISTERED,
  SET_PROPOSED,
  ADD_PROPOSED,
  SET_CANCELED_APPLICATIONS,
  SET_CANCELED_INVITATIONS,
  SET_CANCELED_REGISTERED,
  SET_CANCELED_PROPOSED,
  ADD_CANCELED_APPLICATION,
  ADD_CANCELED_INVITATION,
  ADD_CANCELED_PROPOSED,
  ADD_CANCELED_REGISTERED,
  REMOVE_CANCELED_INVITATION,
  REMOVE_CANCELED_APPLICATION,
  REMOVE_CANCELED_REGISTERED,
  REMOVE_CANCELED_PROPOSED,
  SET_PLANNER_SIDEBAR_LOADING,
  SET_PLANNER_SIDEBAR_RELOAD
} from "fleksRedux/types";

const show = (state = false, action = {}) => {
  switch (action.type) {
    case SET_SHOW_SIDEBAR:
      return action.data;

    default:
      return state;
  }
};

const selectedJob = (state = null, action = {}) => {
  switch (action.type) {
    case SET_SELECTED_JOB:
      return action.data;

    default:
      return state;
  }
};

const selectedCluster = (state = null, action = {}) => {
  switch (action.type) {
    case SET_SELECTED_CLUSTER:
      return action.data;

    default:
      return state;
  }
};

const applications = (state = [], action = {}) => {
  switch (action.type) {
    case SET_APPLICATIONS:
      return action.data;

    case ADD_APPLICATION:
      return state.concat(action.data);

    default:
      return state;
  }
};

const invitations = (state = [], action = {}) => {
  switch (action.type) {
    case SET_INVITATIONS:
      return action.data;

    case ADD_INVITATIONS:
      return state.concat(action.data);

    default:
      return state;
  }
};

const registered = (state = [], action = {}) => {
  switch (action.type) {
    case SET_REGISTERED:
      return action.data;

    case ADD_REGISTERED:
      return state.concat(action.data);

    default:
      return state;
  }
};

const proposed = (state = [], action = {}) => {
  switch (action.type) {
    case SET_PROPOSED:
      return action.data;

    case ADD_PROPOSED:
      return state.concat(action.data);

    default:
      return state;
  }
};

const canceledApplications = (state = [], action = {}) => {
  switch (action.type) {
    case SET_CANCELED_APPLICATIONS:
      return action.data;

    case ADD_CANCELED_APPLICATION:
      return state.concat(action.data);

    case REMOVE_CANCELED_APPLICATION:
      return state.filter(application => action.data !== application.uuid);

    default:
      return state;
  }
};

const canceledInvitations = (state = [], action = {}) => {
  switch (action.type) {
    case SET_CANCELED_INVITATIONS:
      return action.data;

    case ADD_CANCELED_INVITATION:
      return state.concat(action.data);

    case REMOVE_CANCELED_INVITATION:
      return state.filter(invitation => action.data !== invitation.uuid);

    default:
      return state;
  }
};

const canceledRegistered = (state = [], action = {}) => {
  switch (action.type) {
    case SET_CANCELED_REGISTERED:
      return action.data;

    case ADD_CANCELED_REGISTERED:
      return state.concat(action.data);

    case REMOVE_CANCELED_REGISTERED:
      return state.filter(invitation => action.data !== invitation.uuid);

    default:
      return state;
  }
};

const canceledProposed = (state = [], action = {}) => {
  switch (action.type) {
    case SET_CANCELED_PROPOSED:
      return action.data;

    case ADD_CANCELED_PROPOSED:
      return state.concat(action.data);

    case REMOVE_CANCELED_PROPOSED:
      return state.filter(proposed => action.data !== proposed.uuid);

    default:
      return state;
  }
};

const loading = (state = false, action = {}) => {
  switch (action.type) {
    case SET_PLANNER_SIDEBAR_LOADING:
      return action.data;

    default:
      return state;
  }
};

const reload = (state = false, action = {}) => {
  switch (action.type) {
    case SET_PLANNER_SIDEBAR_RELOAD:
      return action.data;

    default:
      return state;
  }
};

const sidebar = combineReducers({
  show,
  selectedCluster,
  selectedJob,
  applications,
  invitations,
  registered,
  proposed,
  canceledApplications,
  canceledInvitations,
  canceledRegistered,
  canceledProposed,
  loading,
  reload
});

export default sidebar;
