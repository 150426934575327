import { combineReducers } from "redux";
import { SET_PAGE_SCHEDULER, SET_SCHEDULER_PREFERENCES, SET_WEEK_CHANGE, USER_LOGIN } from "fleksRedux/types";

import card from "./card";

const sortBy = (state = "-contract_hours_difference", action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.scheduler &&
        typeof action.data.preferences.scheduler.sortBy === "string"
        ? action.data.preferences.scheduler.sortBy
        : state;

    case SET_SCHEDULER_PREFERENCES:
      return action.data.sortBy && typeof action.data.sortBy === "string"
        ? action.data.sortBy
        : state;

    default:
      return state;
  }
};

const changeWeek = (state = false, action= {}) => {
  switch(action.type){
    case SET_WEEK_CHANGE: 
      return action.data
    default:
      return state;
  }
}

const setPageScheduler = (state = 1, action={}) => {
  switch(action.type) {
    case SET_PAGE_SCHEDULER: 
      return action.data
    default:
        return state;
  }
}

const preferences = combineReducers({
  sortBy,
  card,
  changeWeek,
  setPageScheduler
});

export default preferences;
