export default {
  label: "en",
  antdPath: require("antd/lib/locale-provider/en_US"),
  reactIntlPath: require("react-intl/locale-data/en"),
  messages: {
    "User.profile": "My profile",
    "User.addName": "Add name",
    "Tenant.organization": "My organization",
    "Tenant.organization.name": "Organization name",
    "NewDashboard.welcomeToFleks": "Welcome to Fleks, {name}!",
    "NewDashboard.getToKnowThePlatform": "Get to know the platform.",
    "NewDashboard.newJob": `New<br/>job`,
    "NewDashboard.createANewJob": "Create a new job",
    "NewDashboard.newApplications": "New applications",
    "NewDashboard.newApplicationsDescription": "React to your candidates!",
    "NewDashboard.newDeclarations": "New declarations",
    "NewDashboard.newInvoices": "New invoices",
    "NewDashboard.newDeclarationsDescription": "Check the worked hours!",
    "NewDashboard.openJobs": "Open jobs",
    "NewDashboard.openJobsDescription": "Fill the open spots!",
    "NewDashboard.goToPlanningProgress": "Go to planning progress",
    "NewDashboard.planningProgress.title": "Your planning progress",
    "NewDashboard.planningProgress.totalPositions": "Total positions",
    "NewDashboard.planningProgress.fromDate": "From date",
    "NewDashboard.planningProgress.toDate": "To date",
    "NewDashboard.planningProgress.progress":
      "Filled internally / Filled externally / Open positions",
    "NewDashboard.planningProgress.startEndDate": "Start and end date",
    "NewDashboard.toTheOldDashboard": "To the old dashboard",
    "NewDashboard.toTheNewDashboard": "To the new dashboard",
    "Dashboard.welcome": "Welcome",
    "Dashboard.hours-today": "Hours today",
    "Dashboard.hours-today.placeholder": "People working hard today",
    "Dashboard.reserved-hours": "Reserved hours",
    "Dashboard.reserved-hours.placeholder": "The reserved pool for today",
    "Dashboard.projects-progress": "Projects progress",
    "Dashboard.projects-progress.placeholder": "Here is how projects are doing",
    "Dashboard.contracts": "Contracts",
    "Dashboard.contracts.placeholder": "Employees with no contracts since",
    "Dashboard.contracts.placeholder.upcomingToExpire":
      "Employees with a expiring contract between",
    "Dashboard.contracts.placeholder.noSigned":
      "Employees with a contract offer which still needs to be signed",
    "Dashboard.tax-rebates": "Changes in wage tax rebate",
    "Dashboard.tax-rebates.placeholder": "Upcoming changes in tax rebates",
    "Dashboard.did-not-check-in": "Did not check in on time", 
    "Dashboard.did-not-check-in.placeholder":
      "Should check in (two hours) before job starts",
    "Dashboard.too-late": "Too late at location",
    "Dashboard.too-late.placeholder": "On the road, but too late at location",
    "Dashboard.tdl-tables": "TDL Tables", 
    "Dashboard.contracts-table": "Contracts table",
    "Dashboard.times": "Times",
    "Dashboard.contractName": "Contract name", 
    "Dashboard.days": "Days",
    "Dashboard.taxRebate-active": "Wage tax rebate active",
    "Dashboard.taxRebate-noActive": "No wage tax rebate",
    "Dashboard.hr-tna": "Total number of employees", 
    "Dashboard.hr-maa": "Monthly active employees",
    "Dashboard.hr-nrtm": "New registration this month",
    "Dashboard.hr-wa": "Whom applicants", 
    "Dashboard.hr-wap": "Whose approval", 
    "Dashboard.map.next-two-hours": "Next two hours",
    "Dashboard.map.jobs-today": "Jobs today", 
    "Dashboard.map.loaded": "Hours loaded of", 
    "Dashboard.map.client": "Client", 
    "Dashboard.map.info-hours": "Similar hours", 
    "Dashboard.map.info-hours.check_in1": "did check-in at", 
    "Dashboard.map.info-hours.check_in2": "did check-in at location at", 
    "Dashboard.map.info-hours.diff": "is going to be", 
    "Dashboard.map.info-hours.late": "minutes late!", 
    "Dashboard.map.info-hours.show": "Show hour details", 
    "Dashboard.map": "Jobs today", 
    "Dashboard.absent": "Absent",
    "Dashboard.absent.noShow": "NO-SHOW",
    "Dashboard.absent.calledOff": "Sick",
    "Dashboard.taxRebate": "Tax rebate",
    "Dashboard.absent.noShow.subtext":
      "Shifts where the employee did not show up between",
    "Dashboard.absent.calledOff.subtext":
      "Shifts where the employee called in sick between",
    "Dashboard.temporaryQualifications": "Temporary qualifications",
    "Dashboard.temporaryQualifications.qualification": "Qualification",
    "Dashboard.temporaryQualifications.expired": "Expired",
    "Dashboard.temporaryQualifications.comingToExpire": "Coming to expire",
    "Dashboard.temporaryQualifications.onGoing": "On going",
    "Dashboard.temporaryQualifications.endDate": "Expiration date",
    "Dashboard.temporaryQualifications.description": "View expired, expiring and ongoing temporary qualifications here",
    "Presets.select-presets": "Select presets",
    "Presets.select-hr": "Select HR Stats", 
    "Presets.reset": "Reset stats", 
    "LoginPage.emailUnregistered":
      "This e-mail address doesn’t match in our database. Try again or",
    "LoginPage.emailInvalid":
      "The e-mail address you provided is not valid. Please try again!",
    "LoginPage.usernameUnregistered":
      "This username doesn’t match in our database. Try again or",
    "LoginPage.usernameInvalid":
      "The username you provided is not valid. Please try again!",
    "LoginPage.requestHelp": "request help.",
    "LoginPage.next": "Continue",
    "LoginPage.login": "Login",
    "LoginPage.mediamarktCheckbox":
      "I hereby agree to the general terms of use and the code of conduct of promoters of MediaMarkt.",
    "LoginPage.passwordPlaceholder": "Enter your password...",
    "LoginPage.passwordInvalid":
      "The combination of your username and password is incorrect. Try again or",
    "LoginPage.resetPassword": "New password",
    "LoginPage.resetPassword.success": "Success!",
    "LoginPage.resetPassword.success.description":
      "You have set a new password",
    "LoginPage.resetPassword.description":
      "Enter a new password. This password must have at least 8 characters.",
    "LoginPage.resetPassword.save": "Save new password",
    "LoginPage.resetPassword.noValidCode": "You cannot complete this process",
    "LoginPage.resetPassword.noValidCode.description":
      "Looks like you already used this link or it has expired.",
    "LoginPage.rememberMe": "Remember Me",
    "LoginPage.confirmation":
      "Click the %sconfirmation link%e inside %bthe mail we send you, so we can %scontinue%e.",
    "LoginPage.gotMail": "You’ve got mail!",
    "LoginPage.validRobot": "Valid that you are not a robot",
    "LoginPage.error": "An error has occurred, please try again later",
    "LoginPage.password": "Password",
    "LoginPage.password.new": "New password",
    "LoginPage.password.confirm": "Confirm password",
    "LoginPage.password.notEqual": "Passwords do not match",
    "LoginPage.username": "Username",
    "LoginPage.newConnection":
      "We noticed that you have been invited to establish a connection, please log in to review the process.",
    "LoginPage.welcome": "Welcome back!",
    "LoginPage.welcome.description":
      "Login with your login credentials (only for employers).",
    "LoginPage.forgotYourPassword": "Forgot your password?",
    "LoginPage.createAccount": "Don’t have an account yet? Create it here!",
    "LoginPage.createAccount.create": "Create an account",
    "LoginPage.createAccount.fillIn": "Fill in the information",
    "LoginPage.createAccount.userAlreadyTaken":
      "This username is already taken",
    "LoginPage.createAccount.companyNameAlreadyTaken":
      "This company name is already taken",
    "LoginPage.createAccount.success":
      "We are creating your account! You will receive a confirmation email in a few minutes so you can sign in and start planning!",
    "LoginPage.createAccount.error":
      "We’re sorry, but we can’t create an account with this information. <a href='https://www.fleks.works/contact' target='_blank'>Please contact us</a> so we can help you with this issue!",
    "LoginPage.createAccount.generalAgreements":
      "By clicking on 'create an account' you agree to the <a href='https://assets.website-files.com/5e6fe86cf07ada83b89b10a2/5f8011c7581ce4bd9999831f_Algemene%20Voorwaarden.pdf' target='_blank'>general terms and conditions & processing agreement</a>",
    "LoginPage.createAccount.goBack": "Go back",
    "LoginPage.createAccount.invalidUsername":
      "The username should not have any spaces.",
    "LoginPage.credentialsDoNotMatch":
      "Your username and password do not match.",
    "LoginPage.forgot": "Reset password",
    "LoginPage.forgot.description":
      "Enter your username with which you would normally log in.Pay attention! This field is case sensitive.",
    "LoginPage.forgot.sendResetEmail": "Send the reset password email",
    "LoginPage.forgot.checkYourEmail": "Check your email",
    "LoginPage.forgot.checkYourEmail.caption":
      "We will send a reset password link to the email address associated with this username. Check your inbox.",
    "LoginPage.forgot.didNotReceive":
      "Didn't receive an email? Then contact your employee.",
    "LoginPage.forgot.itIsNotAValidUsername": "The username is not recognized.",
    "LoginPage.2fa.sendCode.error": "The code entered must contain 6 digits",
    "LoginPage.2fa.descriptionChoose.subTitle":
      "Your company has enabled two factor authentication. Please choose the authentication method by which you want to receive the code to sign in.",
    "LoginPage.2fa.checkboxSelect.methodGoogle": "Use Google Authenticator",
    "LoginPage.2fa.checkboxSelect.methodEmail":
      "Use the email address associated with my account",
    "LoginPage.2fa.descriptionChoose.title": "Select an option",
    "LoginPage.2fa.title.authenticationGoogle": "Google Authentication",
    "LoginPage.2fa.subtitle.authenticationGoogle":
      "Use the google authenticator app to the scan the image below.",
    "LoginPage.2fa.secondSubtitle.authenticationGoogle":
      "After scanning the barcode image, the app will display a 6-digit code that you can enter below.",
    "LoginPage.2fa.thirdSubtitle.authenticationGoogle":
      "Please enter the 6-digit code shown in the Google Authenticator App below.",
    "LoginPage.2fa.title.authenticationEmail": "Email Authentication",
    "LoginPage.2fa.subtitle.authenticationEmail":
      "An email has been sent to the email address associated with your account. Please enter the 6-digit code shown in the email below.",
    "LoginPage.2fa.enterCode": "Enter code",
    "LoginPage.2fa.enterCode.error":
      "The code you entered is not valid, please try again.",
    "LoginPage.2fa.titleSetMethod.successMessage.google":
      "The authentication method has been added to your account!",
    "LoginPage.2fa.descriptionSetMethod.successMessage.google":
      "From now on, each time you log in a verification code will be requested. You can access to this code by using the Google Authentication application. Remember that if you lose your device or want to set up a new authentication method, you need to contact an Administrator to continue.",
    "LoginPage.2fa.titleSetMethod.successMessage.email":
      "The authentication method has been added to your account!",
    "LoginPage.2fa.descriptionSetMethod.successMessage.email":
      "From now on, each time you log in a verification code will be requested. You can access to this code bychecking the inbox of the email associated with your account. Remember that if you lose your device or want to set up a new authentication method, you need to contact an Administrator to continue.",
    "LoginPage.loginEmployee": "Login as employee",
    "PasswordRecoveryPage.resetPassword": "Reset Password",
    "PasswordRecoveryPage.passwordPlaceholder": "New Password",
    "PasswordRecoveryPage.rePasswordPlaceholder": "Repeat password",
    "PasswordRecoveryPage.passwordRequire":
      "All fields are required, please fill in all fields",
    "PasswordRecoveryPage.differentPasswords":
      "Passwords must match please re-enter them",
    "PasswordRecoveryPage.confirmation":
      "Your password has been reset %bWe will return you %sautomatically%e to the login page",
    "PasswordRecoveryPage.success": "Success!",
    "PasswordRecoveryPage.missCode":
      "Please re-enter this page from the link that was sent to your email",
    "Database.settings.setup": "Set up your tables",
    "Database.settings.description":
      "These are your table settings. Change the following information so is suits your preferences.",
    "Database.settings.columnsPerTable": "Columns per table",
    "Database.settings.columnsPerTable.description":
      "Fleks supports 10 columns per table, where 'Name' and 'Status' are non-editable. This means that you can customise the remaining 8 columns.",
    "Database.settings.columnsPerTable.whatTableEdit":
      "What table do you want to edit?",
    "Database.settings.rowsPerTable": "Adjust rows per table",
    "Database.settings.rowsPerTable.description":
      "You can adjust the number of rows shown in each table in this section.",
    "Database.settings.rowsPerTable.numberOfRows": "Number of rows",
    "Database.settings.rowsPerTable.numberOfRows.maxError":
      "You can only select up to 100 rows",
    "Database.settings.rowsPerTable.adjustRows": "Adjust the rows",
    "Database.settings.title": "Table preferences",
    "Database.settings.saveTableViews": "Save table views",
    "Database.settings.saveTableViews.description": "Do you want to set your current table views as default? This will save your current tab and sorting method for clients, projects, shifts, and hour registration.",
    "Database.settings.saveTableViews.saveDefault": "Save as default",
    "Database.customization.clients": "Clients table",
    "Database.customization.projects": "Project table",
    "Database.customization.jobs": "Job table",
    "Database.customization.jobRequests": "Job requests table",
    "Database.customization.newemployers": "New employers table",
    "Database.customization.employers": "Employers table",
    "Database.customization.employees": "Employees table",
    "Database.customization.incomingEmployees": "Incoming employees table",
    "Database.customization.shifts": "Shifts table",
    "Database.customization.applicants": "Applicants table",
    "Database.customization.newFreelancers": "New freelancers table",
    "Database.customization.freelancers": "Freelancers table",
    "Database.customization.singles": "Hours - Singles table",
    "Database.customization.singlesInternal":
      "Hours - Internal per shift table",
    "Database.customization.totals": "Hours - Totals table",
    "Database.customization.totalsInternal":
      "Hours - Internal per employee table",
    "Database.customization.externals": "Hours - Externals table",
    "Database.customization.allHours": "Hours - All hours table",
    "Database.customization.invoices": "Hours - Invoices table",
    "Database.customization.description":
      "Change the order or replace the columns by dragging the boxes.",
    "Database.customization.saveChanges": "Use these settings",
    "Database.tables.haveSelected":
      "You have selected all the elements from the page.",
    "Database.tables.AllListHaveBeenSelected":
      "All the {total} elements have been selected",
    "Database.tables.selectAllList":
      "Select all the {total} elements from the '{tab}' tab",
    "Clients.timesheets": "Timesheets",
    "Clients.timesheets.management": "Timesheets management",
    "Clients.timesheets.management.description":
      "This page will allow you to find information regarding the employees that are able to create jobs associated with the projects of this client from the mobile application. You can also allow new employees to do this and modify the information of the ones that are already able to create the jobs.",
    "Clients.timesheets.addEmployee": "Add employees",
    "Clients.timesheets.addProjects": "Add projects",
    "Clients.timesheets.editProjects": "Edit projects",
    "Clients.timesheets.linkProjects": "Link projects",
    "Clients.archived": "{items} clients archived",
    "Clients.name": "Name",
    "Clients.id": "ID",
    "Clients.city": "City",
    "Clients.projects": "Projects",
    "Clients.date": "Date",
    "Clients.clients": "Clients",
    "Clients.newClient": "Add client or department",
    "Clients.all": "All",
    "Clients.filter": "Filter",
    "Clients.create-client": "Create client",
    "Clients.create-department": "Create department",
    "Clients.upload": "Upload",
    "Clients.street-name": "Street name",
    "Clients.zip-code": "Zipcode",
    "Clients.house-number": "House number",
    "Clients.house-number-ext": "House number ext",
    "Clients.require-text": "This field is required",
    "Clients.fill-in": "Fill in",
    "Clients.general": "General",
    "Clients.logo": "Logo",
    "Clients.contact": "Contact person",
    "Clients.Single.totalProjects": "Total projects",
    "Clients.Single.ongoingProjects": "Ongoing projects",
    "Clients.Single.finishedProjects": "Finished projects",
    "Clients.Single.project": "Project",
    "Clients.Details.general": "General",
    "Clients.Details.clientId": "Client ID",
    "Clients.Details.id": "ID",
    "Clients.Details.name": "Name",
    "Clients.Details.streetName": "Street name",
    "Clients.Details.number": "Number",
    "Clients.Details.ext": "Ext",
    "Clients.Details.stateRegion": "City",
    "Clients.Details.zipCode": "Zip code",
    "Clients.Details.documents": "Documents",
    "Clients.Details.documents.add": "Add a document",
    "Clients.Details.documents.upload": "Upload the document",
    "Clients.Details.documents.dragText": "Drag the file here to upload it.",
    "Clients.Details.documents.edit": "Edit document",
    "Clients.Details.documents.delete": "Delete document",
    "Clients.Details.documents.delete.confirmation":
      "You are about to delete this document, are you sure about executing this action?",
    "Clients.Details.documents.deleteFile.confirmation":
      "You are about to delete this file, are you sure about executing this action?",
    "Clients.Details.Parent": "Parent client", 
    "Clients.Details.description.placeholder":
      "Add a description about the function the employee will be doing for this client, this will be visible for the employee in their job description.",
    "Clients.Details.description": "Description (optional)",
    "Clients.title": "Clients",
    "Clients.parent": "Parent client",
    "Clients.contactPerson": "Client's Contact persons", 
    "Clients.addDepartment": "Add department", 
    "Clients.type": "Type", 
    "Clients.type.checkbox":
      "This is a department of sub-brand part of a client", 
    "Clients.create": "Create", 
    "Clients.Details.templates": "Standard project",
    "Clients.templates.no-data":
      "Add a template project to the client, when you create a project for this client you can select this template project.",
    "Clients.emptyState.subTable": "No departments added",
    "Clients.emptyState.title": "No clients have been added yet",
    "Clients.emptyState.description":
      "Click on the purple button in the top right corner to add a client or a location.",
    "Clients.noClients": "No clients to show",
    "Clients.active": "Active",
    "Clients.inactive": "Inactive",
    "Clients.numberOfProjects": "Number of projects",
    "Clients.customNumber": "Client ID",
    "Clients.dateCreated": "Date created",
    "Clients.standardProjects.new": "Add a standard project",
    "Clients.archive.confirmation": `You are about to {archive, select,
      true {unarchive}
      false {archive}
    } {items, plural,
      one {# client}
      other {# clients}    
    }, are you sure about executing this action?`,
    "Clients.chamberOfCommerce": "KvK Number",
    "Clients.adjustGeneralInfo": "Adjust the general information",
    "Clients.adjustContactPersonInfo": "Adjust the contact person information",
    "Clients.saveGeneralInfo": "Save the information",
    "Templates.errors.delete": "We had problems deleting template",
    "Templates.errors.edit": "We could not get any template project to edit",
    "Templates.success.delete": "Template project was deleted successfully",
    "Templates.success.create": "Template project was created successfully",
    "Templates.success.edit": "Template project was edited successfully",
    "Templates.info.template": "[text]",
    "Templates.info.loading": "Loading data to create template projects",
    "Templates.edit": "Edit template project",
    "Templates.delete": "Delete template project",
    "Templates.create": "Create template project",
    "Templates.export.project": "Projects",
    "Templates.export.job": "Jobs",
    "Templates.export.employee": "Employees",
    "Templates.export.declaration_internal": "Internal per shift",
    "Templates.export.declaration_total": "External per shift",
    "Templates.export.shift_internal": "Shifts - Internals",
    "Templates.export.shift_all": "Shifts - Internals & Externals",
    "Templates.export.billable_hours": "External per shift",
    "Templates.export.internal_per_employee": "Internal per employee",
    "Templates.exportCreation": "New export template",
    "Templates.exportEdit": "Edit export template",
    "Templates.exportAvailableFor": "Export available for",
    "Templates.roles": "Roles",
    "Templates.adminOnly":
      "This report template will only be available for admin users",
    "Templates.userTypes.app_brand_user_group": "Manager",
    "Templates.userTypes.dashboard_ftp_user_group": "Admin",
    "Templates.userTypes.planner_user_group": "Planner",
    "Templates.shiftCard.internal": "Internals",
    "Templates.shiftCard.internalExternal": "Internals & Externals",
    "Export.templates.delete": "Template was deleted successfully",
    "Export.templates.create": "Template was created successfully",
    "Export.templates.edit": "Template was edited successfully",
    "Templates.model.project_title": "Project title",
    "Templates.model.project_custom_number": "Custom number",
    "Templates.model.project_manager": "Manager",
    "Templates.model.project_full_address": "Full address",
    "Templates.model.project_start_date": "Start date",
    "Templates.model.project_end_date": "End date",
    "Templates.model.project_number_of_employees": "Number of employees",
    "Templates.model.project_accepted_users": "Accepted users",
    "Templates.model.project_category_names": "Category names",
    "Templates.model.project_total_jobs": "Total jobs",
    "Templates.model.project_pending_jobs": "Pending jobs",
    "Templates.model.project_brand_names": "Brand names",
    "Templates.model.employee_custom_number": "Employee custom number",
    "Templates.model.employee_full_name": "Full name",
    "Templates.model.job_project_number": "Project number",
    "Templates.model.job_category_name": "Category name",
    "Templates.model.job_full_address": "Full address",
    "Templates.model.job_tenant_name": "Tenant",
    "Templates.model.job_category_sales_price": "Function Sales price",
    "Templates.model.sign_off_time": "Digital signature from the manager app",
    "Templates.model.employee_sign_off_time": "Digital signature from employee app",
    "Templates.model.employee_current_tenant_name": "Current tenant",
    "Templates.model.jap_start_date": "Start date and time",
    "Templates.model.jap_end_date": "End date and time",
    "Templates.model.jap_custom_number": "Job application custom number",
    "Templates.model.billable_billed_hours": "Billed hours",
    "Templates.model.billable_travel_time": "Travel time",
    "Templates.model.billable_travel_distance": "Travel distance",
    "Templates.model.billable_transportation_cost": "Transportation cost",
    "Templates.model.billable_other_cost": "Other cost",
    "Templates.model.employee_first_name": "First name",
    "Templates.model.employee_initials": "Initials",
    "Templates.model.employee_last_name": "Last name",
    "Templates.model.employee_surname_prefix": "Prefix",
    "Templates.model.employee_address": "Address",
    "Templates.model.employee_bank_acc_number": "Bank Number",
    "Templates.model.employee_social_number": "Social Number",
    "Templates.model.employee_city": "City",
    "Templates.model.employee_house_number": "House number",
    "Templates.model.employee_house_number_ext": "House number extension",
    "Templates.model.employee_post_code": "Zipcode",
    "Templates.model.employee_gender": "Gender",
    "Templates.model.employee_archived_at": "Archived at",
    "Templates.model.employee_wage_tax_rebate_status": "Wage tax rebate status",
    "Templates.model.employee_wage_tax_rebate_date": "Wage taxe rebate date",
    "Templates.model.employee_phone_number": "Telephone number",
    "Templates.model.employee_birth_date": "Birth date",
    "Templates.model.employee_full_address": "Employee full address",
    "Templates.model.employee_email": "Email",
    "Templates.model.job_start_date": "Job start date",
    "Templates.model.jap_invalid_state": "Invalid state",
    "Templates.model.contract_name": "Contract name",
    "Templates.model.comment_texts": "Comments",
    "Templates.model.job_created_by": "Created by",
    "Templates.model.job_date_created": "Created at",
    "Templates.model.jap_created_by": "Created by",
    "Templates.model.jap_date_created": "Created at",
    "Templates.model.employee_created_by": "Created by",
    "Templates.model.project_created_by": "Created by",
    "Templates.model.project_date_created": "Created at",
    "Templates.model.employee_qualifications": "Employee qualifications",
    "Templates.model.job_end_date": "Job end date",
    "Templates.model.declaration_user_hours_worked": "Worked hours",
    "Templates.model.declaration_compensation_hours": "Compensation hours",
    "Templates.model.declaration_break_compensation": "Break compoensation",
    "Templates.model.declaration_applicable_travel_time":
      "Applicable travel time",
    "Templates.model.jap_check_in_qr": "Check-in time QR code",
    "Templates.model.jap_check_out_qr": "Check-out time QR code",
    "Templates.model.declaration_travel_time_rate": "Travel time rate",
    "Templates.model.declaration_travel_time_fee": "Travel time fee",
    "Templates.model.declaration_travel_distance_applicable":
      "Applicable travel distance",
    "Templates.model.declaration_km_driving_cost": "Km driving cost",
    "Templates.model.declaration_transportation_cost": "Transportation cost",
    "Templates.model.declaration_other_cost": "Other cost",
    "Templates.model.declaration_gross_salary": "Gross salary",
    "Templates.model.declaration_check_in": "Check in time",
    "Templates.model.declaration_check_in_on_location":
      "Check in location time",
    "Templates.model.declaration_check_out": "Check out time",
    "Templates.model.declaration_approved_at": "Approved at",
    "Templates.model.declaration_approved_by_email": "Approved by",
    "Templates.model.declaration_exported_at": "Exported at",
    "Templates.model.declaration_exported_by_email": "Exported by",
    "Templates.model.jap_active_hourly_rate": "Hourly rate",
    "Templates.model.declaration_active_fixed_rate": "Fixed rate",
    "Templates.model.declaration_active_payment_type": "Payment type",
    "Templates.model.initial": "Initial",
    "Templates.model.employee_document_number": "Document number",
    "Templates.model.employee_wage_tax_rebate": "Wage tax rebate",
    "Templates.model.employee_date_created": "Creation date",
    "Templates.model.employee_last_invitation_at": "Last invited at",
    "Templates.model.employee_basic_reg_completed_at":
      "Basic registration completed at",
    "Templates.model.employee_last_login_at": "Last login at",
    "Templates.model.employee_last_contacted_at": "Las contacted at",
    "Templates.model.employee_last_jap_date": "Last job application date",
    "Templates.model.contract_start_date": "Contract start date",
    "Templates.model.contract_end_date": "Contract end date",
    "Templates.model.job_custom_number": "Custom number",
    "Templates.model.job_start_time": "Start time",
    "Templates.model.job_end_time": "End time",
    "Templates.model.job_worked_hours": "Worked hours",
    "Templates.model.job_number_of_employees": "Number of employees",
    "Templates.model.job_accepted_employees": "Accepted employees",
    "Templates.model.job_is_full": "Is full",
    "Templates.model.job_pending_employees": "Pending employees",
    "Templates.model.job_invited_users": "Invited users",
    "Templates.model.job_city": "City",
    "Templates.model.job_address_description": "Address description",
    "Templates.model.job_zip_code": "Zipcode",
    "Templates.model.job_extra_note": "Internal note",
    "Templates.model.ratecard_date": "Ratecard date",
    "Templates.model.ratecard_month": "Ratecard month",
    "Templates.model.project_brand_custom_number": "Brand custom number",
    "Templates.model.ratecard_type": "Ratecard type",
    "Templates.model.job_category_custom_number": "Category custom number",
    "Templates.model.ratecard_start_time": "Ratecard start time",
    "Templates.model.ratecard_end_time": "Ratecard end time",
    "Templates.model.ratecard_surcharge": "Surcharge",
    "Templates.model.ratecard_surcharge_percentage": "Surcharge percentage",
    "Templates.model.ratecard_number_of_units": "Ratecard number of units",
    "Templates.model.employee_shirt_size": "Shirt size",
    "Templates.model.employee_shoe_size": "Shoe size",
    "Templates.model.employee_license": "License",
    "Templates.model.employee_car": "Car",
    "Templates.model.employee_education": "Education",
    "Templates.model.jap_exception_state_name": "Exception state",
    "Templates.model.jap_exception_state_code": "Exception state ID",
    "Templates.model.jap_start_time": "Start time",
    "Templates.model.jap_end_time": "End time",
    "Templates.model.jap_total_hours": "Amount of hours",
    "Templates.model.jap_start_date_short": "Start date",
    "Templates.model.jap_end_date_short": "End date",
    "Templates.model.free_fields": "Free fields",
    "Templates.model.employee_shifts_total_km_driving_cost": "Travel Costs",
    "Templates.model.employee_shifts_total_net_costs": "Total Cost",
    "Templates.model.employee_shifts_total_gross_salary":
      "Gross salary (excl. travel time fee)",
    "Templates.model.employee_shifts_number_job_applications":
      "Number of time worked",
    "Templates.model.employee_shifts_total_users_hours_worked": "Worked hours",
    "Templates.model.employee_shifts_total_compensation_hours":
      "Additional hours",
    "Templates.model.employee_shifts_total_travel_time_fee": "Travel time fee",
    "Templates.model.employee_shifts_total_applicable_travel_time":
      "Travel time",
    "Templates.model.employee_shifts_total_travel_distance":
      "Number of driven km",
    "Templates.model.employee_shifts_total_transportation_cost":
      "Public transport fee",
    "Templates.model.employee_shifts_total_other_cost": "Other cost pictures",
    "Templates.model.employee_shifts_highest_hourly_rate":
      "Highest hourly rate",
    "Templates.model.employee_shifts_total_hourly_rate_hours_worked":
      "Hours with hourly rate",
    "Templates.model.employee_shifts_total_reserve_rate_hours_worked":
      "Hours with a fixed reserve rate",
    "Templates.model.employee_shifts_total_fixed_rate_hours_worked":
      "Hours with a fixed rate",
    "Templates.model.employee_shifts_total_hourly_gross_salary":
      "Gross salary with hourly rates",
    "Templates.model.employee_shifts_total_reserve_gross_salary":
      "Total reserve salary",
    "Templates.model.employee_shifts_total_fixed_gross_salary":
      "Total fixed salary",
    "Templates.model.function_description": "Function description",
    "ContactPerson.NewContactPerson": "Create new contact person", 
    "ContactPerson.notCreated": "Could no create the new contact person", 
    "ContactPerson.created": "The contact person was created successfuly", 
    "ContactPerson.create": "Create a new contact person", 
    "ContactPerson.userExist": "An user already exist with the same email", 
    "ContactPerson.no-data": "Not any contact persons to show", 
    "Projects.title": "Projects",
    "Projects.newProject": "New project",
    "Projects.all": "All",
    "Projects.running": "On-going",
    "Projects.upcoming": "Upcoming",
    "Projects.past": "Past",
    "Projects.name": "Name",
    "Projects.clients": "Client(s)",
    "Projects.typeOfWork": "Function",
    "Projects.jobs": "Jobs",
    "Projects.pending": "Pending",
    "Projects.projectManager": "Project manager",
    "Projects.startDate": "Start date",
    "Projects.endDate": "End date",
    "Projects.addEndDate": "Add end date",
    "Projects.id": "ID",
    "Projects.missedFunctionDescription":
      "The description of the project cannot be empty",
    "Projects.fixedRate": "Fixed rate",
    "Projects.hourlyRate": "Fixed rate per hour",
    "Projects.ageHourlyRate": "Paid by age",
    "Projects.customAgeRelatedRate": "Hourly rates of contract",
    "Projects.customNumber": "Project number",
    "Projects.contractBased": "Contract based",
    "Projects.breakApplicable": "Break applicable",
    "Projects.travelCost":
      "Select whether travel expenses should be calculated based on the employees home and job location",
    "Projects.publicTransport":
      "Select whether the employee can submit costs made for public transport",
    "Projects.publicTransport.true":
      "Costs made with public transport may be claimed",
    "Projects.publicTransport.false":
      "Public transport cost are not declareable",
    "Projects.hourCorrection": "Hour correction",
    "Projects.workingHoursUpdateable":
      "Select whether an hour correction can be requested by the employee",
    "Projects.workingHoursUpdateable.true":
      "The employee can request an hour correctien including a comment",
    "Projects.workingHoursUpdateable.false":
      "The employee can not request an hour correction",
    "Projects.travelExpenses": "Travel expenses",
    "Projects.otherCosts": "Other cost",
    "Projects.otherCostsApplicable":
      "Select whether other cost are declareable",
    "Projects.otherCostsApplicable.true": "Other costs are declareable",
    "Projects.otherCostsApplicable.false": "Other costs are not declareable",
    "Projects.breakAdjustable": "Break is adjustable by employee",
    "Projects.travelTimeApplicable":
      "Select whether the travel time of the employee is paid out",
    "Projects.kmDrivenApplicable":
      "Select whether the kilometers driven are paid out",
    "Projects.preCalculatedAndTravelTime.FalseFalse":
      "The travel time is not paid out",
    "Projects.preCalculatedAndTravelTime.FalseTrue":
      "The employee can fill in the travel time and it not pre-calculated",
    "Projects.preCalculatedAndTravelTime.TrueFalse":
      "The travel time is calculated in advance, based on the location of the job and the home location of the employee by google maps. The employee may not adjust this himself",
    "Projects.preCalculatedAndTravelTime.TrueTrue":
      "The travel time is calculated in advance, based on the location of the job and the home location of the employee by google maps. The employee may still adjust this on the declatation form.",
    "Projects.preCalculatedAndKmDriven.FalseFalse":
      "The Kilometers driven are not paid out",
    "Projects.preCalculatedAndKmDriven.FalseTrue":
      "The employee can fill in the Kilometers driven and it not pre-calculated",
    "Projects.preCalculatedAndKmDriven.TrueFalse":
      "The Kilometers driven is calculated in advance, based on the location of the job and the home location of the employee by google maps. The employee may not adjust this himself",
    "Projects.preCalculatedAndKmDriven.TrueTrue":
      "The kilometers driveen are calculated in advance, based on the location of the job and the home location of the employee by google maps. The employee may still adjust this on the declatation form.",
    "Projects.questions": "Questions", // *****
    "Projects.question": "Question", // *****
    "Projects.newQuestion": "New question",
    "Projects.addQuestion": "Add question",
    "Projects.question.press-enter": "Press enter to save", // *****
    "Projects.answer": "Answer {number}", // *****
    "Projects.newAnswer": "New answer",
    "Projects.cancel": "Cancel", // *****
    "Projects.add-quiz": "Add quiz", // *****
    "Projects.open": "Open",
    "Projects.scheduled": "Scheduled",
    "Projects.payment-method": "Payment method",
    "Projects.payment-information": "Payment information",
    "Projects.salesPriceInfoTitle": "Set sales rates",
    "Projects.salesPriceInfoDescription": "Determine your sales rates per selected functions for this project.",
    "Projects.salesPriceInputPlaceholder":"Sales price per hour",
    "Projects.salesPriceInfo.Submit": "Add rate",
    "Projects.salesPriceTitle": "Sales Rate",
    "Projects.declaration-form": "The declaration form",
    "Projects.declaration-form-sub":
      "Select the costs that may be declared and whether an hour correction can be requested",
    "Projects.hourly-rate": "Hourly rate",
    "Projects.reserve-rate": "Reserve rate",
    "Projects.briefing-pdf": "briefing_for_project.pdf",
    "Projects.Single.totalJobs": "Total jobs",
    "Projects.Single.openJobs": "Open jobs",
    "Projects.Single.filledJobs": "Filled jobs",
    "Projects.Single.newJob": "New job",
    "Projects.Single.published": "Published", 
    "Projects.Single.unpublished": "Unpublished", 
    "Projects.Single.no-published": "It was not posible publish the project", // *****
    "Projects.add-briefing": "Add briefing",
    "Projects.edit-quiz": "Edit quiz",
    "Projects.images": "Images",
    "Projects.projectSales": "Project sales",
    "Projects.projectCoordinator": "Project coordinator",
    "Projects.create": "Create Project",
    "Projects.createAndPublish": "Create and publish",
    "Projects.category": "Function",
    "Projects.created-quiz": "New quiz was created",
    "Projects.Details.pool": "Pools",
    "Projects.Details.poolDescription":
      "The jobs added to this project will be visible to the employees who meet the criteria below. Do you want to make the jobs visible to all the employees? Then do not add any pool. <br />The jobs are now visible to %fu employees. Do you want to make an exception for a job? Add a pool add the job!.",
    "Projects.Details.enterBranch": "Enter function",
    "Projects.pool.predefined": "Predefined pools",
    "Projects.pool": "Project pool",
    "Projects.pool.predefined.switch": "Create a project pool",
    "Projects.predefinedPools":
      "Select one or multiple predefined pools for this project",
    "Projects.Details.enterTraining": "Enter training",
    "Projects.Details.enterQualification": "Enter qualification",
    "Projects.createProject": "Create project",
    "Projects.fillIn": "Fill in",
    "Projects.general": "General",
    "Projects.projectName": "Project name",
    "Projects.jobCategory": "Job function",
    "Projects.fieldRequired": "This field is required",
    "Projects.select": "Select",
    "Projects.client": "Client",
    "Projects.department.2": "Department",
    "Projects.startEndDate": "Start date - End date",
    "Projects.responsible": "Responsible",
    "Projects.description": "Description",
    "Projects.characters": "of 1000 characters",
    "Projects.payment": "Payment",
    "Projects.projectSettings": "Project settings",
    "Projects.projectSettings.cardAutoScheduled.title": "Auto scheduled",
    "Projects.projectSettings.cardAutoScheduled.subTitle":
      "Select the types of auto-scheduling that will be available for both internal employees that apply or external employees that are proposed.",
    "Projects.projectSettings.cardAutoScheduled.InternalEmployees":
      "Internal Employees",
    "Projects.projectSettings.cardAutoScheduled.ExternalEmployees":
      "External Employees",
    "Projects.projectSettings.cardAutoScheduled.descriptonSwicthInternal":
      "Select whether an applicable employee is scheduled immediately",
    "Projects.projectSettings.cardAutoScheduled.descriptonSwicthExternal":
      "Select whether to schedule an employee who has been proposed immediately",
    "Projects.paymentMethod": "Payment method",
    "Projects.chooseMethod": "Choose method",
    "Projects.amount": "Amount",
    "Projects.errorDescription":
      "The maximum number of characters was exceeded",
    "Projects.reserveRate": "Reserve rate",
    "Projects.reserveRateOptional": "Reserve rate (Optional)",
    "Projects.new.details":
      "This project will be available to employees who meet the criteria below. To make the project visible to everyone, select neither any predefined pool nor any of the tags shown for the project personal pool.",
    "Projects.addPool": "Add pool",
    "Projects.Details.location": "Location (Optional)",
    "Projects.Details.location.2": "Location",
    "Projects.Details.date": "Date",
    "Projects.location.address": "Street",
    "Projects.location.city": "City",
    "Projects.location.house_number": "Number",
    "Projects.location.house_number_ext": "Extension",
    "Projects.location.zip_code": "Postcode",
    "Projects.projectCreated": "Project created successfully",
    "Projects.newProjectCreated": "New project created",
    "Projects.somethingWrong": "Something went wrong",
    "Projects.fieldsRequired":
      "Project name, job function, client, start date -end date, payment method, amount, and reserve rate are required",
    "Projects.no-clone": "It was not possible to clone the project", // *****
    "Projects.cloning": "Cloning the project", // *****
    "Projects.Pools.edit": "Edit pools", // *****
    "Projects.Pools.error-load":
      "It happened an error while trying to get the employees", // *****
    "Projects.Pools.save-edit": "The pools were saving successfully", // *****
    "Projects.Pools.error-edit": "The pools don't were saving", // *****
    "Projects.projectNumber": "Project number",
    "Projects.projectNamePlaceholder": "Custom project number (optional)",
    "Projects.published": "Published",
    "Projects.unpublished": "Unpublished",
    "Projects.filledJobs": "Filled jobs",
    "Projects.pendingJobs": "Pending jobs",
    "Projects.filledPositions": "Filled positions",
    "Projects.nameTitle": "Title",

    "Projects.visible": "Visible",
    "Projects.department": "Add department (optional)",
    "Projects.load-error": "Error when we try to load projects",
    "Projects.caduced":
      "The end date of the project has expired it is not visible in the app",
    "Projects.publishedNoPending":
      "This project is published, but there are no open jobs. The project is not visible for the employees.",
    "Projects.publishedWithPending":
      "The project is published and there are %sjp open jobs for the employees.",
    "Projects.noPublishedNoPending":
      "This project is not published and there are no open jobs. The project is not visible for the employees.",
    "Projects.noPublishedWithPending":
      "There are %sjp open jobs, publish the project to make them visible for the employees.",
    "Projects.type": "Type",
    "Projects.vacancy": "This is a vacancy",
    "Projects.notVacancy": "This is a project",
    "Projects.vacancy.subtext.true":
      "This request is a vacancy, give an indication of how the amount of hours per week. The employee will apply subscribe for all hours.",
    "Projects.vacancy.subtext.false":
      "This request is NOT a vacancy, the employee will apply on the jobs individually",
    "Projects.vacancy.hoursPerWeek": "Hours per week",
    "Projects.vacancy.numberOfEmployees": "Amount of needed employees",
    "Projects.fixed-rate": "Fixed rate",
    "Projects.appliedPools": "Applied pools",
    "Projects.notAppliedPools": "There aren't applied pools",
    "Projects.new.selectClient": "1. Select a client/department",
    "Projects.new.fillData": "2. Information",
    "Projects.template": "Template",
    "Projects.clientTemplate": "Client template",
    "Projects.departmentTemplate": "Department template",
    "Projects.createTemplate": "Create project template for: [client_name]",
    "Projects.editTemplate": "Edit project template for: [client_name]",
    "Projects.teamInstructionsPdf": "Team instructions",
    "Projects.addTeamInstructions": "Add team instructions",
    "Projects.breakRules": "Break rules",
    "Projects.breakApplicable.title":
      "Select whether a break should be deducted from the working hours",
    "Projects.breakApplicable.subtitle":
      "Select whether break rules are declareable",
    "Projects.breakApplicable.true":
      "The break will be deducted from the working hours",
    "Projects.breakApplicable.false":
      "There will be no break deducted from the working hours",
    "Projects.emptyState.title": "No projects have been created yet",
    "Projects.emptyState.description":
      "Click on the purple button in the top right corner to add a project",
    "Projects.noProjects": "No projects to show",
    "Projects.internalNote": "Internal note",
    "Projects.goToTablePreferences": "Go to table preferences",
    "Projects.clientName": "Client name",
    "Projects.city": "City",
    "Projects.toOldDesign": "Do you still need the old tables?",
    "Projects.archived": "{items} projects archived",
    "Projects.advancedFilters.close": "Close sidebar",
    "Projects.advancedFilters.title": "All filters",
    "Projects.archive.confirmation": `You are about to {archive, select,
      true {unarchive}
      false {archive}
    } {items, plural,
      one {# project}
      other {# projects}    
    }, are you sure about executing this action?`,
    "Projects.adjustLocationInfo": "Adjust the location information",
    "Projects.adjustPaymentInfo": "Adjust the payment information",
    "Projects.adjustQuiz": "Adjust the quiz",
    "Projects.adjustEvaluation": "Adjust the evaluation",
    "Projects.evaluationGuide.description":
      "The following fields can be replaced in the evaluation link so it can be automatically pre-filled by the system when the employees open the evaluation from their mobile devices:",
    "Projects.evaluationGuide.fields": `
      - Employee's full name = [employee_full_name]<br/>
      - Employee's custom number = [employee_custom_number]<br/>
      - Start date of the job = [job_start_date]<br/>
      - End date of the job = [job_end_date]<br/>
      - Start time of the job = [job_start_time]<br/>
      - End time of the job = [job_end_time]<br/>
      - Job's full address = [job_full_address]<br/>
      - Job's zipcode = [job_zipcode]<br/>
      - Internal note = [job_extra_note]<br/>
      - Custom number = [custom_number]<br/>
      - Project name = [project_name]<br/>
      - Job function of the job = [job_category_name]`,
    "Projects.evaluationGuide.footer": `
      To add this to the URL, the text on the right must be placed in the entry of the link that corresponds to that information<br/><br/>
      <b>Example</b>: <em>https://docs.google.com/forms/d/e/1FAIpQLSewnfFlo4fYjZZIH0ZE92v6JYFfEjWf_ciIZrSgSPV_olIVQg/viewform?entry.1147536406=[employee_full_name]</em>`,
    "Projects.isThisArunningProject": "Is this a running project?",
    "Projects.runningProjectDescription": `With a project you can choose a start and end date to indicate the period in which the project takes place. It is also possible to interpret “projects” as “department” or “cost center”. If this is indeed the case, you can leave the end date empty. In this case Fleks will always consider the project as "Ongoing".`,
    "Projects.creation": "Project creation",
    "Projects.publishProjectCreation": "Publish open jobs", 
    "Projects.publishCreation": "Conceptplanning", 
    "Projects.publishProjectDetails": "Open jobs published",
    "Projects.publishDetails": "Conceptplanning",
    "Projects.conceptPlanning" : "Conceptplanning",
    "Projects.conceptPlanningTrue": "Activated",
    "Projects.conceptPlanningFalse": "Not active",
    "Projects.notificationTitle": "Send message to upcoming scheduled employees",
    "Projects.hoverpublishProjectCreation": "If you add open jobs to the project, they will appear on the 'Open jobs' page in the app so that suitable employees can sign up. You can always publish the open jobs later from the project details page.",
    "Projects.hoverpublishProjectDetails": "Publish the project so that the open jobs will appear on the 'Open jobs' page and suitable employees can sign up.",
    "Projects.hoverPublishCreation": "Activate the concept planning to ensure that no automatic messages are sent to employees. As long as the concept planning is active, employees will only see the project and the date of the shift in the app. Note: Don’t forget to turn off the concept planning, or the employee might miss their shift.",
    "Projects.hoverPublishDetails": "Activate the concept schedule to prevent automatic notifications from being sent to employees. As long as the concept or draft schedule is active, employees will only see the project and the date of the shift in the app. Note: Don't forget to turn off the concept schedule, or the employee might miss their shift.",
    "Projects.notificationMessageConceptPlanningTrue": "Concept planning is activated, no automatic messages are sent when scheduling employees",
    "Projects.notificationMessageConceptPlanningFalse": "Concept planning is deactivated, the job details are visible to employees in the app under 'my jobs'",
    "Projects.notificationMessagepublishProjectTrue": "The open jobs have been published. Employees can sign up through the app. Send a notification to the appropriate employees to receive sign-ups as soon as possible.",
    "Projects.notificationMessagepublishProjectFalse": "The project has not been published; employees can no longer sign up for available jobs.",
    "Projects.detailsModal.sidebar.title": "Project Overview",
    "Projects.detailsModal.sidebar.fromStartDateToEndDate":
      "From {start_date} to {end_date}",
    "Projects.detailsModal.sidebar.selectDate": "Select a date:",
    "Projects.detailsModal.content.subtitleOcupation": "Planning",
    "Projects.detailsModal.content.tableColumn.NoName":
      "<span class='avenir-next-italic'>Not planned</span>",
    "SelectClientList.title": "Search a client",
    "SelectClientList.select": "Select this client",
    "SelectClientList.selectDepartment": "Select this department",
    "Sidebar.dashboard": "Dashboard",
    "Sidebar.planner": "Planner",
    "Sidebar.oldPlanner": "Old Planner",
    "Sidebar.betaPlanner": "Beta Planner",
    "Sidebar.scheduler": "Schedule board",
    "Sidebar.database": "Database",
    "Sidebar.oldDatabase": "Old Database",
    "Sidebar.oldHours": "Old hours",
    "Sidebar.newFreelancers": "New freelancers",
    "Sidebar.freelancers": "Freelancers",
    "Sidebar.clients": "Clients",
    "Sidebar.projects": "Projects",
    "Sidebar.jobs": "Jobs",
    "Sidebar.jobRequests": "Job Requests",
    "Sidebar.newEmployers": "New employers",
    "Sidebar.employers": "Employers",
    "Sidebar.employees": "Employees",
    "Sidebar.applicants": "Applicants",
    "Sidebar.hours": "Hours",
    "Sidebar.archive": "Archive",
    "Sidebar.create-project": "New project",
    "Sidebar.create-client": "New client",
    "Sidebar.create-employee": "New employee",
    "Sidebar.create-quiz": "Create quiz",
    "Sidebar.shifts": "Shifts",
    "Sidebar.singles": "Singles",
    "Sidebar.singlesInternal": "Internal per shift",
    "Sidebar.internals": "Internal",
    "Sidebar.totals": "Totals",
    "Sidebar.totalsInternal": "Internal per employee",
    "Sidebar.allHours": "All hours",
    "Sidebar.invoices": "Invoices",
    "Sidebar.company": "Company info for the app",
    "Sidebar.hr": "HR & Finance",
    "Sidebar.admin": "Admin",
    "Sidebar.templates": "Templates",
    "Sidebar.template-project": "Standard project",
    "Sidebar.make-salary-run": "Make salary run",
    "Sidebar.days": "days",
    "Sidebar.externalConnections": "External connections",
    "Sidebar.directPay": "Direct pay",
    "Sidebar.directPayDescription": "Direct pay chosen by employee",
    "JobRequests.title": "Job requests",
    "Jobs.expandTableItems": "Expand the selected items",
    "Jobs.collapseTableItems": "Collapse the selected items",
    "Jobs.updateJobsSpecifications": "Edit information",
    "Jobs.updateJobsSpecifications.allUpdated":
      "All the job details were updated",
    "Jobs.updateJobsSpecifications.allNotUpdated":
      "It was not possible to update the jobs",
    "Jobs.updateJobsSpecifications.oneNotUpdated":
      "One or more items were not updated, please check the jobs",
    "Jobs.updateJobsSpecifications.onePartiallyUpdated":
      "One or more items were partially updated, please check the jobs",
    "Jobs.title": "Jobs",
    "Jobs.newJob": "New job",
    "Jobs.all": "All upcoming",
    "Jobs.allJobs": "All",
    "Jobs.filled": "Filled",
    "Jobs.pending": "Pending",
    "Jobs.past": "Past",
    "Jobs.open": "Open",
    "Jobs.project": "Project",
    "Jobs.projectManager": "Project manager",
    "Jobs.sales": "Sales",
    "Jobs.requests": "Requests",
    "Jobs.request.accepted": "The request has been accepted", 
    "Jobs.request.rejected": "The request has been rejected", 
    "Jobs.request.error": "Error when processing your selection", 
    "EmployeeList.unSetException": "Can not remove exception",
    "WorkFlow.unarchive": "Can not un-archive",
    "EmployeeList.unCancel": "Un-cancel",
    "EmployeeList.notExpired": "Re-accept",
    "Employees.creation": "Employee creation",
    "Employees.creation.basicDetails": "Basic details",
    "Employees.creation.addressDetails": "Address details",
    "Employees.availability.dateSelector": "Select a date or range",
    "Employees.availability.weekSelector":
      "Specify the day of the week (optional)",
    "Employees.availability.availabilitySelector":
      "Select the new availability",
    "Employees.intake": "Contact information",
    "Employees.dateCreatedMin": "Date created (min)",
    "Employees.dateCreatedMax": "Date created (max)",
    "Employees.archived": "{items} employees archived",
    "Employees.archivedinfo":
      "Archived at: - {archivedAt}<br/> Archived by: - {archivedBy}",
    "Employees.export": "Export",
    "Employees.sendOnboarding": "Send the onboarding email",
    "Employees.sendMessage": "Send a message",
    "Employees.additionalFields": "Additional fields",
    "Employees.nameOfTheField": "Name of the field",
    "Employees.contentOfTheField": "Content of the field",
    "Employees.single.setCards": "Setcards",
    "Employees.single.collection.title": "Collection",
    "Employees.single.collection.description": "Supplement your collection with suitable photos for the set cards.",
    "Employees.single.collection.upload.photo.title": "Choose photo",
    "Employees.single.collection.upload.photo.description": "Click here and upload photo",
    "Employees.setCardsError": "* <b>{item}</b> images required",
    "Employees.setCards": "Create set cards",
    "Employees.setCardsModal.description": "Create a set card for your employees by choosing a top three photos. Indicate what the employee's characteristics are. View the example, are you satisfied? Click on generate set card.",
    "Employees.setCardsModal.dropdownOne.title": "General",
    "Employees.setCardsModal.dropdownTwo.title": "Characteristics",
    "Employees.setCardsModal.dropdownThree.title": "Free fields",
    "Employeees.setCardsmodal.submitButton": "Generate set cards",
    "Hours.extraCost": "Extra cost",
    "Hours.acceptedTab": "Accepted",
    "Jobs.occupation": "Occupation",
    "Jobs.connection": "Connection",
    "Jobs.acceptJob": "Accept request",
    "Jobs.rejectJob": "Reject request",
    "Jobs.address": "Address",
    "Jobs.city": "City",
    "Jobs.time": "Time",
    "Jobs.date": "Date",
    "Jobs.dates": "Dates",
    "Jobs.export": "Export",
    "Jobs.cityNote": "City/Note",
    "Jobs.houseNumber": "House number",
    "Jobs.houseNumber2": "Number",
    "Jobs.houseNumberExt": "House number extension",
    "Jobs.houseNumberExt2": "Addition",
    "Jobs.zipCode": "Zipcode",
    "Jobs.extraNote": "Extra note",
    "Jobs.projectName": "Project name",
    "Jobs.client": "Client",
    "Jobs.coordinator": "Coordinator",
    "Jobs.startDate": "Start date",
    "Jobs.endDate": "End date",
    "Jobs.zipCodeMin": "Zipcode (min)",
    "Jobs.zipCodeMax": "Zipcode (max)",
    "Jobs.resetFilters": "Reset filters",
    "Jobs.logo": "Logo",
    "Jobs.Single.employees": "Employees",
    "Jobs.Single.scheduled": "Scheduled",
    "Jobs.Single.pending": "Pending",
    "Jobs.Single.details": "Details",
    "Jobs.Single.media": "Media",
    "Jobs.Single.instructions": "Instructions",
    "Jobs.Single.jobSettings": "Job settings",
    "Jobs.Details.workLocation": "Work location",
    "Jobs.Details.streetName": "Street name",
    "Jobs.Details.number": "Number",
    "Jobs.Details.addition": "Addition",
    "Jobs.Details.zipCode": "Zip code",
    "Jobs.Details.city": "City",
    "Jobs.Details.employees": "Employees",
    "Jobs.Details.dateTime": "Date & time",
    "Jobs.Details.pool": "Current pool",
    "Jobs.Details.addPool": "Add pool",
    "Jobs.Details.updatePool": "Update pool",
    "Jobs.Details.time": "Time",
    "Jobs.Details.date": "Date",
    "Jobs.Details.maxCancellationTime": "Max. cancelation time",
    "Jobs.Details.maximumCancellationTime": "Maximum cancelation time",
    "Jobs.Details.description": "Description", // *****
    "Jobs.Details.documents": "Documents", // *****
    "Jobs.Details.address-description": "Address description", // *****
    "Jobs.Details.project": "Project", // *****
    "Jobs.Details.jobRequestDetails.title": "Job request details",
    "Jobs.Details.jobRequestDetails.employees": "Requested employees",
    "Jobs.Details.jobRequestDetails.remark": "Additional remark",
    "Jobs.planSomeoneIn": "Schedule",
    "Jobs.searchForAnEmployee": "Search", //****
    "Jobs.addressDescription": "Address description", //****
    "Jobs.schedule": "Schedule employee",
    "Jobs.hours": "Hours",
    "Jobs.name": "Jobs name",
    "Jobs.type": "Job type",
    "Jobs.workflow": "Workflow templates",
    "Jobs.selectWorkflow": "Select template",
    "Jobs.new": "New job",
    "Jobs.fullAddress": "Job address",
    "Jobs.teamInstructions.successUpload":
      "The team instructions were uploaded successfully",
    "Jobs.teamInstructions.errorUpload":
      "We had a problem trying to upload the team instructions",
    "Jobs.Details.poolDescription":
      "Add a pool to the job, to specify the employees who are qualified to work here. To make the job visible to all employees or the projects pool, select no pool.<br />This job is visible to %fu employees.",
    "Jobs.plannedJobTimes": "Planned job times",
    "Jobs.emptyState.title": "No jobs have been created yet",
    "Jobs.emptyState.description":
      "Click on the purple button on the top right and create a new job.",
    "Jobs.shareJobs.emptyState.title": "Sharing jobs in Fleks",
    "Jobs.shareJobs.emptyState.description": `Do you collaborate with external parties to complete your jobs? You can now also send these jobs directly from Fleks! This way you will also be able to keep the progress of your planning central in Fleks. <br/><br/>Contact Fleks for a further explanation or send an email to <a href:"mailto:support@fleks.info">support@fleks.info</a> to request the connection. Make sure that the cooperating party is also aware of this.`,
    "Jobs.shareJobs.emptyState.closeModal": "Perfect! Close window",
    "Jobs.shareJobs.confirmation": `You are about to share {items, plural,
      one {# job}
      other {# jobs}    
    } which has already started, are you sure about executing this action?`,
    "Jobs.shareJobs.restrictShareJobWithIncomingEmployee":
      "You can not share this job with another company since one or more incoming employees have been scheduled in this job.",
    "Jobs.sharedJobs.restrictMultipleShareJobWithIncomingEmployee": `You can no share the selected job(s) since that {incomingEmployees} job(s) have at least one incoming employee is already associated with it`,
    "Jobs.timeNoCluster": "Time (Is not shown in cluster)",
    "Jobs.numberOfPendingSpots": "Number of pending spots",
    "Jobs.numberOfSchedules": "Number of schedules",
    "Jobs.startTime": "Start time",
    "Jobs.endTime": "End time",
    "Jobs.requested": "Requested",
    "Jobs.requested.resume": "Requested ({totalAccepted}/{totalShared})",
    "Jobs.shared": "Shared",
    "Jobs.sharedAt": "Shared at",
    "Jobs.cancelledAt": "Cancelled at",
    "Jobs.cancelledBy": "Cancelled by",
    "Jobs.employeeRequested": "Employees requested to {tenant_name}",
    "Jobs.remarkMessage": "Remark message",
    "Jobs.employeeRequested.supplier": "Requested by {tenant_name}",
    "Jobs.scheduledEmployees": "Scheduled employees",
    "Jobs.appliedEmployees": "Applied employees",
    "Jobs.owner": "Owner",
    "Jobs.accepted": "Accepted",
    "Jobs.accepted.resume": "Accepted ({totalAccepted}/{totalShared})",
    "Jobs.accepted.by": "By ({tenantName})",
    "Jobs.cancelled.resume": "Cancelled ({totalAccepted}/{totalShared})",
    "Jobs.cancelled": "Cancelled",
    "Jobs.notRequested": "Not requested",
    "Jobs.alreadyFilled": "Already filled",
    "Jobs.createTemplate":
      "Create standard job for: {client_name} - {standard_project_name}",
    "Jobs.searchCandidates": "Search candidates",
    "Jobs.times": "Time",
    "Jobs.street": "Street",
    "Jobs.internalNote": "Internal note",
    "Jobs.teamInstructions": "Team instructions",
    "Jobs.teamInstructions.choice1": "Yes",
    "Jobs.teamInstructions.choice2": "No",
    "Jobs.jobStatus": "Status, registrations and invitations",
    "Jobs.archive.confirmation": `You are about to {archive, select,
      true {unarchive}
      false {archive}
    } {items, plural,
      one {# job}
      other {# jobs}    
    }, are you sure about executing this action?`,
    "Jobs.cancel.confirmation": `{withMyEmployees, select,
      true {One or more jobs have at least of one your employees working on it.}
      false {}
    } All the job applications of the external employees will be cancelled. Do you want to continue?`,
    "Jobs.cancel.success": `{items, plural,
      one { # job canceled }
      other { # jobs canceled }
    }`,
    "Jobs.DateTimeModal.title": "Adjust Date & Time",
    "Jobs.cancelJob": "Cancel job",
    "Jobs.cancelJobs": "Cancel job(s)",
    "Jobs.ReasonOptional": "Reason (optional)",
    "Jobs.cancellationPolicyWarning":
      "This job's maximum cancelation date is <b>{day}</b> of <b>{month_name}</b> <b>{year}</b>, do you want to cancel it?",
    "Jobs.activeApplicationsWarning":
      "There are still one or more active applications for this job, by proceeding, they will be automatically canceled.",
    "Jobs.cancelSuccessful": "Cancellation successful",
    "Jobs.plannedEmployees": "Planned employees",
    "Jobs.proposalEmployees": "Proposal employees",
    "Jobs.proposedEmployees": "Proposed employees",
    "Jobs.registeredEmployees": "Registered employees",
    "Jobs.invitedEmployees": "Invited employees",
    "Jobs.adjustDescription": "Adjust the job description",
    "Jobs.adjustAddressDescription": "Adjust the job address description",
    "Jobs.archived": `{items, plural,
      one { # job archived }
      other { # jobs archived }
    }`,
    "Jobs.proposeEmployee": "Propose employee",
    "Jobs.import.error":
      "An error occurred while we were trying to read the Google sheet. Make sure it is the correct sheet and it is not empty.",
    "Jobs.plannedFreelancers": "Planned freelancers",
    "Jobs.appliedFreelancers": "Applied freelancers",
    "Jobs.appliedFreelancers.applicationDate": "Application date",
    "Jobs.positionsOfTheJob": "Positions of the job",
    "Jobs.jobCategory": "Job function",
    "Jobs.jobFunction": "Job Function",
    "Jobs.details.single": "Single date",
    "Jobs.details.multiple": "Multiple dates",
    "Jobs.details.repetitiveJobs": "Repetitive jobs",
    "Jobs.details.dateAndTime": "Date and Time",
    "Jobs.details.from": "From",
    "Jobs.details.until": "Until",
    "Jobs.details.description": "Description (optional)",
    "Jobs.description.title": "Job description",
    "Jobs.selectFileToImport": "Select a file to import",
    "Jobs.description1":
      "Use the <a href='https://docs.google.com/spreadsheets/d/1BSxH_MoSlJoCq3sPaT16gsPDvnmztHRB5f_ZlGmhtk0/edit#gid=0' target='_blank'>following Google Sheet</a> as a template to fill your jobs in.",
    "Jobs.description2":
      "Have you already correctly filled the import file? You can proceed with the importation process.",
    "Jobs.description3":
      "The limit of the custom numbers for a job is 70. Also only can create 50 jobs to contains custom numbers.",
    "Jobs.associateProject":
      "To which project do you want to associate the new jobs to?",
    "Jobs.selectAProject": "Select a project",
    "Jobs.chooseTheFile": "Choose the file",
    "Jobs.chooseTheFileFromGoogle": "Choose the file from Google",
    "Jobs.chooseTheFileFromLocal": "Choose the file from Local",
    "Jobs.warnings.planInAndPropose":
      "You select jobs shared with you along with your own ones, are you sre that you want to continue scheduling and proposing them?",
    "Jobs.warnings.numberOfEmployees":
      "It is not possible to save this number since one or more of the selected jobs has a bigger number of active job applications",
    "Jobs.creation.warning.customNumbersLimit":
      "Don't is possible create the job, because the limit of the custom numbers is 70.",
    "Jobs.creation.warning.JobLimitWithCustomNumbers":
      "Don't is possible create this job, because the limit the jobs to create with custom numbers is 50.",
    "Jobs.goToProjects": "Go to projects",
      "CreationFlow.modal.success.title": "Success!",
    "CreationFlow.modal.success.description":
      "You have created <b>{items}</b> job(s).",
    "CreationFlow.modal.success.description.project":
      "You have created the <b>{project_name}</b> project.",
    "CreationFlow.modal.success.description.employee":
      "You have created <b>{items}</b> employee(s).",
    "CreationFlow.modal.actions.sendAMessage": "Send a message",
    "CreationFlow.modal.actions.shareJobs": "Share jobs",
    "CreationFlow.modal.actions.publishOnAdmin": "Publish on {admin_name}",
    "CreationFlow.modal.actions.goToTheJobsTable": "Go to the Jobs table",
    "CreationFlow.modal.actions.done": "Done",
    "CreationFlow.modal.actions.createJobs": "Create jobs for this project",
    "CreationFlow.modal.actions.goToProjectDetails": "Go to the details page",
    "CreationFlow.modal.actions.goToProjectsTable": "Go to the projects table",
    "CreationFlow.modal.actions.scheduleNow": "Schedule now",
    "CreationFlow.modal.actions.goToTheEmployeesTable":
      "Go to the Employees table",
    "Pictures.viewer": "Picture viewer", //****
    "NewEmployers.title": "New employers",
    "NewEmployers.companyName": "Company name",
    "NewEmployers.city": "City",
    "NewEmployers.address": "Adress",
    "NewEmployers.createdAt": "Created at",
    "NewEmployers.contactPerson": "Contact person",
    "NewEmployers.emptyState.title": "No new employers found or added",
    "NewEmployers.emptyState.description": "",
    "NewEmployers.contactPersonFirstName": "Contact person first name",
    "NewEmployers.verified": "{items} employers verified",
    "NewEmployers.verify.confirmation": `You are about to verify {items, plural,
      one {# employer}
      other {# employers}    
    }, are you sure about executing this action?`,
    "Employers.title": "Employers",
    "Employers.companyName": "Company name",
    "Employers.city": "City",
    "Employers.address": "Address",
    "Employers.activationDate": "Activation date",
    "Employers.createdAt": "Created at",
    "Employers.contactPerson": "Contact person",
    "Employers.emptyState.title": "No employers found or added",
    "Employers.emptyState.description": "",
    "Employers.contactPersonFirstName": "Contact person first name",
    "Employers.chamberOfCommerceNumber": "KvK Number",
    "Employees.description": "Your change is saved", //****
    "Employees.errorSaveMessage": "Your changes could not be saved.", //****
    "Employees.title": "Employees",
    "Employees.incoming.title": "Incoming employees",
    "Employees.internals": "Internals",
    "Employees.externals": "Externals",
    "Employees.newEmployee": "Add employee",
    "Employees.all": "All",
    "Employees.name": "Name",
    "Employees.number": "Tel. number",
    "Employees.city": "City",
    "Employees.age": "Age",
    "Employees.lastActive": "Last active",
    "Employees.activeSince": "Active since",
    "Employees.firstLogin": "First login",
    "Employees.lastLogin": "Last login",
    "Employees.phase": "Phase",
    "Employees.comments": "Comments",
    "Employees.editComments": "Edit comment",
    "Employees.editComments.success": "Comment edited successfully",
    "Employees.comments.extra": "Call notes",
    "Employees.create-employee": "Create employee",
    "Employees.information": "Information",
    "Employees.create-new-employee": "Create employee",
    "Employees.loginCredentials": "Login credentials",
    "Employees.resetEmail": "Send reset email",
    "Employees.resetPassword":
      "This will send an email to the employee with instructions to reset password",
    "Employees.sendPassword":
      "Email for reset password request is sent to: %em",
    "Employees.email": "Email",
    "Employees.password": "Password",
    "Employees.initials": "Initials",
    "Employees.first_name": "First name",
    "Employees.full_name": "Full name",
    "Employees.prefix": "Prefix",
    "Employees.surname": "Surname",
    "Employees.last_name": "Last name",
    "Employees.birth_date": "Birth date",
    "Employees.gender": "Gender",
    "Employees.street": "Street name",
    "Employees.street.2": "Street",
    "Employees.state": "State/region",
    "Employees.zip-code": "Zip code",
    "Employees.mobile": "Mobile number",
    "Employees.personalNumber": "Personal number",
    "Employees.houseNumber": "House number",
    "Employees.houseNumberExt": "Ext (optional)",
    "Employees.idType": "Id type",
    "Employees.documentNumber": "Document number",
    "Employees.dateCreation": "Created at",
    "Employees.lastInvitation": "Invited at",
    "Employees.bankAccountNumber": "Bank account number",
    "Employees.initial": "Initial",
    "Employees.bsn": "Citizen service number (BSN)",
    "Employees.startDate": "Start date",
    "Employees.endDate": "End date",
    "Employees.firstName": "First name",
    "Employees.lastName": "Last name",
    "Employees.locationRadius": "Location max (km)",
    "Employees.noMaxLocationRadius": "No max distance",
    "Employees.locationKm": "Max (km)",
    "Employees.zipCodeFrom": "Zip code from",
    "Employees.zipCodeFromMin": "Zip code from (min)",
    "Employees.zipCodeTill": "Zip code till",
    "Employees.zipCodeTillMax": "Zip code till (max)",
    "Employees.categories": "Functions",
    "Employee.specifications": "Employee specifications",
    "Employees.categoryName": "Function name",
    "Employees.activeContract": "Active contract",
    "Employees.whitContract": "Without contract",
    "Employees.drivingLicense": "Driving license",
    "Employees.car": "Car",
    "Employees.sectors": "Functions",
    "Employees.trainings": "Trainings",
    "Employees.qualifications": "Qualifications",
    "Employees.travelInformation": "Travel information",
    "Employees.travelSpecifications": "Travel specifications",
    "Employees.faculties": "Specifications",
    "Employees.addFaculties": "Add specifications",
    "Employees.additionalInformation": "Additional information",
    "Employees.IBAN": "IBAN",
    "Employees.socialSecurityNumber": "Social security number",
    "Employees.expirationDate": "Expiration date",
    "Employees.frontSideId": "Front side ID",
    "Employees.backSideId": "Back side ID",
    "Employees.viewImg": "View",
    "Employees.upload": "Upload",
    "EmployeeModal.title": "Export employee",
    "Employees.resetFilters": "Reset filters",
    "Employees.schedule": "Schedule",
    "Employees.reInvite": "Re-invite",
    "Employees.schedule.confirmation": `You are about to schedule {items, plural,
        one {# employee}
        other {# employees}    
      } for a job which has already started, are you sure about executing this action?`,
    "Employees.reject": "Reject",
    "Employees.accept": "Accept",
    "Employees.propose": "Propose",
    "Employees.propose.confirmation": `You are about to propose {items, plural,
        one {# employee}
        other {# employees}    
      } for a job which has already started, are you sure about executing this action?`,
    "Employees.proposeExit": "Propose and exit",
    "Employees.scheduleAndPropose": "Plan in & Propose",
    "Employees.uninvite": "Uninvite",
    "Employees.cancel": "Cancel",
    "Employees.distance": "Distance",
    "Employees.numberOfEmployees": "Number of employees",
    "Employees.phoneNumber.2": "Phone number",
    "Employees.invalidPhone": "Invalid phone number", //*****
    "Employees.invalidFormat": "Invalid format", //*****
    "Employees.onlyText": "Only accept letters", //*****
    "Employees.validation.birthDate": "Min age is 18",
    "Employees.validation.zipCode":
      "Zipcode must be 4 digits followed by two letters.",
    "Employees.validation.password":
      "The password must have between 8 and 32 characters.",
    "Employees.validation.email": "Invalid email.",
    "Employees.validation.email.length":
      "The email must have between 8 and 64 caracteres.",
    "Employees.validation.fields": "Some fields was wrong.",
    "Employees.validation.fields.description":
      "The employee was created, but not verified because some fields were not accepted. You can find it in the applicants section.",
    "Employees.validation.exists": "The employee already exits.",
    "Employees.registrationDate": "Registration date",
    "Employees.language": "Language",
    "Employees.dutch": "Dutch",
    "Employees.english": "English",
    "Employees.circlePhase": "%n has worked %t times in the past 30 days.",
    "Employees.checkPhase": "%n has completed their registration",
    "Employees.checkPhase2": "%n hasn’t completed phase 2 of the registration",
    "Employees.checkPhase3":
      "%n hasn’t completed their basic registration yet.",
    "Employees.filePhase": "%n has no contract at the moment",
    "Employees.filePhase2": "%n has signed %cn - the contract ends at %ed",
    "Employees.filePhase3": "%n hasn't signed %cn - the contract ends at %ed",
    "Employees.filePhase4":
      "%n has signed %cn - the contract ends at %ed. The following is upcoming %ucn - the contract starts at %sd",
    "Employees.filePhase5":
      "The following is upcoming %ucn - the contract starts at %sd",
    "Employees.filePhase6": "The last contract ended at %ed",
    "Employees.birthDate": "Birth date",
    "Employees.birthDate.2": "Birthdate",
    "Employees.birthDate.3": "Birth date",
    "Employees.address": "Address",
    "Employees.jobCategory": "Job function",
    "Employees.isVerified": "Phase 1",
    "Employees.isRegComplete": "Phase 2",
    "Employees.minNumberTimes": "Number of times worked (min)",
    "Employees.maxNumberTimes": "Number of times worked (max)",
    "Employees.stepsEqual": "Steps completed",
    "Employees.stepsGte": "Steps completed",
    "Employees.stepsLte": "Steps completed",
    "Employees.phase_1_incomplete": "Phase 1 incomplete",
    "Employees.phase_1_complete": "Phase 1 complete",
    "Employees.phase_2_incomplete": "Phase 2 incomplete",
    "Employees.phase_2_complete": "Phase 2 complete",
    "Employees.isApproved": "Is approved",
    "Employees.isExported": "Is exported",
    "Employees.socialNumber": "Social number",
    "Employees.wageTaxRebate": "Wage tax rebate",
    "Employees.wageTaxRebateDate": "Wage tax rebate date",
    "Employees.startPeriodJobs": "Start period jobs",
    "Employees.endPeriodJobs": "End period jobs",
    "Employees.numberOfJobsMin": "Number of jobs (min)",
    "Employees.numberOfJobsMax": "Number of jobs (max)",
    "Employees.hasWorked": "Has worked",
    "Employees.wageTaxRebateGte": "Wage tax rebate (max)",
    "Employees.wageTaxRebateLte": "Wage tax rebate (min)",
    "Employees.birthDateDtGte": "Age number (max)", //*****
    "Employees.birthDateDtLte": "Age number (min)", //*****
    "Employees.hasWorkedGte": "First job date (min)",
    "Employees.hasWorkedLte": "First job date (max)",
    "Employees.fullName": "Full name",
    "Employees.search": "Search employee by name",
    "Employees.phoneNumber": "Telephone number",
    "Employees.newEmployees": "New employees",
    "Employees.newApplication.invitation": "Employee invited successfully",
    "Employees.newApplication.schedule": "Employee scheduled successfully",
    "Employees.newApplication.rejected": "Employee rejected successfully",
    "Employees.newApplication.proposed": "Employee proposed successfully",
    "Employees.newApplication.cancel": "Employee cancelled successfully",
    "Employees.jobPermission": "Is qualified",
    "Employees.options": "Options",
    "Employees.monday": "Mo",
    "Employees.tuesday": "Tu",
    "Employees.wednesday": "We",
    "Employees.thursday": "Th",
    "Employees.friday": "Fr",
    "Employees.saturday": "Sa",
    "Employees.sunday": "Su",
    "Employees.night": "Night",
    "Employees.morning": "Morning",
    "Employees.afternoon": "Afternoon",
    "Employees.evening": "Evening",
    "Employees.history.absent": "Absent", // *****
    "Employees.history.notifications": "Notifications", // *****
    "Employees.customNumber": "Personal ID",
    "Employees.fullAddress": "Employee address",
    "Employees.insertion": "Insertion",
    "Employees.sendOnboarding.explain":
      "Send onboarding emails to selected users",
    "Employees.invitedAt": "Invited at",
    "Employees.openedAt": "Opened at",
    "Employees.registeredAt": "Registered at",
    "Employees.firstJobAt": "First job at",
    "Employees.lastJobAt": "Last job at",
    "Employees.weekHours": "Week hours",
    "Employees.emptyState.title": "No employees found or added",
    "Employees.emptyState.description":
      "Click on the purple button in the top right corner to add an employee.",
    "Employees.newEmployeesCreated":
      "New employee created with: {employee_email}.",
    "Employees.withdrawInvite": "Withdraw",
    "Employees.workedRecently": "Active",
    "Employees.workedRecently.hover": "Worked in the last 30 days",
    "Employees.LoggedInInactive.hover": "Not logged in during the last 60 days",
    "Employees.LoggedIn.hover": "Last logged in within the last 30 days",
    "Employees.LoggedInPast.hover": "Logged in between 30 and 60 days ago",
    "Employees.worked": "Active",
    "Employees.worked.hover": "Worked in the previous month",
    "Employees.inactive": "Inactive",
    "Employees.inactive.hover": "Did not work in the past month or this month",
    "Employees.waiting": "New",
    "Employees.waiting.hover": "Waiting for the first job!",
    "Employees.archive.confirmation": `You are about to {archive, select,
      true {unarchive}
      false {archive}
    } {items, plural,
      one {# employee}
      other {# employees}    
    }, are you sure about executing this action?`,
    "Employees.single.contract.payroll": "Payroll/income tax",
    "Employees.single.contract.payroll.wage": "Requested the wage/income tax?",
    "Employees.single.contract.payroll.wage.yes": "Yes, from {date}",
    "Employees.single.contract.payroll.wage.no": "No",
    "Employees.single.contract.business": "Essentials",
    "Employees.single.contract.business.iban": "IBAN",
    "Employees.single.contract.contracts": "Contract",
    "Employees.single.contract.contracts.dateRange":
      "{from} {to, plural, =null {} other {to}} {to}",
    "Employees.single.contract.contracts.add": "Add contract",
    "Employees.single.contract.contracts.addOne": "Add a contract",
    "Employees.single.contract.shareEmployee.titleButtonModal":
      "Share an employee",
    "Employees.single.contract.shareEmployee.titleButtonModalEdit":
      "Update Employee Sharing",
    "Employees.single.contract.shareEmployee.titleCard": "Share an employee",
    "Employees.single.contract.shareEmployee.titleCardEdit":
      "Update Employee Sharing",
    "Employees.single.contract.shareEmployee.textButtonAdd":
      "Add share the employee",
    "Employees.single.contract.shareEmployee.descriptionCard":
      "Choose a connection to share the employee with and add a period and/or single days.",
    "Employees.single.contract.shareEmployee.descriptionCardEdit":
      "Edit the period and/or add single days.",
    "Employees.single.contract.shareEmployee.labelTextTitleConnection":
      "Connection",
    "Employees.single.contract.shareEmployee.labelTextConnection":
      "Select a connection",
    "Employees.single.contract.shareEmployee.labelTextPeriod":
      "Select a period",
    "Employees.single.contract.shareEmployee.labelTextWeekdays":
      "Optional: Specify weekdays in this period",
    "Employees.single.contract.shareEmployee.labelTextSingleDays":
      "Select single days",
    "Employees.single.contract.shareEmployee.labelTextHoursPerWeek":
      "Optional: How many hours per week?",
    "Employees.single.contract.shareEmployee.labelInputHoursAmount":
      "Hour amount",
    "Employees.single.contract.shareEmployee.labelWeekdaysSelected":
      "All weekdays are selected",
    "Employees.id": "ID",
    "Employees.forgotPassword": "Forgot the password? Click here",
    "Employees.lastContact": "Last contacted",
    "Employees.agenda": "Agenda",
    "Employees.inviteToJob": "Invite for this job",
    "Employees.planInForThisJobs": "Plan in for this job",
    "Employees.proposeForThisJob": "Propose for this job",
    "EmployeesImport.title": "Import employees",
    "EmployeesImport.sheetName": "Sheet name",
    "EmployeesImport.initialCell": "Initial cell",
    "EmployeesImport.finalCell": "Final cell",
    "EmployeesImport.reserve": "Reserve",
    "EmployeesImport.quiz": "Quiz applicable",
    "EmployeesImport.sheetName.explain":
      "The name of the specific document sheet where you would like to import. By default it's the first sheet",
    "EmployeesImport.initialCell.explain":
      "That refers to first cell of a group of cells in the spreadsheet, and is typically used in formulas. By default it's the first cell: A1",
    "EmployeesImport.finalCell.explain":
      "That refers to last cell of a group of cells in the spreadsheet, and is typically used in formulas. By default it's the last valid cell",
    "EmployeesImport.import.explain":
      "You should keep in mind that if you choose a range of cells this should include the row with the name of the fields",
    "EmployeesImport.explain":
      "Import employees through a Google Sheet spreadsheet with the following fields: Email, First name, last name, initials, address, house number, house number ext, phone number, post code, city, is verified, is reg complete, birth date and job functions. This fields must be on first row of spreadsheet",
    "EmployeesImport.wrongCell": "Invalid cell",
    "EmployeesImport.discardedEmployees":
      "employees were discarded because they have no some of this fields: Email",
    "EmployeesImport.discardedFreeFields": `The following free fields from this file could not be recognized: {free_fields}`,
    "EmployeesImport.import": "Import now",
    "EmployeesImport.error": "Error when import",
    "EmployeesImport.error-explain":
      "Make sure that the sheet name, the initial cell and the final cell are correct",
    "EmployeesImport.toCreate": "Imported employees",
    "EmployeesImport.created": "Created employees",
    "EmployeesImport.success": "Employees created successfully",
    "EmployeesImport.error-save": "Employees no created because already exists",
    "EmployeesImport.results": "Import employees resume",
    "EmployeesImport.errorWhenSendOnboardingEmail":
      "We had got problems sending some emails",
    "EmployeesImport.sendOnboardingEmail":
      "De onboarding email is verstuurd naar de geimporteerde gebruikers.",
    "EmployeesImport.sendOnboardingEmail.single":
      "Inform the employee with the onboarding email about the usage of Fleks.",
    "EmployeesImport.descriptionWithTemplate":
      "Use the <a href='https://docs.google.com/spreadsheets/d/1Kkv35ddwuzQz-98qmNvhSH5M-hVedyj5nzbxZP6Dbmc/edit#gid=0' target='_blank'>following Google Sheet</a> as a template to fill your employees in.",
    "Employees.BasicInformationModal.title": "Adjust the general information",
    "Jobs.jobFreeFieldsModal.title": "Adjust the job free fields",
    "Employees.TravelSpecificationsModal.title":
      "Adjust the travel specifications",
    "Employees.CharacteristicsModal.title":
      "Adjust the characteristics information",
    "Employees.AdditionalInformationModal.title":
      "Adjust the additional information",
    "Employees.FacultiesModal.title": "Adjust the specifications information",
    "Employees.FacultiesModal.footer": "Attention! Existing categories that have already been added with an end date will be overwritten.",
    "Employees.SpecificationsModal.title": "Add specifications",
    "Employees.modalSave.tittleButton": "Add the information",
    "Employees.BusinessDataModal.title": "Adjust the Essentials information",
    "Employees.BusinessDataModal.adjustBankData.title":
      "Adjust bank information",
    "Employees.PayrollIncomeTaxModal.title":
      "Adjust the Payroll tax/Income tax information",
    "Employees.adjustContract": "Adjust the Contract information",
    "Employees.modal.save": "Save the information",
    "Employees.contactMoment": "Contact moment",
    "Employees.sendMessageScheduledEmployees":
      "Send email to scheduled employees",
    "Employees.selectMaximumDate": "Select maximum date",
    "Employees.updateFunctions": "Add specifications",
    "Employees.shareEmployees": "Share employees",
    "Employees.shareEmployees.alreadyShared": `At least one of the selected employees has already been shared with {tenant_name}`,
    "Employees.shareEmployees.success": `The selected employees are being shared with {tenant_name}`,
    "Applicants.title": "Applicants",
    "Applicants.newApplicant": "New Applicant",
    "Applicants.newAccepted": "New Accepted Applicant",
    "Applicants.accepted": "Accepted",
    "Applicants.applicants": "Applicants",
    "Applicants.createApplicant.title": "Create Applicant",
    "Applicants.createApplicant.save": "Create Applicant",
    "Applicants.sendOnboardingEmail.check":
      "Inform the applicant with the onboarding email about the usage of Fleks.",
    "Applicants.newApplicantCreated":
      "New Applicant created with: {employee_email}.",
    "Applicants.confirm-accept-checked":
      "Are you sure you want to send the applicant to the next phase?",
    "Applicants.emptyState.title": "No applicants have been added yet",
    "Applicants.emptyState.description":
      "Click on the purple button in the top right corner to add a employee.",
    "Applicants.registered": "Registered",
    "Applicants.link": "Show the Applicants link",
    "Applicants.link.title": "Share your applicant's link",
    "Applicants.link.description":
      "Copy the link shown below and publish it where potential employees can read it and apply to work on your company!",
    "Applicants.agreement":
      "By creating an account you agree on the privacy statement / agreement.",
    "History.totalJobs": "Total jobs",
    "History.extraHours": "Total extra hours",
    "History.grossSalary": "Total gross salary",
    "History.travelTime": "Total travel time",
    "History.travelTimeFee": "Total travel time fee",
    "History.kmDriven": "Total Km driven",
    "History.otherCost": "Total others cost",
    "History.sick": "Total sick",
    "History.noShow": "Total no show",
    "Notifications.error": "Error", //*****
    "Notifications.beAware": "Be aware",
    "Notifications.confirm-accept": "Are you sure?",
    "Notifications.confirm-accept-checked":
      "Are you sure to store all the selected items?",
    "Notifications.successful": "Successful",
    "Notifications.archived": "archived",
    "Notifications.unarchived": "unarchived",
    "Notifications.activate":
      "Now you can receive notifications from Fleks. Click here and turn it on!",
    "Notifications.confirm-accept-declarations":
      "You are about to accept the selected declaration(s), do you want to confirm this?",
    "Hours.travelDistance": "Travel distance",
    "Hours.bonus": "Bonus",
    "Hours.function_based": "Amount",
    "Hours.employeeZipCode": "Employee's ZipCode",
    "Hours.employeePhoneNumber": "Phone Number",
    "Hours.employeeEmail": "Employees Email",
    "Hours.employeeCity": "Employee's City",
    "Hours.employeeAddress": "Employee's Address",
    "Hours.employeeFullAddress": "Employee's Full Address",
    "Hours.employeeHouseN": "Employee's House Number",
    "Hours.employeeHouseNExt": "Employee's House N Ext",
    "Hours.jobZipCode": "Job's Zip Code",
    "Hours.jobCity": "Job's City",
    "Hours.jobAddress": "Job's Address",
    "Hours.jobFullAddress": "Job's Full Address",
    "Hours.jobHouseN": "Job's House Number",
    "Hours.jobHoouseNExt": "Job's House N Ext",
    "Hours.jobCategory": "Job function",
    "Hours.startDateMin": "Start Date (min)",
    "Hours.startDateMax": "Start Date (max)",
    "Hours.hostNumber": "Host number",
    "Hours.name": "Name",
    "Hours.lastname": "Lastname",
    "Hours.city": "City",
    "Hours.totalHours": "Total Hours",
    "Hours.extra": "Extra",
    "Hours.travelCosts": "Travel Costs",
    "Hours.totalCost": "Total Cost",
    "Hours.title": "Hours",
    "Hours.newEmployee": "New employee",
    "Hours.employeeId": "Employee ID",
    "Hours.all": "All",
    "Hours.open": "Open",
    "Hours.outliers": "Outliers",
    "Hours.accepted": "Accepted",
    "Hours.processed": "Processed",
    "Hours.notSent": "Not sent",
    "Hours.checkTheHours": "Check the hours",
    "Hours.incompleted": "Incompleted",
    "Hours.initial": "Initial",
    "Hours.prefix": "Prefix",
    "Hours.birthDay": "Birth day",
    "Hours.age": "Age",
    "Hours.projectTitle": "Project title",
    "Hours.startDate": "Start date",
    "Hours.workedDay": "Worked hours",
    "Hours.additionalHours": "Additional hours",
    "Hours.breakHours": "Break hours (min)",
    "Hours.appliedPaymentMethod": "Applied payment method",
    "Hours.hourlyWage": "Hourly wage",
    "Hours.fixedHourlyWage": "Fixed hourly wage",
    "Hours.fixedAmount": "Fixed amount",
    "Hours.fixedReserveRate": "Fixed reserve rate",
    "Hours.travelHours": "Travel hours",
    "Hours.travelHoursRate": "Travel hours rate",
    "Hours.travelTimeFee": "Travel time fee",
    "Hours.noteStartTime": "When updating start or end time both are required.",
    "Hours.noteBreak": "Updating only the break is a possibility.",
    "Hours.calculate-error": "Error to calculate travel expenses", // *****
    "Hours.calculate-success": "Calculate travel expenses successfully", // *****
    "Hours.no-calculate":
      "It is not possible to calculate travel expenses. Please check the directions of the job and the employee.", // *****
    "Hours.numberDrivenKm": "Number of driven km",
    "Hours.ratePerKm": "Rate per km",
    "Hours.drivenKmFee": "Driven km fee",
    "Hours.publicTransportFee": "Public transport fee",
    "Hours.advances": "Advances",
    "Hours.projectNumber": "Project number",
    "Hours.declarationNumber": "Job number",
    "Hours.note": "Notes",
    "Hours.status": "Status",
    "Hours.endDate": "End date", //*****
    "Hours.isException": "Is exception?",
    "Hours.isCompleted": "Workflow completed?",
    "Hours.details": "Details",
    "Hours.accepted-success": "Hour(s) accepted successfully",
    "Hours.close-success": "Hour(s) close successfully",
    "Hours.open-to-approved-success":
      "The hours are being Approved in the background, the changes will be reflected in the table in a few minutes. This can take up to an hour.",
    "Hours.approved-to-processed-success":
      "The hours are being Processed in the background, the changes will be reflected in the table in a few minutes. This can take up to an hour.",
    "Hours.toinvoiced-to-invoiced-success":
      "The hours are being Invoiced in the background, the changes will be reflected in the table in a few minutes. This can take up to an hour.",
    "Hours.otherCost": "Other cost pictures", // *****
    "Hours.transportationCost": "Transportation cost pictures", // *****
    "Hours.declarationPhoto": "Declaration pictures", // *****
    "Hours.both": "Hour cost pictures", // *****
    "Hours.fixed_rate": "Fixed rate", // *****
    "Hours.hourly_rate": "Fixed rate per hour", // *****
    "Hours.custom_age_related_rate": "Custom rate per age", // *****-------------------------><br>---
    "Hours.reserve_rate": "Reserved rate", // *****
    "Hours.declaration": "Declaration", // *****
    "Hours.costs": "Costs", // *****
    "Hours.travel": "Travel", // *****
    "Hours.travelDistanceApplicable": "Number of driven km", // *****
    "Hours.travelTime": "Travel time", // *****
    "Hours.travelTimeCompensation": "Travel time compensation", // *****
    "Hours.travelTimeRate": "Travel time rate", // *****
    "Hours.publicTransport": "Upload public transport costs possible", // *****
    "Hours.preCalculateTravel": "Travel cost calculated by Google Maps", // *****
    "Hours.grossSalary": "Gross salary (excl. travel time fee)", // *****
    "Hours.otherCosts": "Other costs", // *****
    "Hours.checkTimes": "Clocked times", // *****
    "Hours.projectID": "Project ID", // *****
    "Hours.travelDistanceCost": "Travel distance cost", // *****
    "Hours.travelTimesCost": "Travel times cost", // *****
    "Hours.kmDrivingCost": "Km driving cost", // *****
    "Hours.netCosts": "Net cost", // *****
    "Hours.gross-salary": "Gross salary", // *****
    "Hours.needCheckAndPlan": "The hour still needs to be checked by planning",
    "Hours.checkedAndNeedPlan":
      "The hour is checked by planning but still needs to be checked by finance",
    "Hours.checkedAndPlanned": "The hour is checked by finance and planning",
    "Hours.worked": "Worked",
    "Hours.approvedExported": "Status of approved/exported",
    "Hours.wasScheduleBefore.warning":
      "'[full_name_employee]' has previously been rejected for this job, are you sure you want to schedule him?",
    "Hours.actions.correction": "Make a correction",
    "Hours.actions.comment": "Place a comment",
    "Hours.actions.sendToProcessed": "Send to processed",
    "Hours.actions.acceptDeclaration": "Accept declaration",
    "Hours.actions.calculateTravelExpenses": "Calculate travel expenses",
    "Hours.actions.acceptDeclaration.allHours": "Approve declaration(s)",
    "Hours.actions.acceptDeclaration.allHours.isNotHourOwner":
      "You are not the hour owner of {japs} of the selected items, please, check the selected information before continuing.",
    "Hours.correction": "New declaration",
    "Hours.actions.invoice.allHours": "Send selected hours to invoiced",
    "Hours.correction.success": "Hour's correction created successfully",
    "Hours.correction.error": "We had problem creating hour's correction",
    "Hours.update.success": "Hours updated successfully",
    "Hours.update.error": "We had problem updating the hours",
    "Hours.newDeclaration": "New declaration", // *****
    "Hours.invitePending": "Invite pending",
    "Hours.appliedPending": "Applied pending", // ******
    "Hours.proposed": "Proposed",
    "Hours.proposal": "Proposal",
    "Hours.activeFixedRate": "Fixed rate", // ******
    "Hours.applicableTravelTime": "Travel time", // ******
    "Hours.totalNetCost": "Net costs",
    "Hours.activePaymentType": "Applied payment method",
    "Hours.totalTravelTime": "Travel time",
    "Hours.numberJobApplications": "Number of time worked",
    "Hours.age_hourly_rate": "Paid by age",
    "Hours.contract_based": "Contract based",
    "Hours.highestHourlyRate": "Highest hourly rate",
    "Hours.hoursHourlyRate": "Hours with hourly rate",
    "Hours.hoursReserveRate": "Hours with a fixed reserve rate",
    "Hours.hoursFixedRate": "Hours with a fixed rate",
    "Hours.totalHourlySalary": "Gross salary with hourly rates",
    "Hours.totalReserveSalary": "Total reserve salary",
    "Hours.totalFixedSalary": "Total fixed salary",
    "Hours.makeSalaryRun": "Make salary run",
    "Hours.correction.info":
      "The correction will be placed in the open hours tab",
    "Hours.correction.disabled": `You can not update the hours anymore, since these are already processed by {email}, in this case a correction should be made. Go to the processed tab of the internals per shift table and click on the times to quickly make a correction.`,
    "Hours.notCalculated":
      "We are having trouble calculating the distance and travel time, please try again later.", // *****
    "Hours.isRejected": "Rejected",
    "Hours.emptyState.title": "Oh no! We cannot find declarations",
    "Hours.emptyState.description": "Refresh the page and try again",
    "Hours.log": "Declaration status",
    "Hours.hour": "Hours",
    "Hours.startAndEndTime": "Start/end time",
    "Hours.hoursWorked": "Working hours",
    "Hours.extraHours": "Extra hours",
    "Hours.rates": "Rates",
    "Hours.travelExpenses": "Travel expenses",
    "Hours.amountOfKm": "Amount of KM",
    "Hours.publicTransportationCost": "Public transportation cost",
    "Hours.toolTip.title": "Travel Specifications",
    "Hours.tooltip.car": "Car",
    "Hours.tooltip.license": "License",
    "Hours.tooltip.publicTransportCard": "Public Transport Card",
    "Hours.notOwner":
      "You cannot select this application because you are not the owner of the job",
    "Hours.supplier": "Supplier",
    "Hours.singles": "Singles",
    "Hours.singles.title": "Hours - singles",
    "Hours.totals.title": "Hours - totals",
    "Hours.workedHoursAndBreakHours": `- Planned times: {plannedHours}<br/>- Check In: {checkIn}<br/>- Clocked times: {clockedHours}<br/>- Definitive times: {definitiveHours}<br/>- Worked hours: {workedHours}<br/>- Break hours: {breakCompensation}<br/>- Sign Off time (manager): {sign_off_time}<br/>- Sign Off time (employee): {employee_sign_off_time}`,
    "Hours.workedHoursAndBreakHours.allHours": `- Planned times: {plannedHours}<br/>- Check In: {checkIn}<br/>- Clocked times: {clockedHours}<br/>- Definitive times: {definitiveHours}<br/>- Suggested hours: {suggestedHours}<br/>- Comment: {comment}`,
    "Hours.travelTimeAndApplicableTravelTime": `- Original travel time: {travelTime}<br/>- Applicable travel time: {applicableTravelTime}`,
    "Hours.travelDistanceAndTravelDistanceApplicable":
      "Original travel distance {travelDistance} km, Applicable travel distance {travelDistanceApplicable} km",
    "Hours.archive.confirmation": `You are about to {archive, select,
      true {unarchive}
      false {archive}
    } {items, plural,
      one {# declaration}
      other {# declarations}    
    }, are you sure about executing this action?`,
    "Hours.proposedDate": "Proposed date",
    "Hours.registeredDate": "Registered date",
    "Hours.invitedDate": "Invited date",
    "Hours.toInvoice": "To invoice",
    "Hours.invoiced": "Invoiced",
    "Hours.initialCheck": "Initial check",
    "Hours.feebackLoopStatus": "Feedback loop status",
    "Hours.suggestionMade": "Suggestion made",
    "Hours.feedbackLoopStatus.not_started": "Not started",
    "Hours.feedbackLoopStatus.active": "Active",
    "Hours.feedbackLoopStatus.approved": "Approved",
    "Hours.feedbackLoopStatus.rejected": "Rejected",
    "Hours.feedbackLoopStatus.expired": "Expired",
    "Hours.options.sendToFinqle": "Send to Finqle", 
    "Hours.sendToFinqleSuccess": "The selected hours and costs have been sent to Finqle. If the billing request is not yet in Finqle, please check the Connections portal to view the error.", 
    "Hours.sendToFinqlePartialSuccess": "Some of the selected hours and costs have been sent to Finqle. Not everything could be created. Please check the Connections portal to view the successful and unsuccessful services.", 
    "Hours.sendToFinqleFailed": "The selected hours and costs have not been sent to Finqle due to an issue. Please contact the Fleks support team.", 
    "Hours.sendToFinqleConfirmTitle": "Are you sure ?",
    "Hours.sendToFinqleConfirmDescription": "The hours will be send to finqle, already sent shifts will not be send again.",
    "Hours.startFeedbackLoop.selectedJapOwn": `You have selected {japWithoutShared, plural,
      one { # own job application }
      other { # own job applications }
    } but you can't send these items. In order to do this, you must select the applications that are shared.`,
    "Hours.startFeedbackLoop.selectedJapNotHourOwner": `You have selected {japNotHourOwner, plural,
      one { # own job application }
      other { # own job applications }
    } but you can't send these items. In order to do this, you must select the applications where you are the hours owner.`,
    "Hours.startFeedbackLoop.selectedJapWithFeedbackloopActive": `You have selected {japWithFeedbackloopActive, plural,
      one { # job application }
      other { # job applications }
    } that have already been send. You can't send again these items. In order to do this, you must select the shared applications that have not been sent before.`,
    "Hours.startFeedbackLoop.empty.title": "Send schedule check",
    "Hours.startFeedbackLoop.empty.subtitle": `You have selected {jap, plural, one { # job application } other { # job applications} } to begin the process of verification of hours worked and to be able to request approval or suggestions about the hours.`,
    "Hours.startFeedbackLoop.modal.title": "Send to checked",
    "Hours.startFeedbackLoop.modal.subtitle": `You have send {jap, plural, one { # employee hour } other { # employee hours } } to checked. Select a deadline and connection(s) to double check the hours`,
    "Hours.startFeedbackLoop.modal.deadline": "Deadline",
    "Hours.startFeedbackLoop.modal.error.deadline": "Select a correct deadline",
    "Hours.startFeedbackLoop.modal.button.titleSend": "Send hours",
    "Hours.startFeedbackLoop.modal.checkbox.selectAll":
      "Select all connections",
    "Hours.statusFeedbackLoop.no_feedback.hourOwner": "Checked",
    "Hours.statusFeedbackLoop.no_feedback.hourSupplier": `Checked by {hourOwner}`,
    "Hours.statusFeedbackLoop.feedback_requested.hourSupplier":
      "Check the hours",
    "Hours.statusFeedbackLoop.feedback_added.hourSupplier": "Suggestion added",
    "Hours.statusFeedbackLoop.feedback_sent.hourOwner":
      "Suggestion sent by {hourSupplier}",
    "Hours.statusFeedbackLoop.feedback_sent.hourSupplier": "Suggestion sent",
    "Hours.statusFeedbackLoop.hours_approved.hourOwner": `Approved by {hourSupplier}`,
    "Hours.statusFeedbackLoop.automatically_accepted.hourOwner":
      "Checking period expired",
    "Hours.statusFeedbackLoop.automatically_accepted.hourSupplier":
      "Automatically accepted",
    "Hours.statusFeedbackLoop.hourApprovalRequest": "Hour approval requested",
    "Hours.statusFeedbackLoop.suggestionApproved": "Suggestion approved",
    "Hours.statusFeedbackLoop.suggestionDenied": "Suggestion denied",
    "Hours.statusFeedbackLoop.approved": "Approved",
    "Hours.popoverModal.hourSupplier.title": "Make a suggestion",
    "Hours.popoverModal.hourOwner.title": "Requested suggestion",
    "Hours.popoverModal.hourSupplier.titleButton.add": "Add suggestion",
    "Hours.popoverModal.hourSupplier.titleButton.approved": "Approve hours",
    "Hours.popoverModal.hourOwner.titleButton.accept": "Accept suggestion",
    "Hours.popoverModal.hourOwner.titleButton.decline": "Decline",
    "Hours.popoverModal.hourOwner.titlePopConfirm.decline":
      "You are about to reject the suggestion, do you want to continue?",
    "Hours.popoverModal.hourOwner.titlePopConfirm.accept":
      "You are about to approve the suggestion, do you want to continue?",
    "Hours.allHours.button.sendReviewedHours": "Send reviewed hours",
    "Hours.allHours.button.error.sendReviewedHours":
      "Revised hours are only allowed to be submitted if they are accepted or have a suggestion added.",
    "Hours.invoices": "Invoices",
    "Hours.invoices.title": "Hours - invoices",
    "Hours.invoices.actions.makeCounterProposal": "Make a counterproposal",
    "Hours.invoices.freelancers": "Freelancers",
    "Hours.invoices.lastProposal": "Last proposal",
    "Hours.invoices.invalidatedProposals": "Invalidated proposals",
    "Hours.invoices.creator": "Creator",
    "Hours.invoices.breakTime": "Break time",
    "Hours.invoices.breakDuration": "Break duration",
    "Hours.invoices.currentDeclaration": "Current declaration",
    "Hours.invoices.newCorrection": "New correction",
    "Hours.invoices.sendCounterProposal": "Send counter-proposal",
    "Hours.invoices.modal.counterProposal.title": "Create counter-proposal",
    "Hours.invoices.accepted-success": "Invoice(s) accepted successfully",
    "Hours.invoices.created-success": "Proposal created successfully",
    "Hours.invoices.viewInvoices": "View invoice",
    "Hours.correctionModal.adjustHoursQuickly": "Adjust the hours quickly",
    "Hours.correctionModal.markAsApproved": "Mark as approved",
    "Hours.correctionModal.textButton.approve": "Approve",
    "Hours.correctionModal.textButton.updateAndApprove": "Update and approve",
    "Hours.feedbackLoop.title.button": "Send schedule check",
    "Hours.externals": "External per shift",
    "Hours.externals.amountOfHours": "Hours",
    "Hours.externals.status.pending": "Check the hours",
    "Hours.externals.status.reviewed": "Approved",
    "Hours.externals.status.processed": "Checked",
    "Hours.externals.lastProposal": "Last proposal",
    "Hours.externals.previousProposals": "Previous proposals",
    "Hours.externals.creator": "Creator",
    "Hours.externals.billableHours": "Billable hours",
    "Hours.externals.creationDate": "Creation date",
    "Hours.creationDetails": "Creation details",
    "Hours.externals.approvalDate": "Approval date",
    "Hours.externals.rejectionDate": "Rejection date",
    "Hours.externals.status": "Status",
    "Hours.externals.currentProposal": "Current proposal",
    "Hours.externals.employeeName": "Employee name",
    "Hours.externals.newProposal": "New proposal",
    "Hours.externals.accept.confirmation": `You are about to accept {items, plural,
      one {# proposal}
      other {# proposals}    
    }, are you sure about executing this action?`,
    "Hours.externals.accept": "Accept proposals",
    "Hours.externals.accepted-success": "Proposal(s) accepted successfully",
    "Hours.externals.editBillableHours":
      "There are pending billable hours associated with this information. Update billable hours as well.",
    "Hours.externals.createBillableHours": "Create billable hours",
    "Hours.externals.currentBillableHours": "Current billable hours",
    "Hours.externals.newBillableHours": "New billable hours",
    "Hours.externals.sendBillableHours": "Send billable hours",
    "Single.totalWorked": "Total worked jobs",
    "Single.totalHours": "Total hours",
    "Single.plannedHours": "Planned",
    "Single.lastActive": "Last active",
    "Single.calendar": "Calendar",
    "Single.overview": "Overview",
    "Single.details": "Details",
    "Single.contract": "Contract",
    "Single.history": "History",
    "Single.media": "Media",
    "Single.comments": "Comments",
    "Single.hours-registration": "Hours registration",
    "Single.jobs": "Jobs",
    "Single.pools": "Pools",
    "Single.dateRegistered": "Date registered",
    "Single.payment": "Payment",
    "Single.candidates": "Candidates",
    "Details.general": "General",
    "Details.characteristics": "Characteristics",
    "Details.clothingSize": "Shirt size",
    "Details.length": "Height",
    "Details.shoeSize": "Shoe size",
    "Details.piercingsTattoo": "Piercings/Tattoo",
    "Details.no": "No",
    "Details.yes": "Yes",
    "Details.planIn": "Schedule",
    "Details.notVisible": "Yes, but not visible",
    "Details.travel": "Travel",
    "Details.publicTransport": "Public transport",
    "Details.pt": "Public transport",
    "Details.week": "Week",
    "Details.weekend": "Weekend",
    "Details.license": "License",
    "Details.car": "Car",
    "Details.notAlways": "Not always",
    "Details.normal": "Normal",
    "Details.discount": "Discount",
    "Details.sometimes": "Sometimes",
    "Details.unknown": "Unknown",
    "Details.education": "Education",
    "Details.hearAboutUs": "How did you hear about us?",
    "Details.friend": "Friend",
    "Details.socialMedia": "Social media",
    "Details.other": "Other",
    "Details.branch": "Function",
    "Details.trainings": "Trainings",
    "Details.qualifications": "Qualifications",
    "Details.languages": "Languages",
    "Details.languages.adjust": "Adjust the languages information",
    "Details.employeeLanguages": "Employee languages",
    "Details.employeeLanguages.addOne": "Add languages",
    "Details.information": "Information",
    "Details.payment": "Payment",
    "Details.description": "Description",
    "Details.briefing": "Briefing",
    "Details.questions": "Questions",
    "Details.numberOfQuestions": "Number of questions",
    "Details.evaluation": "Evaluation",
    "Details.evaluationLink": "Link",
    "Details.contact-person": "Contact person",
    "Details.no-evaluation": "Enter the evaluation link", // *****
    "Details.view": "View",
    "Details.documents": "Documents", // *****
    "Details.applications": "Applications", // *****
    "Details.invitations": "Invitations", // *****
    "Details.scheduled": "Schedules", // *****
    "Details.registered": "Registered", // *****
    "Details.team": "Team", // *****
    "Details.cancelled": "Cancelled", // *****
    "Details.proposed": "Proposed", // *****
    "Details.proposals": "Proposals", // *****
    "Details.customNumber": "Custom number",
    "Media.photo": "Profile photo",
    "Media.dates": `{date} at {start_time} to {end_time}`,
    "Media.filters.jobFullAddress": `Job full address`,
    "Creation.jobs.pool.predefined": "Predefined pools (optional)",
    /* The common strings are using text in different views. */
    "common.sharedType": "Shared type",
    "common.shared": "Shared",
    "common.combined": "combined",
    "common.internal": "internal",
    "common.all": "All",
    "common.doanloadAll": "Download All",
    "Common.downloadAll": "Download all",
    "Common.downloadMedia": "Download media",
    "Common.downloadMedia.declarationPhoto": "Workflow photo's",
    "Common.downloadMedia.publicTransport": "Receipts public transport",
    "Common.downloadMedia.otherCosts": "Receipts other costs",
    "Common.downloadMedia.all": "All types",
    "Common.downloadMedia.description": "Select the image types to download:",
    "Common.downloadAllBeta": "Try the new beta download!",
    "Common.downloadAll.confirmation": `You are about to download all the selected media types, are you sure about executing this action?`,
    "Common.downloadAll.success": `We are currently downloading your media, you will receive an e-mail and a notification when the file is ready`,
    "common.cancel": "Cancel",
    "Common.save": "Save",
    "Common.yes": "Yes",
    "Common.saveAndExit": "Save and exit",
    "Common.no": "No",
    "Common.cancel": "Cancel",
    "Common.saving": "Saving",
    "Common.savingChangesError": "Saving the changes was not successful",
    "Common.lastname": "Last Name",
    "Common.usedForPlanning": "Used for planning",
    "Common.notUsedForPlanning": "Not used for planning",
    "Common.shareable": "Shareable",
    "Common.notShareable": "Not shareable",
    "Common.name": "Name",
    "Common.city": "City",
    "Common.age": "Age",
    "Common.gender": "Gender",
    "Common.email": "E-mail",
    "Common.mailbox": "Notifications mailbox",
    "Common.noReply": "No reply e-mail",
    "Common.zipCode": "Zipcode",
    "Common.address": "Address",
    "Common.houseNumber": "House number",
    "Common.houseNumberExt": "Extension",
    "Common.postCode": "Post code",
    "Common.country": "Country",
    "Common.save-success": "Saved successfully",
    "Common.save-error": "Problems saving",
    "Common.try-later": "Please try again later",
    "Common.required": "This field is required",
    "Common.min": "The password must contain at least [number] characters",
    "Common.max": "This field should contain less than {number} characters",
    "Common.inputMinLength": `The field must contain at least {number} characters`,
    "Common.inputMaxLength": `The field must contain a maximum {number} characters`,
    "Common.inputFixedLenght": `The field must contain exactly {number} characters`,
    "Common.created-success": "Created successfully",
    "Common.created-error": "Error to create",
    "Common.maxLength": "Exceeds the maximum length",
    "Common.length": "Does not meet the required size",
    "Common.copyLink": "Copy link", // *****
    "Common.clickToCopy": "Click to copy in the clipboard", // *****
    "Common.copiedLink": "Link was copied to the clipboard", // *****
    "Common.load-error": "Error when load the data", // *****
    "Common.seeComments": "See comments",
    "Common.hideComments": "Hide comments",
    "Common.errorOccurred": "An error has occurred",
    "Common.exit": "Exit",
    "Common.addFilter": "Add filter",
    "Common.archive": "Archive",
    "Common.filters.resetAll": "Reset filters",
    "Common.edit": "Edit",
    "Common.editTime": "Edit time",
    "Common.delete": "Delete",
    "Common.next": "Next",
    "Common.duplicate": "Duplicate",
    "Common.viewJobs": "View Jobs",
    "Common.search": "Search",
    "Common.period": "Period",
    "Common.periodDays": "Period (days)",
    "Common.specifications": "Specifications",
    "Common.periodFrom": "Period from",
    "Common.periodTill": "Periode till",
    "Common.remove": "Remove",
    "Common.optional": "Optional",
    "Common.archived": "Archived",
    "Common.returned": "Item returned",
    "Common.noShow": "No-show",
    "Common.calledInSick": "Called in sick",
    "Common.undoCancellation": "Undo cancellation",
    "Common.stepBack": "Step back",
    "Common.watchOut": "Watch out!",
    "Common.export": "Export",
    "Common.exportAll": "Export all ({total})",
    "Common.exportAllNoTotal": "Export all",
    "Common.exportAllBeta": "Try the new beta export!",
    "Common.exportAll.confirmationAll": `You are about to export all the data, are you sure about executing this action?`,
    "Common.exportAll.confirmation": `You are about to export all the data ({total}), are you sure about executing this action?`,
    "Common.exportAll.success": `We are currently exporting your data, you will receive an e-mail and a notification when the file is ready`,
    "Common.exportPdf": "Print PDF",
    "Common.exportPdfExplain": "Print selection to pdf",
    "Common.exportPdfAll": "Print selection to PDF ({total})",
    "Common.exportPdfMessageAll":
      "You are about to print your current selection of ({total}) rows to PDF.",
    "Common.exportPdfMessage":
      "You will receive the download link to the pdf in your notifications tab, as soon as its ready.",
    "Common.woman": "Woman",
    "Common.women": "Woman",
    "Common.man": "Man",
    "Common.men": "Man",
    "Common.applied": "Applied",
    "Common.invited": "Invited",
    "Common.scheduled": "Scheduled",
    "Common.savePreset": "Save preset",
    "Common.unarchive": "Unarchive",
    "Common.loadMore": "Load more ({total})",
    "Common.seeProfile": "See profile",
    "Common.moreDetails": "More details",
    "Common.seeJob": "See job",
    "Common.noEmployeesScheduled": "No employees scheduled",
    "Common.noExternalsScheduled": "No external employees scheduled",
    "Common.noFreelancersScheduled": "No freelancers scheduled",
    "Common.noApplications": "There are no applications",
    "Common.noInvites": "There are no invites",
    "Common.noCancelledApplications": "No cancelled applications",
    "Common.noQualifiedCandidates": "No qualified candidates have been found",
    "Common.noEmployees": "No employees to show",
    "Common.noAppliedFreelancers": "No applied freelancers to show",
    "Common.noPlannedFreelancers": "No planned freelancers to show",
    "Common.noProjects": "No projects to show",
    "Common.noFreelancers": "No freelancers to show",
    "Common.noConnections": "No connections to show",
    "Common.noEmployeesProposed": "No employees proposed",
    "Common.hours": `{hours, plural,
      =0 { no hours }
      one { hour }
      other { hours } 
    }`,
    "Common.close": "Close",
    "Common.allDay": "All day",
    "Common.accept": "Accept",
    "Common.reject": "Reject",
    "Common.download": "Download",
    "Common.pagination":
      "{first, number}-{last, number} out of {total, number}",
    "Common.rebuildPagination":
        "out of",
    "Common.rebuildPaginationMultiple":
        "many",
    "Common.settings": "Settings",
    "Common.logged": "Logged in as",
    "Common.selected": "{items} selected",
    "Common.unsubscribe": "Unsubscribe",
    "Common.makeAnExport": "Make an export",
    "Common.details": "Details",
    "Common.Share": "Share",
    "Common.ShareJobs": "Share jobs",
    "Common.sendToMarketplace": "Send to the Marketplace",
    "Common.status.job_applied": "Job applied",
    "Common.status.job_accepted": "Job accepted",
    "Common.status.quiz_completed": "Quiz completed",
    "Common.status.quiz_complete": "Quiz completed",
    "Common.status.check_in": "Check in",
    "Common.status.check_in_location": "Check in location",
    "Common.status.upload_photo": "Upload photo",
    "Common.status.evaluation": "Evaluation",
    "Common.status.check_out": "Check out",
    "Common.status.hour_declaration": "Hour declaration",
    "Common.invalidStates.auto_canceled": "Auto cancelled",
    "Common.invalidStates.cancelled": "Cancelled",
    "Common.invalidStates.rejected": "Rejected",
    "Common.invalidStates.expired": "Expired",
    "Common.invalidStates.refused": "Refused",
    "Common.invalidStates.already_scheduled": "Already scheduled",
    "Common.invalidStates.unsubscribed": "Unsubscribed",
    "Common.invalidStates.sick": "Sick",
    "Common.invalidStates.no_show": "No show",
    "Common.invalidStates.cancelled_by_supplier": "Cancelled by supplier",
    "Common.invalidStates.cancelled_by_owner": "Cancelled by owner",
    "Common.goToNewTables": "Do you want to try the new tables?",
    "ClientsModal.title": "Export Clients",
    "Common.goToNewPage": "Do you want to try the new page?",
    "Common.goToOldDesigns": "Do you still need the old designs?",
    "Common.basicDetails": "Basic details",
    "Common.finance": "Finance",
    "Common.yesterday": "Yesterday",
    "Common.today": "Today",
    "Common.adjustAvailability": "Adjust availability",
    "Common.todayAt": "Today at {hour}",
    "Common.contacted": "Contacted",
    "Common.customNumber": "Custom number",
    "Common.verify": "Verify",
    "Common.uploadImageOnly": "You can only upload an image file!",
    "Common.uploadSmallerImage": "Image must be smaller than 2MB!",
    "Common.categories": "Functions",
    "Common.functions": "Functions",
    "Common.seeDetails": "See details",
    "Common.shouldBeNumber": "This field should be a number",
    "Common.permissions": "Permissions",
    "Common.noProposals": "No proposals registered",
    "Common.addingFiles": "Adding files",
    "Common.optionalInformation": "Optional information",
    "Common.introduction": "Introduction",
    "Common.seeMore": "See more",
    "Common.EmployeeInformation": "Employee information",
    "Common.noBillableHours": "No billable hours",
    "Common.allSpotsFilled": "All the job spots are filled",
    "Common.uploadedFiles": "Uploaded files",
    "Common.documents": "Additional documents (maximum 5)",
    "Common.documentsDescription":
      "Upload additional PDF documents here (resume & certificates etc.).",
    "Common.nameAlreadyTaken": "The name is already taken",
    "Common.jobOwner": "Job owner",
    "Common.employeeOwner": "Employee owner",
    "Common.exceptionStateName": "Exception state",
    "Common.exceptionStateId": "Exception state ID",
    "Common.invalidEmail": "An invalid email was provided",
    "Common.invalidGender":
      "An invalid gender was provided (The option to be 'men' or 'women')",
    "Common.invalidCar":
      "An invalid car was provided (The option to be 'Yes', 'No', 'Not always' or 'sometimes')",
    "Common.invalidLicense":
      "An invalid license was provided (The option to be 'Yes' or 'No')",
    "Common.invalidPublicTransportCard":
      "An invalid public transport card was provided (The option to be 'No', 'Week', 'Weekend', 'Discount' or 'Normal')",
    "Common.invalidFirstName":
      "The first name field is mandatory and it isn't to be empty",
    "Common.invalidCustomNumber":
      "The custom number field must contain a maximum of 50 characters",
    "Common.invalidPhoneNumber":
      "The phone number field isn't a number correct remember to add only numbers or also add the landcode number at the beginning, starting with '+'",
    "Common.invalidHouseNumber": "The house number field isn't a number",
    "Common.invalidContract":
      "The contract name is not registered in the system",
    "Common.invalidFormatStartDateContract":
      "The start date format for the contract is invalid",
    "Common.invalidFormatEndDateContract":
      "The end date format for the contract is invalid",
    "Common.invalidDependeDateContract":
      "The contract start date field and contract end field is required if the contract name is added",
    "Common.invalidFormatBirthDate": "The birth date format is invalid",
    "Common.invalidFormatExpiryDate": "The expiry date format of ID is invalid",
    "Common.invalidFormatRebateDate":
      "The wage tax rebate date format is invalid",
    "Common.invalidRebate":
      "The wage tax rebate is invalid (The option to be 'Yes' or 'No')",
    "Common.invalidShoeSize": "The shoe size number field isn't a number",
    "Common.invalidSocialNumber":
      "The social number (BSN) field isn't a number",
    "Common.invalidEducation":
      "An invalid education was provided (The option to be HBO, WO, MBO, No or None)",
    "Common.invalidShirtSize":
      "An invalid shirt size was provided (The option to be S, M, L, XL or XXL )",
    "Common.invalidLanguages":
      "The languages field is mandatory and it isn't to be empty, as well, the languages that are included, must exist in the system (Fleks supports languages like 'nl' or 'en')",
    "Common.invalidJobCategories":
      "The following job categorie(s) could not be found in the system: {categories}",
    "Common.invalidFreeFields":
      "The following free field(s) have values that don't correspond to those preset in the system: {freefields}",
    "Common.invalidHeight": "The height field isn't a number",
    "Common.invalidBankNumber":
      "The bank acc number field must be 18 characters",
    "Common.invalidPiercingsTattos":
      "An invalid piercings and tattoos was provided (The option to be 'Yes', 'No', 'Yes but not visible' or 'unknown')",
    "Common.knowledgebase": "Knowledgebase",
    "Common.AssignToTheJob": "Assign to the job",
    "Common.AssignToTheJobAndExit": "Assign to the job and exit",
    "Common.kmDriven": "Km driven",
    "Common.searchProject": "Search project",
    "Common.reset2fa": "Reset 2FA Method",
    "Common.reset2fa.message.confirm":
      "You are about to reset the authentication method of this user. He will have to set up a new authentication method the next time he tries to sign in to Fleks. Do you want to continue?",
    "Common.additionalDates": "Additional dates",
    "Common.sharedWith": "Shared with",
    "Common.empty": " ",
    "HoursModal.title": "Export Hours",
    "JobsModal.title": "Export Jobs",
    "ProjectsModal.title": "Export Projects",
    "shiftsModal.title": "Export Shifts",
    "DownloadModal.content":
      "Your current selection will be exported as Excel file.",
    "DownloadModal.numberItems": "This is the number of items for export: ",
    "DownloadModal.customExportHere": "Custom your export here:",
    "DownloadModal.fullTable": "Default export",
    "DownloadModal.fullData": "Advance export",
    "DownloadModal.salaryRun": "Salary run",
    "DownloadModal.simplifiedSalaryRun": "Simplified salary run",
    "DownloadModal.totalSalary": "Total salary",
    "DownloadModal.invoiceInformation": "Invoice information",
    "DownloadModal.attendanceList": "Attendance list",
    "JobsModal.projectName": "project name",
    "JobsModal.projectNumber": "project number",
    "JobsModal.startDate": "Start date",
    "JobsModal.endDate": "End date",
    "JobsModal.city": "City",
    "JobsModal.address": "Address",
    "JobsModal.name": "Name",
    "JobsModal.prefix": "Prefix",
    "JobsModal.lastName": "Lastname",
    "JobsModal.phoneNumber": "Phone number",
    "JobsModal.ShoeSize": "Shoe size",
    "JobsModal.height": "Height",
    "JobsModal.note": "Note",
    "ProjectsModal.projectName": "Project name",
    "ProjectsModal.clientName": "Client name",
    "ProjectsModal.jobCategoryName": "Function name",
    "ProjectsModal.jobsNumber": "Number of jobs",
    "ProjectsModal.jobsPending": "Number of pending jobs",
    "ProjectsModal.projectManager": "Project manager",
    "ProjectsModal.startDate": "Start date",
    "ProjectsModal.endDate": "End date",
    "ProjectsModal.projectNumber": "Project number",
    "Contract.no": "No",
    "Contract.yes": "Yes",
    "Contract.essentials": "Essentials",
    "Contract.bankInformation": "Bank information",
    "Contract.tax": "Payroll tax / Income tax",
    "Contract.contracts": "Contracts",
    "Contract.active": "Active?",
    "Contract.signed": "Signed?",
    "Contract.contractPerWeek": "Contract per week",
    "Contract.contractDays": "Contract days",
    "Contract.howContractsWork": "How does this work?",
    "Contract.howContractsWorkDescription":
      "If you have agreed with an employee that he or she must work a standard number of hours per year/month/week, you can use this setting. Fleks calculates with contract hours per week as standard.",
    "Contract.contractHours": "Contract hours",
    "Contract.minContractHours": "Contract hours (min)",
    "Contract.maxContractHours": "Contract hours (max)",
    "Contract.noValidContract": "No contract",
    "Contract.contractHours.hours": `{hours, plural,
      one { # hour }
      other { # hours }
    }`,
    "Contract.isSigned": "Is signed?",
    "Contract.type": "Contract type", // *****
    "Contract.duration-start": "Start date", // *****
    "Contract.duration-end": "End date", // *****
    "Contract.month": "Month (s)", // *****
    "Contract.new": "New Contract", // *****
    "Contract.noHoursContract": "Zero-hours contract", // *****
    "Contracts.no-contract": "With no contract", // *****
    "Contracts.upcoming-to-expire": "Coming to expire", // *****
    "Contracts.no-signed": "Without signed", // *****
    "Contracts.documents": "Documents", // *****
    "Contracts.hourPerWeek.onlyNumbers":
      "This field only accepts numeric characters",
    "NewProjects.addLocation": "Add a location",
    "NewProjects.addBriefing": "Add the briefing document",
    "NewProjects.addQuiz": "Add a quiz",
    "NewProjects.addDeclaration": "Adjust the declaration form",
    "NewProjects.salesPrice": "Set sales rates",
    "NewProjects.chooseAClient": "Choose a client",
    "NewProjects.projectDescription": "Project description",
    "NewProjects.addAnImage": "Add an image",
    "NewJobs.name": "Name",
    "NewJobs.applicants": "Applicants",
    "NewJobs.address": "Address",
    "NewJobs.city": "City",
    "NewJobs.time": "Time",
    "NewJobs.startDate": "Start date",
    "NewJobs.criteria1": "Critera 1",
    "NewJobs.criteria2": "Criteria 2",
    "NewJobs.workflow": "Workflow template",
    "NewJobs.isSuccessful": "Job created",
    "NewJob.createJobs": "Create jobs",
    "NewJob.ongoingProjects": "1. Ongoing projects",
    "NewJob.information": "2. Information",
    "NewJob.overview": "3. Overview",
    "NewStandardJob.information": "1. Information",
    "NewStandardJob.overview": "2. Overview",
    "NewJob.next": "Select project",
    "NewJob.selectProject": "Select an ongoing project",
    "NewJob.filterByProject": "Filter by project name",
    "NewJob.filterByClient": "Filter by client name",
    "NewJob.addToConcepts": "Add to concepts",
    "NewJob.concepts.list": "Concepts ({amount})",
    "NewJob.concepts.feedback":
      "You have added {amount} concepts, please scroll down to check them out.",
    "NewJob.clearForm": "Clear",
    "NewJob.useStandardJob": "Use a standard job",
    "NewJob.useStandardJob.description": "Choose a standard to create the job",
    "NewJob.useStandardJob.selectClient": "Select a client",
    "NewJob.useStandardJob.selectAStandardJob": "Select a standard job",
    "NewJob.useStandardJob.useThis": "Use this standard job",
    "NewJob.addTeamInstructions": "Add team instructions",
    "NewJob.addExtraDocument": "Add an extra document",
    "NewJob.addAutoScheduled": "Approval settings",
    "Step2.fillIn": "Fill in",
    "Step2.general": "General",
    "Step2.startDate": "Start date:",
    "Step2.startEndTime": "Start- and End time:",
    "Step2.name": "Name:",
    "Step2.repeatJob": "Repeat job:",
    "Step2.notRepeat": "Do not repeat",
    "Step2.daily": "Daily",
    "Step2.weekly": "Weekly",
    "Step2.monthly": "Monthly",
    "Step2.customize": "Customize",
    "Step2.amount": "Amount of employees:",
    "Step2.criteria": "Criteria",
    "Step2.quiz": "Quiz",
    "Step2.reserve": "Reserve",
    "Step2.add": "Add",
    "Step2.newJobs": "Jobs to be created: ",
    "Step2.newStandardJobs": "Standard jobs to be created: ",
    "Step2.addAnotherJobs": "You can still add another %j jobs",
    "Step2.addAnotherStandardJobs":
      "You can still add another %j standard jobs",
    "Step2.createdJobs": "Created jobs:",
    "Step2.createdStandardJobs": "Created standard jobs:",
    "Step2.previous": "Previous",
    "Step2.upload": "Upload",
    "Step2.selectCriteria": "Select criteria",
    "Step2.zipCode": "Zip code",
    "Step2.state": "City",
    "Step2.city": "City & zipcode",
    "Step2.ext": "Ext",
    "Step2.number": "Number",
    "Step2.streetName": "Street name",
    "Step2.address": "Address",
    "Step2.location": "Location",
    "Step2.totalJobs": "Total jobs: %t (Max 200)",
    "Step2.searchAddress": "Search your address on the map",
    "Step2.wrong-date": "The start date have to be of this year", // *****
    "Step2.wrong-startTime": "Start time have to greater than 00:00", // ****
    "Step2.wrong-endTime": "End time have to greater than 00:00", // ****
    "Step2.empty-address": "No address given", // ****
    "Step2.empty-city": "No city given", // ****
    "Step2.empty-required-fields": "This field is required: House number", // ****
    "Step2.empty-workflow": "No workflow template given", // ****
    "Step2.empty-category": "No job function given", // ****
    "Step2.empty-name": "No name given", // ****
    "Step2.importInformation":
      "For the document to be read correctly, keep in mind that you must have the header in the first row. The fields are as follows, in the following order: Start date, Start time, End date, End time, Number of employees, City, Street, Number, Number extension, Zipcode.",
    "Step2.importWithSheets": "Import with sheets",
    "Step2.importJobs": "Import jobs",
    "Step2.edit": "Edit",
    "Step2.importWarning.times": `An invalid time information was provided`,
    "Step2.importWarning.dates": `An invalid date was provided`,
    "Step2.importWarning.jobCategory":
      "The specified job function could not be found in the system",
    "Step2.importWarning.invalidProject":
      "A project with the specified ID could not be found in the system",
    "Step2.importWarning.pools":
      "The following pool(s) could not be found in the system: {pools}",
    "Step2.endDate": "This is a nightshift",
    "Step2.useWorkflow": "Use workflow",
    "Step2.useWorkflow.false":
      "This job will not ask the employees to confirm every step in the workflow",
    "Step2.useWorkflow.true": "",
    "Step2.useQuiz": "Quiz",
    "Step2.useQuiz.false":
      "This job will not ask the employee to fill in the quiz specified in the project",
    "Step2.useQuiz.true": "",
    "Step2.useReserve": "Reserve",
    "Step2.useReserve.true":
      "This is a reserve job. The employee is only required to check in for the job",
    "Step2.useReserve.false": "This is not a reserve job.",
    "Step2.requiredFieldsToUpload":
      "Make sure all jobs have the required fields filled.",
    "Step2.empty-documents":
      "Make sure all documents have name and description.",
    "NewEmployee.conceptList.textButtonUploadEmployee": "Upload employee(s)",
    "RepeatWindow.defaultRepeated": "It is repeated every day.",
    "RepeatWindow.repeat": "Repeat this job",
    "RepeatWindow.every": "Every:",
    "RepeatWindow.day": "Day",
    "RepeatWindow.week": "Week",
    "RepeatWindow.month": "Month",
    "RepeatWindow.mon": "Mon",
    "RepeatWindow.tue": "Tue",
    "RepeatWindow.wed": "Wed",
    "RepeatWindow.thu": "Thu",
    "RepeatWindow.fri": "Fri",
    "RepeatWindow.sat": "Sat",
    "RepeatWindow.sun": "Sun",
    "RepeatWindow.monday": "monday",
    "RepeatWindow.tuesday": "tuesday",
    "RepeatWindow.wednesday": "wednesday",
    "RepeatWindow.thursday": "thursday",
    "RepeatWindow.friday": "friday",
    "RepeatWindow.saturday": "saturday",
    "RepeatWindow.sunday": "sunday",
    "RepeatWindow.ends": "Ends:",
    "RepeatWindow.never": "Never",
    "RepeatWindow.occurrences": "occurrences",
    "RepeatWindow.on": "On",
    "RepeatWindow.done": "Done",
    "RepeatWindow.repeatedEvery": "It is repeated every",
    "RepeatWindow.and": "and",
    "RepeatWindow.occurs": "Occurs",
    "RepeatWindow.times": "times",
    "RepeatWindow.until": "Until",
    "RepeatWindow.after": "After",
    "Step3.overview": "Overview",
    "Step3.youCreated": "You’ve created",
    "Step3.jobsFor": "jobs for",
    "Step3.standardJobsFor": "standard jobs for",
    "Step3.done": "Done",
    "employee.confirmSaveTitle": "Save changes" /*****/,
    "employee.confirmSave": "Do you wish to save changes?" /*****/,
    "Settings.shareType": "Share type",
    "Settings.shareType.internal": "Internal only",
    "Settings.shareType.external": "External only",
    "Settings.shareType.combined": "Internal & External",
    "Settings.selectExportTemplate": "Select export template",
    "Settings.selectRateCard": "Select rate card",
    "ExportModal.surchargeExport": "Surcharge export",
    "Settings.profileSettings": "Profile settings",
    "Settings.actions": "Actions", // *****
    "Settings.name": "Name",
    "Settings.phoneNumber": "Phone number",
    "Settings.accountSettings": "Account settings",
    "Settings.email": "E-mail",
    "Settings.password": "Password",
    "Settings.users": "Users",
    "Settings.materiallist": "Materials",//
    "Settings.materiallist.new": "New",//
    "Settings.materiallist.addMaterial": "New material", //
    "Settings.materiallist.changeMaterial": "Change Material",//
    "Settings.materiallist.categoryField": "Category", //,
    "Settings.materiallist.materialName": "Material Name",//
    "Settings.materiallist.review": "Review", //
    "Settings.materiallist.addCategory": "Add a new category",//
    "Settings.materiallist.saveMaterial": "Save",//
    "Settings.materiallist.cancelMaterial": "Cancel",//
    "Settings.materiallist.remark":"Remark", //,
    "Settings.materiallist.deleteTitle":"Delete Material",//
    "Settings.materiallist.deleteFirstTextSplitFirst": "Are you sure you want to remove",//
    "Settings.materiallist.deleteFirstTextSplitSecond": "?",//
    "Settings.materiallist.deleteSecondText": "This cannot be undone.",//
    "Settings.materiallist.deleteNotification": "Material {item} is deleted successfully",//
    "Settings.materiallist.addNotification": "Material {item} is added successfully",//
    "Settings.materiallist.editNotification": "Material {item} is edited successfully",//
    "Settings.materiallist.example": "E.g. t-shirt",
    "Settings.workflow": "Workflow",
    "Settings.defaultWorkflow": "Default workflow",
    "Settings.salesPrice": "Sales Price",
    "Settings.workflow.new": "Create a new workflow",
    "Settings.declarationSettings.tittle": "Declaration settings", // ****
    "Settings.declarationSettings.description":
      "The users and employees are able to modify the working hours of a job in steps of <b class='avenir-next-demi-bold'>{step} minutes</b>", // ****
    "Settings.declarationSettings.edit.description":
      "The users and employees are able to modify the working hours of a job in steps of:", // ****
    "Settings.declarationSettings.edit.minutes": "minutes", // ****
    "Settings.declarationSettings.edit.timeInterval": "Time Interval", // ****
    "Settings.securitySettings.tittle": "Security", // ****
    "Settings.securitySettings.description":
      "The two factor authentication is currently <b class='avenir-next-demi-bold'>{status}</b> for the dashboard users of your company.", // ****
    "Settings.securitySettings.edit.2fa.tittle": "Two-factor authentication", // ****
    "Settings.securitySettings.edit.2fa.description":
      "Do you want to enable the Two Factor Authentication for all the dashboard users of your company?", // ****
    "Settings.securitySettings.enabled": "enabled", // ****
    "Settings.securitySettings.disabled": "disabled", // ****
    "Settings.standardDeclarationForm": "Standard declaration form",
    "Settings.standardDeclarationForm.description":
      "This declaration form can be used as default for my projects.",
    "Settings.sharedDeclarationForm": "Shared declaration form",
    "Settings.sharedDeclarationForm.description":
      "This declaration form can be used for received job requests.",
    "Settings.connections": "Connections",
    "Settings.connectionName": "Connection name",
    "Settings.activeSince": "Active since",
    "Settings.jobsShared": "Jobs shared",
    "Settings.status": "Status",
    "Settings.connnectionActive": "Active",
    "Settings.connnectionDeactivated": "Deactivated",
    "Settings.activationConfirm": `Are you sure that you want to activate your connection? <b>You will be able to share jobs with this connection again.</b>`,
    "Settings.deactivationConfirm": `Are you sure you want to deactivate your connection? You will not be able to send job requests to the connection anymore. <b>All the jobs which you have already shared and scheduled with the connection will still be valid.</b>`,
    "Settings.contractSettings": "Contract settings",
    "Settings.categorySettings": "Category settings",
    "Settings.sectors": "Functions",
    "Settings.qualifications": "Qualifications",
    "Settings.trainings": "Trainings",
    "Settings.contractName": "Contract name",
    "Settings.workflowName": "Template name",
    "Settings.workflowSteps": "Steps",
    "Settings.paymentType": "Payment type",
    "Settings.paidOnAge": "Paid on age",
    "Settings.fixedAmountPer": "Fixed amount per",
    "Settings.fixedAmount": "Fixed amount per",
    "Settings.rate": "Rate",
    "Settings.cancel": "Cancel",
    "Settings.create": "Create",
    "Settings.update": "Update",
    "Settings.documents": "Documents",
    "Settings.hourlyRate.sub-header":
      "The rates applicable when age dependent rate is selected for a project",
    "Settings.hourlyRate": "Age dependent hourly rate",
    "Settings.from": "From",
    "Settings.still": "Still",
    "Settings.hourlyRatesCreate":
      "Add hourly rate with a start date _ end date",
    "Settings.hourlyRatesUpdate": "Update the hourly rate for selected period",
    "Settings.appointments": "Contact moments",
    "Settings.appointments.description":
      "Make the options from which applicants can choose",
    "Settings.appointment.location": "Location",
    "Settings.appointment.dateTime": "Date & time (optional)",
    "Settings.appointment.options": "Actions",
    "Settings.appointment.saved": "New appointment created successfully",
    "Settings.appointment.updated": "Appointment updated successfully",
    "Settings.appointment.archived": "Appointment archived successfully",
    "Settings.tenant.title": "Basic details",
    "Settings.tenant.description":
      "Add the company information here, these will be visible for the employees in the mobile app on the information page.",
    "Settings.tenant.hours": "Your package",
    "Settings.tenant.hoursDone": "{currentHours} out of {planHours} hours",
    "Settings.tenant.remainingMonth": "Remaining until {endOfMonth}",
    "Settings.tenant.checkOutPlans": "Check out other packages",
    "Settings.page.company": "Company info app",
    "Settings.page.company.desc":
      "This page will allow you to add the company information, which will be visible for the employees in the mobile app on the information page. Besides, here you will be able to modify information related to the company documents.",
    "Settings.page.company.adjust": "Adjust the basic details information",
    "Settings.page.hr": "HR & Finance",
    "Settings.page.poole": "Poole settings",
    "Settings.page.users": "Users",
    "Settings.page.admin.changePassword": "Change the password? Click here",
    "Settings.page.admin": "Admin",
    "Settings.page.admin.deactivate": "Deactivate",
    "Settings.page.admin.deactivateSuccess":
      "The dashboard user has been deactivated",
    "Settings.page.admin.activateSuccess":
      "The dashboard user has been activated",
    "Settings.page.admin.archiveSuccess":
      "The workflow template has been archived",
    "Settings.page.templates": "Templates",
    "Settings.page.sharing": "Sharing jobs",
    "Settings.page.sharing.desc":
      "This page will allow you to modify the settings corresponding to the sharing jobs functionality. Therefore, here you will be able to modify information related to your connections and payment information for external employees.",
    "Settings.page.sharing.connections.pooleCombinations": "Poole combinations",
    "Settings.page.sharing.connections.pooleCombinations.saved":
      "Saved pool combinations",
    "Settings.page.sharing.connections.linkedPool": "Linked pool",
    "Settings.page.sharing.connections.employees": "Employee reach",
    "Settings.page.sharing.connections.lastModified": "Last modified on",
    "Settings.page.sharing.connections.activeSince": `Connection active since {date}`,
    "Settings.page.sharing.connections.adjusting": `You are adjusting the pool combination for the {function} function.`,
    "Settings.page.sharing.connections.deleted":
      "The combination has been deleted",
    "Settings.page.sharing.connections.edited":
      "The combination has been edited",
    "Settings.page.sharing.connections.deleteConfirmation": `You are about to delete this combination, are you sure about executing this action?`,
    "Settings.page.rateCards": "Rate cards",
    "Settings.page.rateCards.ratecards.hoursCrossed": "Hours crossed",
    "Settings.page.rateCards.ratecards.insufficientTime": "Insufficient time",
    "Settings.rateCards": "Rate cards",
    "Settings.rateCards.new": "Create a new rate card",
    "Settings.rateCards.update": "Update rate card",
    "Settings.rateCards.name": "Rate card name",
    "Settings.rateCards.percentage": "Percentage",
    "Settings.page.rateCards.surcharges": "Surcharges",
    "Settings.page.rateCards.surcharges.creation": "Surcharge creation",
    "Settings.page.rateCards.surcharges.modification": "Surcharge modification",
    "Settings.page.rateCards.surcharges.new": "New surcharge",
    "Settings.page.rateCards.surcharges.create": "Create the surcharge",
    "Settings.page.rateCards.surcharges.modify": "Modify the surcharge",
    "Settings.page.rateCards.surcharges.name": "Surcharge name",
    "Settings.page.rateCards.surcharges.percentage": "Percentage",
    "Settings.page.rateCards.holidays": "Holidays",
    "Settings.page.rateCards.holidays.creation": "Holiday creation",
    "Settings.page.rateCards.holidays.modification": "Holiday modification",
    "Settings.page.rateCards.holidays.new": "New holiday",
    "Settings.page.rateCards.holidays.create": "Create the holiday",
    "Settings.page.rateCards.holidays.modify": "Modify the holiday",
    "Settings.page.rateCards.holidays.name": "Holiday name",
    "Settings.page.rateCards.holidays.date": "Date",
    "Settings.page.reports": "Reports",
    "Settings.page.reports.title": "Export templates",
    "Settings.page.reports.desc":
      "Make the settings yourself for the reports that are required for your organization.",
    "Settings.page.reports.new": "Add new template",
    "Settings.page.reports.item.columns": "columns",
    "Settings.pools": "Poole manager",
    "Settings.pools.name": "Name",
    "Settings.pools.total": "Number of employees",
    "Settings.pools.actions": "Actions",
    "Settings.pools.subText":
      "Create and update you pools here, these can be assigned to specific projects.",
    "Settings.pools.create": "New custom filter",
    "Settings.pools.edit": "Edit custom filter",
    "Settings.pools.archive.confirmation":
      "Are you sure? If you do this, the poule will be removed from the projects and jobs with it.",
    "Settings.afasImport": "Import the AFAS data",
    "Settings.afasIntegration": "AFAS Integration",
    "Settings.afasIntegration.clientIDs": "Client IDs",
    "Settings.afasIntegration.clientIDs.note":
      "Note: Client IDs must be separated by commas",
    "Settings.afasIntegration.clientIDs.spaces":
      "The Client IDs should not have any spaces between them.",
    "Settings.afasIntegration.settingTitle": "AFAS Employees",
    "Settings.afasIntegration.settingDescription":
      "Specify if you only want to receive employees imported from AFAS from external companies.",
    "Settings.afasIntegration.active":
      "You will only receive employees that were imported from AFAS",
    "Settings.afasIntegration.inactive":
      "You will receive every type of employee created by other companies",
    "Settings.generateConnection": "New connection",
    "Settings.generateConnection.title": "Create a connection",
    "Settings.generateConnection.description":
      "Copy the link below and send this to the company you want to create a connection with!",
    "Settings.username": "User name",
    "Settings.functions": "Functions",
    "Settings.functions.subText": "Create and update your functions here.",
    "Settings.functions.create": "Add a function",
    "Settings.functions.edit": "Edit function",
    "Settings.functions.name": "Name",
    "Settings.functions.description": "Description",
    "Settings.functions.actions": "Actions",
    "Settings.notificationsAndEmails": "Notifications and Emails",
    "Settings.organizationAdmin": "Admin organization",
    "Settings.organizationAdmin.desc":
      "In this page you will be able to manage the dashboard users, permissions and also create workflows",
    "Settings.userRoles": "User permissions",
    "Settings.userRoles.admin": "Admin",
    "Settings.userRoles.admin.desc": "All permissions",
    "Settings.userRoles.manager": "Manager",
    "Settings.userRoles.manager.desc":
      "These users are only able to use the Fleks manager application to manage the employees at the job location",
    "Settings.userRoles.planner": "Planner",
    "Settings.userRoles.planner.desc":
      "Users able to manage the planning and declarations. However, they are not able to read the employee's details",
    "Settings.userRoles.client": "Client",
    "Settings.userRoles.client.desc":
      "Let your clients create jobs and schedule employees linked to their pool.",
    "Settings.accounts.active": "Active accounts",
    "Settings.accounts.inactive": "Deactivated accounts",
    "Settings.accounts.new": "New user",
    "Settings.accounts.creation": "Account creation",
    "Settings.accounts.modification": "Account modification",
    "Settings.accounts.new.description":
      "Create a system user for the dashboard. Give a user permissions to protect information. The user will receive an email with the password.",
    "Settings.accounts.create": "Create the user",
    "Settings.accounts.modify": "Modify the user",
    "Settings.accounts.2fa.messageResetSuccess":
      "The 2FA method for the user has been successfully reset.",
    "Settings.accounts.2fa.messageResetError":
      "There was a problem resetting the 2FA method for the user.",
    "Settings.accounts.optional.clients": "You can optionally add a client to a planner user. In this way, the planner can only plan for the selected client(s)",
    "Settings.freeFields.add": "Add free field",
    "Settings.freeFields": "Free fields",
    "Settings.jobFreeFields": "Job free fields",
    "Settings.freeFields.new": "New field",
    "Settings.freeFields.creation": "Field creation",
    "Settings.freeFields.modification": "Field modification",
    "Settings.freeFields.create": "Create the field",
    "Settings.freeFields.modify": "Modify the field",
    "Settings.freeFields.fillableSwitch":
      "Fillable by employees and applicants",
    "Settings.freeFields.visibleSwitch":
      "Visible by employees and applicants",
    "Settings.freeFields.mandatorySwitch":
      "Is mandatory",
    "Settings.freeFields.checkboxEnableValuesOption":
      "Specify the values of this field",
    "Settings.freeFields.inputEnableValuesOption": "Values",
    "Settings.freeFields.jobContactPerson": "Job contact person",
    "Settings.exceptionState.tittle": "Exception states",
    "Settings.exceptionState.add": "Add exception state",
    "Settings.exceptionState.add.id": "ID",
    "Settings.exceptionState.creation": "Exception state creation",
    "Settings.exceptionState.modification": "Exception state modification",
    "Settings.exceptionState.create": "Create exception state",
    "Settings.exceptionState.modify": "Modify exception state",
    "Settings.exceptionState.switchBreakInvoiced.title": "Break is invoiced",
    "Settings.exceptionState.switchBreakPayedOut.title": "Break is payed out",
    "Settings.exceptionState.switchWorkingHoursInvoiced.title":
      "Working hours are invoiced",
    "Settings.exceptionState.switchWorkingHoursPayedOut.title":
      "Working hours are payed out",
    "Settings.exceptionState.switchCostsInvoiced.title": "Costs are invoiced",
    "Settings.exceptionState.switchCostsPayedOut.title": "Costs are payed out",
    "Settings.tenantSettings": "Tenant settings",
    "Settings.tenantSettings.billable hours": "Billable hours",
    "Settings.tenantSettings.createBillableHoursDescription":
      "The billable hours allow you to specify the number of hours that will be paid for a job independently of the working hours. Enabling this option will allow you to see a table where you can manage the billable hours for your shared jobs.",
    "Settings.tenantSettings.applyBreakToBillableHours":
      "Apply break to billable hours",
    "Settings.salaryProfiles": "Salary profiles",
    "Settings.salaryProfiles.add": "Create a new salary profile",
    "Settings.salaryProfiles.activeFrom": "Active from {start_date}",
    "Settings.salaryProfiles.create": "Create salary profile",
    "Settings.salaryProfiles.update": "Update salary profile",
    "Settings.salaryProfiles.name": "Name",
    "Settings.salaryProfiles.customId": "ID",
    "Settings.salaryProfiles.description":
      "Create a set of hourly rates. These can be linked to a contract, position or direct to an employee.",
    "Settings.salaryProfiles.maximumAgeMessage":
      "Employees who are <b>{age}</b> or older receive an hourly wage of <b>€{wage}</b>",
    "Settings.salaryProfiles.addAgeRatePair":
      "You must add at least one age / rate pair for each rate period",
    "Settings.salaryProfiles.newRatePeriod": "Add new rate period",
    "Settings.salaryProfiles.deleteRatePeriod":
      "You are about to delete this rate period, are you sure about executing this action?",
    "Settings.availabilityPeriodLimit": "Availability period limit",
    "Settings.availabilityPeriodLimit.adjust":
      "Adjust the availability period limit",
    "Settings.availabilityPeriodLimit.subtitle":
      "This is a BETA functionality that will be available in the new mobile app",
    "Setting.pooleSettings.confirmDelete":
      "Are you sure about this? Existing jobs will keep this category. However, it will be removed from the employees and pooles that have it.",
    "Settings.organizationSettings": "Organization settings",
    "Settings.organizationSettings.adjust": "Adjust the organization settings",
    "Settings.organizationSettings.subtitle": "First KM not compensated",
    "Settings.organizationSettings.labelKM": "Distance (KM)",
    "Setting.acceptingHours.card.title": "Accepting hours",
    "Setting.acceptingHours.card.subtitle":
      "Select who is responsible for the hours. The other party can not edit the hours from the other party.",
    "Setting.acceptingHours.card.titleAdjust": "Adjust accepting hours",
    "Setting.acceptingHours.card.switchDisable":
      "The owner of the hours is my organization",
    "Setting.acceptingHours.card.switchEnable":
      "The owner of the hours is the owner of the external employees",
    "Setting.autoScheduledEmployee.card.title": "Automatic proposals",
    "Setting.autoScheduledEmployee.card.subtitle":
      "Specify if you want your employees to be automatically proposed to the jobs shared with your company.",
    "Setting.autoScheduledEmployee.card.titleAdjust":
      "Adjust automatic proposals",
    "Setting.autoScheduledEmployee.card.switchEnable":
      "Your employees will be automatically proposed for jobs shared with your company.",
    "Setting.autoScheduledEmployee.card.switchDisable":
      "Your employees will not be automatically proposed for jobs shared with your company.",
    "EditTenantData.name": "Company name",
    "EditTenantData.description": "Company description",
    "EditTenantData.email": "Info email",
    "EditTenantData.phone": "Phonenumber",
    "EditTenantData.mobile": "Mobile",
    "EditTenantData.logo": "Logo",
    "EditTenantData.updatedError": "We had problems trying update company info", // *****
    "CreateAppointment.city": "City",
    "CreateAppointment.city.required": "This field is required",
    "CreateAppointment.city.max": "City should have less than 100 characters",
    "CreateAppointment.street": "Street",
    "CreateAppointment.street.required": "This field is required",
    "CreateAppointment.street.max":
      "Street should have less than 100 characters",
    "CreateAppointment.houseNumber": "House number",
    "CreateAppointment.houseNumber.max":
      "House number should have less that 10 characters",
    "CreateAppointment.houseNumberExt": "House number extension",
    "CreateAppointment.houseNumberExt.max":
      "House number extension should have less that 15 characters",
    "CreateAppointment.zipCode": "Zip code",
    "CreateAppointment.zipCode.max":
      "Zip code should have less that 6 characters",
    "CreateAppointment.host": "Host",
    "CreateAppointment.datetime": "Date & time (optional)",
    "HourlyRates.dateRange": "Select a date range",
    "HourlyRates.newRate": "Add new age and rate",
    "HourlyRates.created": "Hourly rate was created successfuly", // *****
    "HourlyRates.updated": "Hourly rate was updated successfuly", // *****
    "HourlyRates.age": "Age",
    "HourlyRates.rate": "Hourly rate",
    "HourlyRates.start_date": "Start date",
    "HourlyRates.end_date": "End date",
    "HourlyRates.no-data": "Not any hourly rate to show",
    "HourlyRates.age-duplicated": "This age has already been added", // *****
    "HourlyRates.minAge": "The minimum required age is 16.", // *****
    "EmployeeList.selectCancellation": "Select cancellation",
    "EmployeeList.unDoCancellation": "Undo cancellation",
    "EmployeeList.cancelJob": "Cancel job",
    "EmployeeList.reject": "Reject",
    "EmployeeList.noShow": "No-show",
    "EmployeeList.calledOff": "Called off",
    "EmployeeList.expired": "Expired",
    "EmployeeList.archive": "Archive",
    "EmployeeList.setException": "Set exception",
    "EmployeeList.calculateTravelExpenses": "Calculate travel expenses",
    "EmployeeList.edit": "Edit",
    "EmployeeList.workingHours": "Working hours",
    "EmployeeList.extra": "Extra",
    "EmployeeList.breakHours": "Break hours",
    "EmployeeList.travelTime": "Travel time",
    "EmployeeList.publicTransport": "Public transport",
    "EmployeeList.other": "Other",
    "EmployeeList.schedule": "Schedule",
    "EmployeeList.distance": "Distance",
    "EmployeeList.search": "Search",
    "EmployeeList.open": "Open",
    "EmployeeList.since": "since",
    "EmployeeList.onStandby": "On standby",
    "EmployeeList.activate": "Activate",
    "EmployeeList.reserve": "reserve",
    "EmployeeList.checkedIn": "Checked in",
    "EmployeeList.atLocation": "at location",
    "EmployeeList.photos": "Photos",
    "EmployeeList.areTaken": "are taken",
    "EmployeeList.checkOut": "checked out",
    "EmployeeList.hours": "Hours",
    "EmployeeList.areSent": "are sent",
    "EmployeeList.quiz": "Quiz",
    "EmployeeList.unarchive": "Un-archive",
    "EmployeeList.notChosen": "Not chosen",
    "EmployeeList.jobAccepted": "Job accepted",
    "EmployeeList.evaluation": "Evaluation",
    "EmployeeList.disabledButtonEdit":
      "This is an external employee from: {tenantName}. You are not able to edit information about this shift.",
    "DetailedPlanner.allJobs": "All jobs",
    "DetailedPlanner.plannedJobs": "Planned jobs",
    "Planner.header.filters": "Filter the shown cards",
    "Planner.header.settings": "Planner settings",
    "Planner.header.newJob": "Create a new job",
    "Planner.newJobModal.title": "Job creation",
    "Planner.newJobModal.date": "Date",
    "Planner.newJobModal.client": "Client",
    "Planner.newJobModal.project": "Project",
    "Planner.newJobModal.jobDetails": "Job details",
    "Planner.newJobModal.stepOneCompleted.desc": `You are creating a job for <b>{date}</b> for the project <b>{project_name}</b> to <b>{client_name}</b>`,
    "Planner.newJobModal.stepOneCompleted.adjust": "Adjust this information",
    "Planner.newJobModal.stepOneCompleted.adjust.bankInformation":
      "Adjust bank information",
    "Planner.newJobModal.details": "Job details",
    "Planner.newJobModal.standardJob": "Standard job (optional)",
    "Planner.newJobModal.jobCategory": "Job function",
    "Planner.newJobModal.pools": "Pools (optional)",
    "Planner.newJobModal.street": "Street",
    "Planner.newJobModal.houseNumber": "House number",
    "Planner.newJobModal.additionalInfo": "Additional info (optional)",
    "Planner.newJobModal.postalCode": "Postal code",
    "Planner.newJobModal.city": "City",
    "Planner.newJobModal.startTime": "Start-time",
    "Planner.newJobModal.endTime": "End-time",
    "Planner.newJobModal.available": "Available position",
    "Planner.newJobModal.workflow": "Workflow template",
    "Planner.newJobModal.createJob": "Create Job",
    "Planner.newJobModal.jobCreatedSuccess": "The job has been created",
    "Planner.title": "Planner",
    "Planner.Week.today": "Today",
    "Planner.Week.week": "Week",
    "Planner.noJobs": "No jobs on this day",
    "Planner.stats.positions": "positions",
    "Planner.Details.jobLocation": "No specific location given",
    "Planner.Details.jobInformation": "Job information",
    "Planner.Details.scheduled": "Scheduled",
    "Planner.Details.registered": "Registered",
    "Planner.Details.candidates": "Candidates",
    "Planner.Details.extensiveSearch": "Extensive search",
    "Planner.Details.search": "Search..",
    "Planner.Details.showMore": "Show more",
    "Planner.Details.seeAll": "See all",
    "Planner.Details.employees": "Employees",
    "Planner.Details.noJobSelected": "No job selected yet",
    "Planner.Details.selectAJob":
      "Select a job in the planner. To get detailed information about the job.",
    "Planner.Details.invited": "Invited",
    "Planner.Details.clusterInformation": "Cluster information",
    "Planner.fullJobs": "Include full jobs",
    "Planner.jobBecomeFull": "Job's spot are filled.",
    "Planner.filters.client": "Client name",
    "Planner.filters.project": "Project name",
    "Planner.filters.startDate": "Start date",
    "Planner.filters.endDate": "End date",
    "Planner.filters.projectManager": "Project manager",
    "Planner.filters.typeOfWork": "Function",
    "Planner.filters.week": "Week",
    "Planner.filters.city": "Full address",
    "Planner.filters.isFull": "Include full jobs",
    "Planner.filters.cluster": "Cluster",
    "Planner.clusterView": "Clusters",
    "Planner.closeDetails": "Close details",
    "Planner.settings": "Set planner",
    "Planner.settings.back": "Return to the planner",
    "Planner.settings.cancel": "Cancel",
    "Planner.settings.save": "Save changes",
    "Planner.settings.setUp": "Set up your planner",
    "Planner.settings.setUp.desc":
      "These are your planner settings. Adjust the following information to better suit your preferences.",
    "Planner.settings.jobCluster": "Job card clustering",
    "Planner.settings.jobCluster.desc":
      "Choose if you want to cluster your jobs. When the clustering is turned on, the jobs with the same client, project and function will get clustered.",
    "Planner.settings.jobCluster.toggleAction": "Group my jobs",
    "Planner.settings.jobCluster.notApplied": "Your jobs are not clustered.",
    "Planner.settings.jobCluster.applied": "Your jobs are clustered.",
    "Planner.settings.fullJobs": "Filled jobs",
    "Planner.settings.fullJobs.desc":
      "Turn this option on if you want to see all of the filled jobs in the planner.",
    "Planner.settings.fullJobs.toggleAction": "Show filled jobs",
    "Planner.settings.fullJobs.notApplied":
      "Filled jobs are not being displayed.",
    "Planner.settings.fullJobs.applied": "Filled jobs are being displayed.",
    "Planner.settings.sortCandidates": "Sort qualified candidates",
    "Planner.settings.sortCandidates.desc":
      "Choose how you want to sort your qualified candidates in the sidebar.",
    "Planner.settings.sortCandidates.availability":
      "Always sort by availability as standard",
    "Planner.settings.sortCandidates.distance": "Distance",
    "Planner.settings.sortCandidates.hoursWorked":
      "Weekly hours / Contract hours", // *****
    "Planner.settings.sortCandidates.timesWorked": "Experience at the client", // *****
    "Planner.settings.jobCardInformation": "Job cards information and grouping",
    "Planner.settings.jobCardInformation.desc":
      "Select which information you want to see in your job cards and whether the services should be grouped. The more elements are checked, the larger the job card becomes.",
    "Planner.settings.jobCardInformation.cardLooks":
      "This is what your service cards will look like",
    "Planner.settings.customizeCandidates": "Customize my candidates",
    "Planner.settings.customizeCandidates.desc":
      "Choose the candidates that you want to see for each one of the jobs you will plan!",
    "Planner.settings.customizeCandidates.applied": "Show all the candidates",
    "Planner.settings.customizeCandidates.notApplied":
      "Show all the candidates",
    "Planner.settings.customizeCandidates.excludeUsers":
      "Exclude the users that:",
    "Planner.settings.customizeCandidates.noValidContract":
      "Have no valid contract",
    "Planner.settings.customizeCandidates.invitedToAnotherJob":
      "Have been invited to a different job",
    "Planner.settings.customizeCandidates.scheduledToAnotherJob":
      "Have been scheduled to a different job",
    "Planner.goToOld": "Do you still need the old planner?!",
    "Planner.jobCard.pendingSpots": "Pending spots ({pendingSpots})",
    "Planner.jobCard.totalSchedules": `{totalSchedules, plural,
        =0 { No schedules }
        other { # planning in }
      }`,
    "Planner.jobCard.full": "Stuffed up",
    "Planner.Details": "Details",
    "Planner.Details.close": "Close sidebar",
    "Planner.Employees.planned": "Planned",
    "Planner.Employees.pending": "Pending",
    "Planner.Employees.proposed": "Proposed",
    "Planner.Employees.proposals": "Proposals",
    "Planner.pending.registered": "Registered",
    "Planner.pending.invited": "Invited",
    "Planner.pending.canceled": "Cancelled",
    "Planner.pending.sick": "Sick",
    "Planner.pending.noShow": "No-show",
    "Planner.employees.internals": "Internals",
    "Planner.employees.externals": "Externals",
    "Planner.Employees.incoming": "Incoming employees", // *****
    "Planner.Employees.incoming.count": "Incoming employees - {total}", // *****
    "Planner.Employees.candidates": "Candidates - {total}",
    "Planner.candidates.search": "Search employee",
    "Planner.clusterDetails": "Cluster details",
    "Planner.clusteredJobs": "Clustered Jobs",
    "Planner.clusteredJobs.planClusterIn": "Plan cluster in",
    "Planner.clusteredJobs.overview.contains": "This cluster contains",
    "Planner.clusteredJobs.overview.contains.totalJobs": `jobs, from which`,
    "Planner.clusteredJobs.overview.contains.sharedJobs": `{totalSharedJobs, plural,
      one {is shared}
      other {are shared}
    }`,
    "Planner.clusteredJobs.overview.furthermore": "Furthermore we count",
    "Planner.clusteredJobs.overview.furthermore.openSpots":
      "open positions with",
    "Planner.clusteredJobs.overview.furthermore.applications": "applications",
    "Planner.clusteredJobs.overview.furthermore.invitations":
      "sent invitations",
    "Planner.startPlanning": "Start planning",
    "Planner.jobDetails": "Job details",
    "Planner.openPositions": `{pendingSpots, plural,
      0= { Available positions }
      one { # Available position }
      other { # Available positions }
    }`,
    "Planner.selectedEmployees": "Selected",
    "Planner.selectedEmployeesInfo": "Deselect the employees here",
    "Planner.selectEmployees": "Select the employees to be scheduled",
    "Planner.selectProposed": "Select the proposed to be scheduled",
    "Planner.selectFreelancers": "Select the freelancers to be scheduled",
    "Planner.applicationDate": "Application date",
    "Planner.selectEmployeesToBeProposed":
      "Select the employees to be proposed",
    "Planner.afasIntegration.notPropose":
      "You can’t propose/schedule employees that have not been imported from AFAS to this company",
    "EmployeeCard.goBack": "Back to information",
    "Planner.noData.header": "No jobs to select",
    "Planner.noData.callToAction":
      "Return to the planner and select a week with jobs.",
    "EmployeeCard.reschedule.attentionText": "Please note!",
    "EmployeeCard.reschedule.warning": " The employee is already scheduled at this time.",
    "EmployeeCard.reschedule.reason": "Do you want to cancel the old job and schedule the employee for the selected job?",
    "EmployeeCard.reschedule.overlappingText": "The selected job overlaps with the job below:",
    "EmployeeCard.cancellation.reason": "Why are you cancelling this shift?",
    "EmployeeCard.cancellation.reason.noShow": "The candidate did not show up",
    "EmployeeCard.cancellation.reason.sick": "The candidate called in sick",
    "EmployeeCard.cancellation.reason.noReason": "Other reason",
    "EmployeeCard.cancellation.reason.include": "Include in the salary run",
    "EmployeeCard.cancellation.beforeJobStarts":
      "You’re cancelling the shift {expiration} before the job starts.",
    "EmployeeCard.cancellation.reason.save": "Save reason",
    "EmployeeCard.cancellation.confirmation.cancellationPast":
      "Are you sure you want to cancel this shift? You can still cancel this shift without consenquences until {date} at {hour}.",
    "EmployeeCard.cancellation.confirmation.cancellationNoPast":
      "You’re cancelling inside the cancellation policy date. The cancellation date was {date} at {hour}. ",
    "EmployeeCard.cancellation.confirmation.save": "Confirm cancellation",
    "EmployeeCard.cancellation.confirmation.noCancellationPolicy":
      "You are able to freely cancel this job since there is no cancellation policy date set for it",
    "Scheduler.header.settings": "Preferences",
    "Scheduler.settings": "Scheduler settings",
    "Scheduler.settings.back": "Go back to the scheduler",
    "Scheduler.settings.explication.title": "Your scheduler settings",
    "Scheduler.settings.explication.description":
      "These are your scheduler settings, please adjust the information according to your preferences",
    "Scheduler.settings.job.title": "Card's information",
    "Scheduler.settings.job.description":
      "Please, select the information that you want to see in the cards. Consider that the more information you choose the bigger the cards will be",
    "Scheduler.settings.job.preview":
      "This is what your service cards will look like.",
    "Scheduler.settings.order.title": "Employees sorting",
    "Scheduler.settings.order.description":
      "How should your employees be sorted in the scheduler?",
    "Scheduler.settings.orderAtoZ": "Name A - Z",
    "Scheduler.settings.orderZtoA": "Name Z - A",
    "Scheduler.settings.orderUnplannedHoursAsc": "Unplanned hours - Ascending",
    "Scheduler.settings.orderUnplannedHoursDesc":
      "Unplanned hours - Descending",
    "Scheduler.settings.orderZipCodeAsc": "Zipcode - Ascending",
    "Scheduler.settings.orderZipCodeDesc": "Zipcode - Descending",
    "Scheduler.filters.isAvailableOn": "Is available on",
    "Scheduler.filters.isAvailableOn.and": "And",
    "Scheduler.filters.isAvailableOn.or": "Or",
    "Notifications.header": "Notifications",
    "Notifications.footer.clearAll": "Clear all notifications",
    "Notifications.footer.clearAllPopconfirm":
      "Are you sure you want to clear all notifications?",
    "Notifications.info.close": "Close view",
    "Notifications.info.goToPage": "Go to page",
    "Notifications.info.download": "Download now",
    "Notifications.delete": "Delete",
    "Notifications.finqle.approveError": `You cannot set the hours to processed from fleks because the hours and costs must first be processed in Finqle, visit app.connections.fleks.works to look up the status of the hours.`,
    "Notifications.finqle.acceptError": `Your organization does not have a ratecard selected yet which should be considered for the connection with finqle, contact fleks.`,
    "Notifications.deleteSuccess": "The notification has been deleted",
    "Planner.Connections": "Connections",
    "Candidates.available.title": "Available",
    "Candidates.partlyAvailable.title": "Partly available",
    "Candidates.notAvailable.title": "Not available",
    "Candidates.noData": "Not any candidate",
    "Candidates.notUpdated.title": "Not updated",
    "Candidates.errors.load":
      "[section_name]: We had an error loading the candidates",
    "AvailabilityPercentage.partlyAvailable":
      "[first_name] has indicated to be available from [start_time] to [end_time]",
    "AvailabilityPercentage.contact": "Telephone number: [phone_number]",
    "AvailabilityPercentage.wholeDay":
      "[first_name] has indicated to be available all day.",
    "AvailabilityPercentage.wholeDayNotAvailable":
      "[first_name] has indicated that they will not be available all day.",
    "AvailabilityPercentage.alreadyScheduled":
      "[first_name] is already scheduled for a job",
    "MiniWorkFlow.operationNotAllowed": "Operation not allowed",
    "Shifts.all": "All",
    "Shifts.confirm-archive-checked": "",
    "Shifts.title": "Shifts",
    "Shifts.isCompleted": "Shift completed?",
    "Shifts.activeStatus": "Active step",
    "Shifts.uncompletedStatus": "Uncompleted step",
    "Shifts.validOnly": "Valid only",
    "Shifts.invalidState": "Invalid state",
    "Shifts.upcoming": "Upcoming",
    "Shifts.emptyState.title": "No employees are scheduled",
    "Shifts.emptyState.description":
      "Return to the employees page and add a new employee.",
    "Shifts.inProgress": "In progress",
    "Shifts.workflowStatus": "Workflow status",
    "Shifts.toOldDesign": "Do you still need the old tables?",
    "Shifts.goToTablePreferences": "Go to table preferences",
    "Shifts.advancedFilters.close": "Close sidebar",
    "Shifts.advancedFilters.title": "All filters",
    "Shifts.archived": "{items} shifts archived",
    "Shifts.client": "Client",
    "Shifts.clientId": "Client ID",
    "Shifts.telephoneNumber": "Telephone number",
    "Shifts.project": "Project",
    "Shifts.time": "Time",
    "Shifts.workflowModalTitle": "Confirm check in and check out time",
    "Shifts.worflowLocationButton": "Confirm",
    "Shifts.checkIn": "Check in",
    "Shifts.checkOut": "Check out",
    "Shifts.archive.confirmation.material": "You are about to return a given item, are you sure?",
    "Shifts.archive.confirmation": `You are about to {archive, select,
      true {unarchive}
      false {archive}
    } {items, plural,
      one {# shift}
      other {# shifts}    
    }, are you sure about executing this action?`,
    "Shift.workflow.confirmation":
      "Are you sure you want to complete this step?",
    "Shift.jobAccepted": "Job accepted",
    "Shift.quizComplete": "Quiz completed",
    "Shift.checkIn": "Left home",
    "Shift.checkInLocation": "At location",
    "Shift.uploadPhoto": "Uploaded pictures",
    "Shift.checkOut": "Checked out",
    "Shift.hourDeclaration": "Sent declaration",
    "Shift.evaluation": "Evaluation",
    "Shift.exceptionState.modal.title": "Add exception state",
    "Shift.exceptionState.modal.select": "Select exception state",
    "Shift.updateJobApplications.allUpdated":
      "All the job applications were updated",
    "Shift.updateJobApplications.updated": "the job application was updated",
    "Shift.updateJobApplications.updated.exceptionState":
      "The exception state was updated correctly",
    "Shift.updateJobApplications.notUpdated.exceptionState":
      "It was not possible to update the exception state",
    "Shift.updateJobApplications.allNotUpdated":
      "It was not possible to update the job applications",
    "Shift.updateJobApplications.oneNotUpdated":
      "One or more items were not updated, please check the job applications",
    "Shift.updateJobApplications.onePartiallyUpdated":
      "One or more items were partially updated, please check the job applications",
    "Shift.updateJobApplications.selectedSharedJobApp.withMe": `Updating the information is not possible, you have selected {jobApplicationsSharedWithMe, plural,
      one { # Job Application }
      other { # Job Applications }
    } shared with you. In order to do the update you must select either own job applications but not job applications shared with you.`,
    "Shift.assignMaterials":"Assign materials",
    "Shift.assignMaterials.selectMaterials": "Select materials",
    "Shift.assignMaterials.givenMaterials": "Given materials",
    "Shift.assignMaterials.materialName": "Material name",
    "Shift.assignMaterials.remark": "Remark",
    "Shift.assignMaterials.404.primary": "You currently haven't assigned any materials to the employee.",
    "Shift.assignMaterials.404.secondary": "Select materials to the left to assign.",
    "Shift.assignMaterials.footer.giveMaterials": "Connect and secure materials",
    "Shift.assignMaterials.footer.completeStep": "Complete workflow step",
    "Shift.assignMaterials.search": "Search material or category",
    "Shift.assignMaterials.category": "Category",
    "Shift.gatherMaterials":"Submit materials",
    "Shift.gatherMaterials.handed":"Handed in",
    "Shift.gatherMaterials.notHanded":"Not submitted",
    "Shift.gatherMaterials.complete": "Confirm and complete step",
    "Shift.gatherMaterial.returned": "All items returned and complete",
    "Shift.gatherMaterials.404":"Cross off the above items when they are have not been submitted",
    "Shift.assignMaterials.footer.handed.confirm": "Confirm submitted materials",
    "Shift.managerSignOff": "Signature", 
    "Shift.managerSignOff.footer.handed.confirm": "Confirm signature",
    "CreateCategory.categoryCreated": "The new function was created correctly",
    "CreateCategory.newCategory": "New function",
    "CreateCategory.notCreated": "The function could not be created",
    "CreateCategory.createCategory": "Create function",
    "CreateCategory.inputName": "Please input a name",
    "CreateCategory.name": "Name",
    "CreateCategory.pleaseSelectType": "Please select a type",
    "CreateCategory.selectType": "Select a type",
    "CreateCategory.regular": "Regular",
    "CreateCategory.trainings": "Trainings",
    "CreateCategory.qualifications": "Qualifications",
    "CreateCategory.addDescription": "Please add a description",
    "CreateCategory.description": "Description",
    "CreateCategory.categoryType": "Function type",
    "CreateCategory.salesPrice": "Sales price",
    "CreateCategory.categoryName": "Name",
    "CreateCategory.salaryProfile": "Salary profile",
    "CreateCategory.selectSalaryProfile": "Select a salary profile",
    "CreateCategory.mandatoryExpirationDate":
      "The category must be entered including an end date.",
    "CreateCategory.idCategory": "The category must include a ID when adding to an employee",
    "CreateCategory.optionalHourlyRate": "I want to optionally add an hourly rate when added to an employee",
    "CreateCategory.expirationDateTitle": "Until when is this category valid?",
    "CreateCategory.title": "Add category specifications",
    "CreateCategory.idMandatory": "Add ID number",
    "CreateCategory.hourlyRates": "Exception hourly rate (optional)",
    "CreateCategory.idMandatory.placeholder": "ID number",
    "CreateCategory.updateCategory": "Update function",
    "CreateCategory.categoryUpdated": "The function was updated successfully",
    "CreateCategory.deleteCategory": "Delete function",
    "CreateCategory.categoryDeleted": "The function was delete succesfully",
    "CreateCategory.notDeleted": "The function could not be deleted",
    "CreateCategory.notUpdated": "The function could not be updated",
    "CreateContract.createContract": "Create a contract", // *****
    "CreateContract.updateContract": "Update contract", // *****
    "CreateContract.contractName": "Contract name", // *****
    "CreateContract.inputName": "Is required a contract name", // *****
    "CreateContract.contractType": "Contract type", // *****
    "CreateContract.inputType": "Is required a contract type", // *****
    "CreateContract.zzp": "ZZP", // *****
    "CreateContract.ma": "MA", // *****
    "CreateContract.sis": "SIS", // *****
    "CreateContract.ghost": "GHOST", // *****
    "CreateContract.paymentType": "Payment type", // *****
    "CreateContract.inputPayment": "Is required a payment type", // *****
    "CreateContract.amount": "Rate", // *****
    "CreateContract.amountIn": "Rate in €", // *****
    "CreateContract.inputAmount": "Is required a amount", // *****
    "CreateContract.fixedRate": "Fixed amount", // *****
    "CreateContract.hourlyRate": "Fixed amount per hour", // *****
    "CreateContract.ageRate": "Paid on age", // *****
    "CreateContract.createdContract": "New Contract created successfully", // *****
    "CreateContract.updatedContract": "Contract updated successfully", // *****
    "CreateContract.error": "Was not possible create o update a new contract", // *****
    "CreateContract.maxWeekHours": "Max weekly hours", // *****
    "CreateContract.contract": "Max weekly hours", // *****
    "CreateCategory.id": "ID Category",
    "CreateDashboardUser.createDashboardUser": "Create system user",
    "CreateDashboardUser.email": "E-mail",
    "CreateDashboardUser.inputEmail": "Please input a email",
    "CreateDashboardUser.username": "Username",
    "CreateDashboardUser.inputUsername": "Please put a username",
    "CreateDashboardUser.password": "Password",
    "CreateDashboardUser.inputPassword": "Please input a password",
    "CreateDashboardUser.inputPasswordLength": "Minimum 8 characters",
    "CreateDashboardUser.firstName": "First name",
    "CreateDashboardUser.inputFirstName": "Please input a first name",
    "CreateDashboardUser.prefix": "Prefix",
    "CreateDashboardUser.inputPrefix": "Please input a prefix",
    "CreateDashboardUser.lastName": "Last name",
    "CreateDashboardUser.inputLastName": "Please input a last name",
    "CreateDashboardUser.phoneNumber": "Phone number",
    "CreateDashboardUser.invalidEmail": "The input is not valid E-mail!",
    "CreateDashboardUser.invalidNumber": "The input is not valid number!",
    "CreateDashboardUser.newDashboardUser": "New system user",
    "CreateDashboardUser.created":
      "The dashboard user was created successfully",
    "CreateDashboardUser.notCreated": `{errorCode, select,
      sr_user_11 {An active account with this email already exists.}
      sr_user_10 {Username already exists.}
      other {The dashboard user could not be created}
    }`,
    "CreateDashboardUser.minLength":
      "The username must contain at least 4 characters",
    "CreateWorkflowTemplate.createWorkflowTemplate": "Select workflow elements",
    "CreateWorkflowTemplate.description":
      "The workflow defines which steps the employee need to do during the job. Create your own workflow here, you can choose between one of these during job creation.",
    "CreateWorkflowTemplate.name": "Workflow name",
    "CreateWorkflowTemplate.job_applied": "Job applied",
    "CreateWorkflowTemplate.job_accepted": "Job accepted",
    "CreateWorkflowTemplate.quiz_complete": "Quiz",
    "CreateWorkflowTemplate.quiz_completeDescription":
      "With the quiz, you are able to check if the employee has read the briefing or job details properly.",
    "CreateWorkflowTemplate.item_distribution": "Material distribution", 
    "CreateWorkflowTemplate.item_distributionDescription": "Keep track of which materials have been issued to the employee. All materials added under Settings > Admin will be included.", 
    "CreateWorkflowTemplate.item_gathering": "Material gathering", 
    "CreateWorkflowTemplate.item_gatheringDescription": "Keep track of which materials have been issued to the employee. All materials added under Settings > Admin will be included.", 
    "CreateWorkflowTemplate.check_in": "Check in",
    "CreateWorkflowTemplate.check_inDescription":
      "Let the employee check in 2 hours before the job is starting, so that you know they did not forget their job!",
    "CreateWorkflowTemplate.check_in_location": "Check in location",
    "CreateWorkflowTemplate.check_in_locationDescription":
      "Let the employee check in at the work location.",
    "CreateWorkflowTemplate.check_in_qr": "Check in with QR",
    "CreateWorkflowTemplate.assignMaterials": "Assign materials",
    "CreateWorkflowTemplate.check_in_qrDescription":
      "Allow the users to check in the job with a QR code",
    "CreateWorkflowTemplate.upload_photo": "Upload photos",
    "CreateWorkflowTemplate.upload_photoDescription":
      "Let the employee upload pictures during job day!",
    "CreateWorkflowTemplate.evaluation": "Evaluation",
    "CreateWorkflowTemplate.evaluationDescription":
      "Let the employee evaluate their own work.",
    "CreateWorkflowTemplate.check_out": "Check out",
    "CreateWorkflowTemplate.check_outDescription":
      "Let the employee check out, after the job is done.",
    "CreateWorkflowTemplate.check_out_qr": "Check out with QR",
    "CreateWorkflowTemplate.check_out_qrDescription":
      "Allow the users to check out of the job with a QR code",
    "CreateWorkflowTemplate.hour_declaration": "Hour declaration",
    "CreateWorkflowTemplate.hour_declarationDescription":
      "Let the employee send a declaration after the job is ended.",
    "CreateWorkflowTemplate.add": "Add workflow",
    "CreateWorkflowTemplate.isReserved": "Is reserved",
    "CreateWorkflowTemplate.message.invalidOrder": "Error on the steps order",
    "CreateWorkflowTemplate.signing_off": "Digital signature from the manager app",
    "CreateWorkflowTemplate.signing_offDescription": "In the manager app, an employee can sign his name to check out, no more discussion about working hours!",
    "CreateWorkflowTemplate.employee_signing_off":"Digital signature employee app",
    "CreateWorkflowTemplate.employee_signing_offDescription": "In the employee app, a manager can sign on location and immediately check the hours worked.",
    "CreateWorkflowTemplate.message.invalidOrder.description":
      "The check in step cannot be first than the check out step.",
    "CreateWorkflowTemplate.messageTooltipStep.completedAt":
      "Completed at: {completedAt}",
    "CreateWorkflowTemplate.messageTooltipStep.completedBy":
      "Completed by: {completedBy}",
    "CreateWorkflowTemplate.messageTooltipStep.completedByEmployee":
      "Completed by: Employee",
    "CreateWorkflowTemplate.messageTooltipStep.distance": "Distance from location : {distance}km",
    "CreateWorkflowTemplate.messageTooltipStep.locationButton": "View location",
    "AddPoolToShareJob.poolSpecification": "Pool specification",
    "AddPoolToShareJob.description": `You have accepted <b>{job_number}</b> job(s). Do you want to publish the job(s) to a specific group or to all of your employees?`,
    "AddPoolToShareJob.tip":
      "Tip: To be sure that the right employees will apply for your job, add a pool specification.",
    "AddPoolToShareJob.selectYourPool": "Select your pool",
    "AddPoolToShareJob.radio1":
      "I want to publish the job(s) to a specific group",
    "AddPoolToShareJob.radio1Info":
      "The pool specifcation can always be changed on the job detail page.",
    "AddPoolToShareJob.radio2":
      "I want to publish the job(s) to all of my employees",
    "AddPoolToShareJob.save": "Save my choice",
    "AddPoolToShareJob.rememberCombination": "Remember combination",
    "AddPoolToShareJob.rememberCombinationWarning":
      "All the job requests with from {tenant_owner} with the function {job_category} will be automatically accepted and assigned to the selected pool",
    "SaveCombinations.modal.title": "Job verification",
    "SaveCombinations.modal.description":
      "Please, verify that the information to be published in the Marketplace meets your requirements.",
    "SaveCombinations.modal.employersName": "Employer's name",
    "SaveCombinations.modal.checkbox1":
      "Always accept this type of jobs from this employer",
    "SaveCombinations.modal.checkbox2":
      "Ask me to review the job the next time",
    "SaveCombinations.save": "Save my choice",
    "ShareJobs.title": "Send job request",
    "ShareJobs.description":
      "You have selected <b>{job_number}</b> jobs. Select the connections that you want to send the job requests to.",
    "ShareJobs.searchPlaceholder": "Search for a connection",
    "ShareJobs.counterNotification":
      "You have selected <b>{connections_number}</b> connections.",
    "ShareJobs.sendRequests": "Send my requests",
    "ShareJobs.alreadyShared": "Already shared",
    "ShareJobs.updateJobCancellationDate": "Update the job cancellation date",
    "ShareJobs.updateAllTheJobsCancellationDate":
      "Update all the jobs cancellation date",
    "ShareJobs.cancellationDate": "Cancellation date",
    "ShareJobs.cancellationDate.optional": "Cancellation date (Optional)",
    "ShareJobs.employeePropose.optional":
      "Number of employees to propose (Optional)",
    "ShareJobs.employeePropose.error":
      "The number of employees to be proposed must be less than or equal to {jobRequestMax}",
    "ShareJobs.employeePropose.update": "Update proposed employees",
    "ShareJobs.employeePropose.notificationSuccess":
      "Employee request successfully updated",
    "ShareJobs.cancellationDate.flexible": "Flexible - 1 day (24h)",
    "ShareJobs.cancellationDate.standard": "Standard - 2 days (48h)",
    "ShareJobs.cancellationDate.strict": "Strict - 3 days (72h)",
    "ShareJobs.cancellationDate.superstrict": "Superstrict - 7 days (168h)",
    "ShareJobs.marketplace.title": `Publish your jobs on {tenant_name}`,
    "ShareJobs.marketplace.description": `In order to send this job to {tenant_name}, we need you to fill the following information:`,
    "ShareJobs.marketplace.shareJobWith": "Publish jobs on {tenant_name}",
    "ShareJobs.xJobsSharedFromY": "{x} out of {y} jobs are already shared",
    "ShareJobs.publishOnX.description":
      "Publish your jobs on the {tenant_name} freelance platform.",
    "ShareJobs.publishOnX.selectedSharedJob.disabled": `Publishing is not possible, you have selected {jobsSharedWithMarketplace, plural,
        one { # job }
        other { # jobs }
      } already published on {tenant_name}`,
    "ProfileForm.editProfile": "Edit Profile",
    "ProfileForm.firstNameInput": "Is required a first name",
    "ProfileForm.lastNameInput": "Is required a last name", 
    "ProfileForm.phoneNumberInput": "Is required a phone number",
    "ProfileForm.updated-success": "Profile was updated successfully",
    "ProfileForm.updated-error": "Profile was not updated",
    "ChangePassword.changePassword": "Change password",
    "ChangePassword.description":
      "Press the button below to send a reset-code to %e. Fill in the code you received below along with your new password to change your password.",
    "ChangePassword.sendCode": "Send code",
    "ChangePassword.code": "Code",
    "ChangePassword.inputCode": "Please input the code",
    "ChangePassword.password": "Password",
    "ChangePassword.inputPassword": "Please input a password",
    "ChangePassword.confirmPassword": "Confirm password",
    "ChangePassword.inputConfirmPassword": "Please confirm your password",
    "ChangePassword.codeSentSuccess":
      "The code has been sent: check your mail!",
    "ChangePassword.codeSentError": "The code could not be sent, try again",
    "ChangePassword.changed": "Password updated correctly",
    "ChangePassword.noChanged":
      "Something has happened, remember that the password must be at least 8 characters and the confirmation must match",
    "Employee.Overview.addJob": "Add job",
    "Employee.Overview.client": "Client",
    "Employee.Overview.schedule": "Schedule",
    "Employee.Overview.jobsFor": "Nearest Open Jobs for",
    "Employee.Overview.jobsFor.empty": "No near jobs",
    "Employee.Overview.scheduleEmployee": "Schedule employee",
    "Employee.Overview.scheduleSuccess": "Employee scheduling successfully",
    "Employee.Overview.partialSchedule": "Some employees could not schedule",
    "Employee.Overview.acceptedProposal":
      "The proposal was scheduled successfully",
    "Employee.Overview.rejectedProposal":
      "The proposal was rejected successfully",
    "Overview.sick": "Sick",
    "Overview.hadContact": "Had contact",
    "Overview.rejected": "Rejected",
    "Overview.noShow": "No show",
    "Overview.contacted": "Contacted",
    "Overview.wantToWork": "Wants to work",
    "Overview.unavailability": "Unavailable",
    "Overview.availability": "Availability",
    "Overview.available": "Available",
    "Overview.availability.default": "has not defined availability",
    "Overview.unavailability.text": "Is unavailable",
    "Overview.availability.error":
      "An error occurred trying to change [employee_name]'s availability",
    "Overview.availability.success":
      "[employee_name]'s availability was changed successfully",
    "Overview.availability.description":
      "Availability changed by dashboard user",
    "Overview.availabilityRange": "[name] [availability] from [from] to [to]",
    "Overview.status": "Status",
    "Overview.dashboardChange": "Availability changed by dashboard",
    "Overview.scheduledRange": "Scheduled from [from] to [to]",
    "Overview.updateAvailability": "Update availability",
    "Overview.errorOccurred": "An error has occurred",
    "ScheduleButton.hasContract":
      "%n has a contract offer valid during %jsd but it has not yet been signed.",
    "ScheduleButton.hasNoContract": "%n has no contract valid during %jsd.",
    "ScheduleButton.unavailable": "%n is set to unavailable on %jsd.",
    "ScheduleButton.hasAJob":
      "%n has already been scheduled for %c, %a, from %jst until %jet.",
    "ScheduleButton.hasMultiJobs":
      "%n has been scheduled for multiple jobs on %jsd.",
    "ScheduleButton.areYouSure": "Are you sure?",
    "ScheduleButton.Reschedule": "Reschedule",
    "ScheduleButton.Invite": "Invite",
    "ScheduleButton.Invite.disabled":
      "One or more of the selected jobs have already happened, meaning that the employee can’t be invited",
    "ScheduleButton.errorSendInvitation": "Error sending the invitation",
    "ScheduleButton.confirm": "Confirm",
    "ScheduleButton.addExpireTime": "Expiration time",
    "ProposeButton.errorProposing": "Error proposing employee",
    "Archive.clients": "Clients",
    "Archive.projects": "Projects",
    "Archive.jobs": "Jobs",
    "Archive.shifts": "Shifts",
    "Archive.employees": "Employees",
    "Archive.applicants": "Applicants",
    "Archive.hours": "Hours",
    "Archive.emptyState.title": "You haven't archived anything yet",
    "Archive.emptyState.description":
      "Click on the archive icon to archive your files.",
    "Archive.emptyState.description.1": "Click on the",
    "Archive.emptyState.description.2": "to archive your files.",
    "Archive.selectedSharedJob.withMe": `Archiving is not possible, you have selected {jobsSharedWithMe, plural,
      one { # job }
      other { # jobs }
    } shared with you`,
    "Archive.selectedSharedJob.byMe": `Archiving is not possible, you have selected {jobsSharedByMe, plural,
      one { # job }
      other { # jobs }
    } shared by you`,
    "Archive.selectedSharedJob.byMe.unique": `Archiving is not possible, this job was shared by you`,
    "Archive.selectedSharedJob.both": `Archiving is not possible, you have selected {jobsSharedWithMe, plural,
      one { # job }
      other { # jobs }
    } shared with you and {jobsSharedByMe, plural,
      one { # job }
      other { # jobs }
    } shared by you`,
    "UpdateSpecifications.selectedSharedJob.withMe": `Updating the information is not possible, you have selected {jobsSharedWithMe, plural,
      one { # job }
      other { # jobs }
    } shared with you along with jobs owned by you. In order to do the update you must select either own jobs or jobs shared with you, but not both.`,
    "NotificationModal.sendMail": "Send a message",
    "NotificationModal.sendMailToEmployees": "Message employees",
    "NotificationModal.emailsSent": "Emails sent correctly",
    "NotificationModal.sendNotification": "Send notification",
    "NotificationModal.notificationSent": "Notification sent correctly",
    "NotificationModal.emailsNotSent":
      "The emails could not be sent, try again later",
    "NotificationModal.subjectPlaceholder": "Type in a subject…",
    "NotificationModal.subject": "Subject",
    "NotificationModal.email": "Email",
    "NotificationModal.message": "Message",
    "NotificationModal.selectAll": "Select all",
    "NotificationModal.receivers": "Receivers",
    "NotificationModal.modalTitle": "Send email to %si employees%sj",
    "NotificationModal.about": "about",
    "NotificationModal.notification": "Notification",
    "NotificationModal.subjectLengthErrorDescription":
      "The title of a push message cannot exceed %si characters. Resize the subject to send it as a push notification or send it as an e-mail.",
    "NotificationModal.messageLengthErrorDescription":
      "The description of a push message cannot exceed %si characters. Resize the message to send it as a push notification or just send it as an email.",
    "Documents.uploadText":
      "Click here or drag a PDF file to this area to upload. Max of {max} MB",
    "Documents.uploadDescription":
      "Support for a single or bulk upload. Strictly prohibit from\nuploading company data or other band files",
    "Documents.documentName": "Name", 
    "Documents.documentDescription": "Description",
    "Documents.documentOptions": "Actions", 
    "Documents.uploadDocument": "Upload a Document", 
    "Documents.success-upload": "Document(s) was upload successfully",
    "Documents.success-update": "Document(s) was update successfully", 
    "Documents.upload-error": "We had an error uploading the file",
    "Documents.uploader": "Documents Uploader", 
    "Documents.cancel": "Cancel",
    "Documents.save": "Save", 
    "Documents.no-data": "Not any documents to show",
    "Documents.error.tooBig": "Your file is too big!",
    "GoogleFilePicker.defaultInfo":
      "For the document to be read correctly, note that it must have the header in the first row. The date format is DD-MM-YYYY.",
    "GoogleFilePicker.textButton": "Import",
    "EmployeeRow.updateError": "Update invitation",
    "EmployeeRow.defaultError": "Oops an error has occurred, try again",
    "ScheduleModal.JobItem.notHaveJob": "is available for job",
    "ScheduleModal.JobItem.haveJob": "have a job during the job",
    "ScheduleModal.JobItem.willNotHasContract":
      "does not have a contract during the job",
    "ScheduleModal.JobItem.hasContractNoSigned":
      "does have a contract during the job but it's not signed yet",
    "ScheduleModal.filters": "Filters",
    "ScheduleModal.empty": "There are no employees with project's pool",
    "MainView.selected": "selected",
    "EditPools.hasACar": "Has a Car",
    "EditPools.hasDrivingLicense": "Has a driving license",
    "EditPools.name": "Name",
    "EditPools.name.placeholder": "Select a custom filter name",
    "EditPools.branch.placeholder": "Select function categories",
    "EditPools.trainings.placeholder": "Select training categories",
    "EditPools.qualifications.placeholder": "Select qualification categories",
    "EditPools.distance": "Distance (Km)",
    "EditPools.distance.placeholder": "Distance",
    "EditPools.distance.toggle":
      "The employees in this pool have a maximum travel distance",
    "EditPools.employee": "Employees (#)",
    "EditPools.filters": "Filters",
    "EditPools.others": "Others",
    "EditPools.created": "Pool was successfully created",
    "EditPools.updated": "Pool was successfully updated",
    "EditPools.basic": "Basic",
    "EditPools.categories": "Functions",
    "EditPools.whitelist": "Whitelist",
    "EditPools.delete.confirm":
      "Are you sure delete this employee from whitelist?",
    "EditPools.error.nameAlreadyExists": "The name is already being used.",
    "EditFunctions.function": "Function",
    "EditFunctions.created": "Function was successfully created",
    "EditFunctions.updated": "Function was successfully updated",
    "EditFunctions.basic": "Basic",
    "EditFunctions.name": "Name",
    "EditFunctions.name.placeholder": "Select a function name",
    "EditFunctions.description": "Description",
    "EditFunctions.description.placeholder": "Specify a description",
    "EditFunctions.sharing": "Sharing",
    "EditFunctions.isShareable": "Is shareable",
    "GlobalSearch.employees.noFound": "Not any employee found",
    "GlobalSearch.search": "Search for employees",
    "Availability.na": "-",
    "Availability.setForAJob":
      "What is the new availability of <strong className='avenir-next-demi-bold fz-12'>{name}</strong> on the day <strong className='avenir-next-demi-bold fz-12'>{date}</strong>?",
    "ExpirationCountdown.timeLeft": "Expires in",
    "Invitation.expiredMessage": "Expired",
    "Invitation.alreadyInvited": "{fullName} was already invited to this job",
    "StandardProject.basicData": "Project basic data",
    "StandardProject.wrongLoad": "There isn't any template project",
    "StandardProject.standardJobs": "Standard jobs",
    "StandardProject.noStandardJobs": "No standard jobs to show",
    "OnboardingInvitation.RegistrationForm.title": "Please introduce yourself",
    "OnboardingInvitation.RegistrationForm.fillInformation":
      "Please check and fill in the missing information.",
    "OnboardingInvitation.RegistrationForm.requiredFieldsNotice":
      "The input fields with a star are mandatory.",
    "OnboardingInvitation.RegistrationForm.personal": "Personal",
    "OnboardingInvitation.RegistrationForm.contact": "Contact",
    "OnboardingInvitation.RegistrationForm.credentials": "Credentials",
    "OnboardingInvitation.RegistrationForm.address": "Address",
    "OnboardingInvitation.ThanksPage.title": "Thanks, [employee_name]",
    "OnboardingInvitation.ThanksPage.notice":
      "We are happy that you have entered your profile details",
    "OnboardingInvitation.ThanksPage.whatsNext": "What’s next?",
    "OnboardingInvitation.ThanksPage.downloadApp": "Download the app directly.",
    "OnboardingInvitation.ThanksPage.descriptionApp":
      "View open services in the app and supplement your availability.<br /> If there are no outstanding services yet, view our house rules in the about us.",
    "OnboardingInvitation.loginError": "Whoops.",
    "OnboardingInvitation.invalidLink":
      "You have no access to this content, please use the link given to you through the e-mail.",
    "OnboardingEmail.sentTo":
      "[number_of_emails] emails were sent successfully",
    "OnboardingEmail.emailAlreadyTaken": "This email address is already taken",
    "Filters.apply": "Apply filters",
    "Filters.advancedApply": "Apply filters ({filters_number})",
    "Filters.allFilters": "All filters",
    "Filters.clear": "Clear my filters",
    "Filters.removeAllFilters": "Remove all filters",
    "Filters.more": "More filters",
    "Filters.refresh": "Refresh",
    "Comments.new": "Place a comment",
    "Comments.new.minLength": "Too short!",
    "Comments.new.added": "Comment added successfully",
    "Comments.new.added.error": "We had problem adding the comment",
    "Comments.new.add": "Add comment",
    "SalaryRun.invoiceRun": "Invoice run",
    "SalaryRun.title": "Salary export",
    "SalaryRun.periodAndSpecifications": "Period and specifications",
    "SalaryRun.declarations": "Declarations",
    "SalaryRun.selectRateCard": "Select the rate card to be applied (Optional)",
    "SalaryRun.select": "Select",
    "SalaryRun.exportOptions": "Export options",
    "SalaryRun.sentTo.accepted": "Send to accepted",
    "SalaryRun.sentTo.processed": "Send to processed",
    "SalaryRun.sentTo.invoiced": "Send to invoiced",
    "SalaryRun.sentTo.back": "Go back to Declarations",
    "SalaryRun.createReports": "Create reports",
    "PeriodAndSpecifications.periodExplain":
      "Please select the period that you want to create a salary run from.",
    "PeriodAndSpecifications.specificationsExplain":
      "Specify what you want to export. If you don’t make any changes, everything will be exported in the salary run.",
    "PeriodAndSpecifications.specifyTheContractTypes":
      "Specify the contract types",
    "PeriodAndSpecifications.specifyClients": "Specify the clients",
    "PeriodAndSpecifications.specifyTheProjects": "Specify the projects",
    "PeriodAndSpecifications.specifyTheBranches": "Specify the functions",
    "PeriodAndSpecifications.specifyTheQualifications":
      "Specify the qualifications",
    "PeriodAndSpecifications.specifyTheTrainings": "Specify the trainings",
    "PeriodAndSpecifications.specifyTheConnections": "Specify the connections",
    "Declarations.summaryExplain": "We created a summary of your salary run.",
    "Declarations.foundDeclarations": "Found declarations",
    "Declarations.corrections": "Corrections",
    "Declarations.noDeclarations": "No declarations",
    "Declarations.toExportOptions": "To export options",
    "Declarations.watchOutExplain":
      "We have found [number_of_shifts] shifts that haven’t been declared yet. You can choose to manually finish the workflow of these shifts and accept the declaration.",
    "Declarations.goShifts": "Go to shifts",
    "Declarations.notCorrections":
      "There are no accepted declarations before the selected period.",
    "Declarations.includeCorrections":
      "Corrections: tick the box to include possible corrections in the salary export. Corrections are declarations which for jobs which were scheduled before the selected period: [start_date] & [end_date]",
    "ExportOptions.exportExplain": "Choose what you want to export.",
    "ExportOptions.declarations": "Declarations",
    "ExportOptions.declarations.explain":
      "Export all declaration for the selected period",
    "ExportOptions.totals": "Totals",
    "ExportOptions.totals.explain": "Export the totals of the selected period",
    "ExportOptions.newEmployees": "New employees",
    "ExportOptions.newEmployees.explain":
      "Export information of the employees which had their first job",
    "ExportOptions.wageTaxRebate": "Wage tax rebate",
    "ExportOptions.wageTaxRebate.explain":
      "Export information of the employees which requested a wage tax rebate change",
    "ExportOptions.receiptImages": "Download receipts",
    "ExportOptions.receiptImages.2": "Download receipts",
    "ExportOptions.receiptImages.explain":
      "Download all receipts of the selected period in a zip file",
    "ExportOptions.allDeclarations": "Invoice ratecard export",
    "ExportOptions.allDeclarations.explain":
      "Both your internal and external employees are included",
    "ExportOptions.allDeclarations.noRateCard":
      "You have to select a ratecard first",
    "ExportOptions.allDeclarations.deselectRateCard":
      "Not possible since you have selected ratecard",
    "SentToAccepted.periodExplain":
      "Almost done! Click on the button below to send the checked declarations to Accepted and close this flow.",
    "SentToAccepted.continueAndClose": "Accept declarations and close",
    "SentToAccepted.successNotification":
      "The shifts list ([number_of_accepted]) were sent to accepted",
    "SentToAccepted.successNotificationHourOwner":
      "You do not own some of the hours you just exported. Only the ones you do own have been approved. ([number_of_accepted]) shifts were sent to accepted",
    "SentToAccepted.errorNotification":
      "We had a error sending the shifts to accepted",
    "SentToProcessed.periodExplain":
      "Almost done! Click on the button below to send the checked declarations to “Processed” and close this flow.",
    "SentToProcessed.continueAndClose": "Process declarations and close",
    "SentToProcessed.successNotification":
      "The shifts list ([number_of_processed]) were sent to processed",
    "SentToProcessed.errorNotification":
      "We had a error sending the shifts to processed",
    "SentToInvoiced.periodExplain":
      "Almost done! Click on the button below to send the checked declarations to “Invoiced” and close this flow.",
    "SentToInvoiced.continueAndClose": "Invoice declarations and close",
    "SentToInvoiced.successNotification":
      "The shifts list ([number_of_invoiced]) were sent to invoiced",
    "SentToInvoiced.errorNotification":
      "We had a error sending the shifts to invoiced",
    "HoursDataTooltip.invoicedAt": "Invoiced at",
    "HoursDataTooltip.invoicedBy": "Invoiced by",
    "HoursDataTooltip.approvedAt": "Approved at",
    "HoursDataTooltip.approvedBy": "Approved by",
    "HoursDataTooltip.approvedByLine": "[user_email] at [approved_date]",
    "HoursDataTooltip.exportedAt": "Exported at",
    "HoursDataTooltip.exportedBy": "Exported by",
    "HoursDataTooltip.exportedByLine": "[user_email] at [exported_date]",
    "HoursDataTooltip.processedBy": "Processed by",
    "HoursDataTooltip.processedAt": "Processed at",
    "HoursDataTooltip.approvedAtMin": "Approved at (Min)",
    "HoursDataTooltip.exportedAtMin": "Exported at (Min)",
    "HoursDataTooltip.approvedAtMax": "Approved at (Max)",
    "HoursDataTooltip.exportedAtMax": "Exported at (Max)",
    "HoursDataDetailsModal.firstParagraph.notShared":
      "Check & approve the hours from your employees in the internal per shift table.",
    "HoursDataDetailsModal.firstParagraph.shared": `This shift is from <b>{job_owner_tenant_name}</b> and has been filled by an employee of <b>{employee_owner_tenant_name}</b>, this means you can both edit the worked hours until they have been processed by <b>{employee_owner_tenant_name}</b>!
    </br><b>{employee_owner_tenant_name}</b> can let <b>{job_owner_tenant_name}</b> know the hours are checked by approving the hours the “internal per shift“ table.`,
    "HoursDataDetailsModal.secondParagraph.notShared":
      "When the salary period has passed, start the salary run and send the hours from accepted to processed.",
    "HoursDataDetailsModal.secondParagraph.shared": `When the salary period has passed and <b>{employee_owner_tenant_name}</b> is also working with Fleks for their planning, they should mark the hours as processed when the worked hours should be payed out to their employees.`,
    "HoursDataDetailsModal.thirdParagraph.notShared":
      "Working with different periods for invoicing and your salary run, keep track of the invoided hours in the all hours table and mark your hours as invoiced.",
    "HoursDataDetailsModal.thirdParagraph.shared": `Discuss with your connection before what time the hours should be checked and correct within Fleks. <b>{job_owner_tenant_name}</b> can mark the hours as invoiced.`,
    "PlannerEmployeeCard.title": "Employee details",
    "PlannerEmployeeCard.note.NoResult": "No job scheduled within 2 days",
    "PlannerEmployeeCard.note.Less": "Pay attention to the rest time",// 
    "PlannerEmployeeCard.note.More": "No overlapping jobs",//
    "PlannerEmployeeCard.note.Overlapping": "Attention! A job is already scheduled at this time",//
    "PlannerEmployeeCard.restTime.Equal": "The employee is already scheduled at the ", //
    "PlannerEmployeeCard.restTime.SameTimeTitle": " same time",
    "PlannerEmployeeCard.tooltipText": "The rest period of 11 hours may be shortened to at least 8 hours once every 7 days. Fleks checks 2 days before and after the shift whether the rest period has been exceeded.",
    "PlannerEmployeeCard.restTimeBefore": " rest time between the previously scheduled shift(s)",//
    "PlannerEmployeeCard.restTimeAfter": " rest time between the next scheduled shift(s)",//
    "PlannerEmployeeCard.hoursTitle": " hours",
    "PlannerEmployeeCard.minutesTitle": " minutes",
    "PlannerEmployeeCard.restTimeOverlappingBefore": " overlapping with the following scheduled shift(s)",
    "PlannerEmployeeCard.restTimeOverlappingAfter": " overlapping with the following scheduled shift(s)",
    "PlannerEmployeeCard.weeklyHours": "Hours worked / week hours",
    "PlannerEmployeeCard.experience": "Experience",
    "PlannerEmployeeCard.projectExperience": "Experience per project",
    "PlannerEmployeeCard.clientExperience": "Experience per client",
    "PlannerEmployeeCard.proposedAt": "Proposed at",
    "PlannerEmployeeCard.categories": "Functions",
    "PlannerEmployeeCard.manyHoursWorkedThisWeek":
      "Pay attention! This person is about his/her contract hours",
    "PlannerEmployeeCard.employeeWasInvited":
      "Pay attention, this person has been invited to another job",
    "PlannerEmployeeCard.employeeWasScheduled":
      "Pay attention! This person is already scheduled",
    "PlannerEmployeeCard.cancelled_by": "Cancelled by",
    "PlannerEmployeeCard.cancelled_at": "Cancelled at",
    "PlannerEmployeeCard.cancel_reasons": "Cancel Reasons",
    "PlannerEmployeeCard.created_by": `<strong>Created by: </strong>Employee`,
    "PlannerEmployeeCard.registered_by": "Registered by",
    "PlannerEmployeeCard.expirationDateValidUntil": "Valid until",
    "AvailabilityStatus.available": "Available",
    "AvailabilityStatus.notAvailable": "Not available",
    "AvailabilityStatus.partlyAvailable": "Partially available",
    "AvailabilityStatus.unknown": "Unknown",
    "AvailabilityStatus.invited": "Invited",
    "AvailabilityStatus.registered": "Registered",
    "AvailabilityStatus.scheduled": "Scheduled",
    "AvailabilityStatus.reserved": "Reserved",
    "PlannerTenantCard.title": "Connection details",
    "ClusteredJob.pendingSpot": `{spots, number} open`,
    "ClusteredJob.registered": `{spots, plural,
      =0 {registrations}
      one { # registration }
      other { # registrations }
    }`,
    "ClusteredJob.invited": `{spots, plural,
      one { # invitation }
      other { # invitations }
    }`,
    "Planning.numberOfAvailableSpots": "Open positions",
    "Planning.title": "Plan quickly",
    "Planning.cluster": "Cluster",
    "Connections.newConnection.title": "Welcome to Fleks,",
    "Connections.newConnection.description": `{tenantName} wants to exchange jobs with you via Fleks! If you accept the connection request, you can share jobs and propose candidates. Help each other by filling the empty gaps in the planning.`,
    "Connections.newConnection.paragraph1": `<b>How does sharing jobs work?</b><br/><br/>
      Once you have accepted the connection request, you will be in each other's Fleks environment as a connection. Your relation can select your company from the planner and send a "job request". You will receive a notification of this request and can then accept or refuse it. If you accept the job request, the service will appear in your planner overview. The externally jobs can be recognized by the arrow in the job card that points inwards. Click on the appropriate job card and introduce a suitable candidate to your connection.<br /><br />
      It is important that the candidate is informed in advance that the data can be shared with the party with whom a connection is made. This can be done by direct communication or by mentioning in the employment contract. The responsibility for this communication lies with the employer and Fleks only facilitates the planning process with his software.`,
    "Connections.newConnection.paragraph2": `<b>Prep yourself and start quickly!</b><br/><br/>You can make a number of preparations to make the process even smoother. Follow the following tips:<br/><br/>
      <ul>
        <li>Put your qualifications in the system</li>
        <li>Create a number of pools</li>
        <li>Add your employees to the system with a complete profile</li>
      </ul>
      
      You are now ready to introduce candidates to your connection!`,
    "Connections.newConnection.paragraph3": `<b>Want to use Fleks for your own planning?</b><br/><br/>
    If you like planning with Fleks, you can also use Fleks to fill your own services. Contact us for more information or a free demo: <a href="www.fleks.nl" target="_blank">www.fleks.nl</a>`,
    "Connections.acceptedConnection.title": "Dear connection,",
    "Connections.acceptedConnection.description": `Hooray! You have accepted your relationship's connection request!`,
    "Connections.acceptedConnection.paragraph1": `The connection with <b>{tenantName}</b> is now confirmed and you can start sharing jobs.`,
    "Connections.acceptedConnection.paragraph2": `<b>Questions about the Fleks tool?</b><br/><br/>
    If you are not yet or not sufficiently familiar with Fleks, we will gladly help you with the first introduction and steps. In this case, request a web demo at: <a href="mailto:support@fleks.info">support@fleks.info</a>. Or call us on the following telephone number: 020 244 1535.`,
    "Connections.acceptedConnection.paragraph3": `Share, propose and quickly fill your jobs with Fleks!`,
    "Connections.acceptedConnection.paragraph4": `Good luck and we wish you a good collaboration.`,
    "Connections.GoToFleks": "Go to Fleks",
    "Connections.rejectedConnection.title": "What a pity!",
    "Connections.rejectedConnection.description": `You declined the connection request from {tenantName}.`,
    "Connections.rejectedConnection.paragraph1": `<b>Not convinced yet?</b><br/><br/>
      Contact your relationship to discuss the possibilities for a collaboration. {tenantName} would like to work with you and fill the empty gaps in the planning together.`,
    "Connections.rejectedConnection.paragraph2": `<b>Questions about the system?</b><br/><br/>
    If you are not yet or not sufficiently familiar with Fleks, we will gladly help you with the first introduction and steps. In this case, request a web demo at: <a href="mailto:support@fleks.info">support@fleks.info</a>. Or call us on the following telephone number: 020 244 1535.`,
    "Connections.rejectedConnection.paragraph3": `We look forward to hearing from you!`,
    "PlanningModal.numberOfEmployeesSelected": `{numberOfEmployees, plural,
      =0 { No selected employees }
      one { # selected employee }
      other { # selected employees }
    }`,
    "PlanningModal.thereAreMoreEmployeesThanOpenSpots":
      "The number of selected employees ({numberOfEmployees}) is higher than the open spots of the job",
    "PlanningModal.maximumEmployeesProposed":
      "You have already proposed the maximum number of employees for this job",
    "PlanningModal.clustersEnabled": "Show as clusters",
    "Scheduling.moreEmployeesThanOpenSpots":
      "It's not possible to schedule more employees than the number of open spots for this job",
    "Scheduling.sentInvitations": "Sent invitations",
    "SharedJobs.share": "Share job",
    "SharedJobs.cancel": "Cancel sharing",
    "SharedJobs.status.Pending": "Pending",
    "SharedJobs.status.Accepted": "Accepted",
    "SharedJobs.status.Rejected": "Rejected",
    "SharedJobs.status.notShared": "Not shared",
    "SharedJobs.notification.shared": `{sharedJobs, plural, 
     one { # job was }
     other { # jobs were }
    } shared successfully`,
    "DashboardAvailabilityTable.numberOfEmployees": `{employees, plural, 
      =0 { - employees }
      one { # employee }
      other { # employees }
    }`,
    "DashboardAvailabilityTable.hours": `{hours, plural, 
      =0 { - hours }
      one { # hour }
      other { # hours }
    }`,
    "DashboardAvailabilityTable.hours.title": "Hours",
    "DashboardAvailabilityTable.title": "Availability",
    "DashboardAvailabilityTable.availabilityType": "Type",
    "DashboardAvailabilityTable.description": "Availability of employees",
    "DashboardAvailabilityTable.period": "Availability period",
    "ExtensiveSearch.title": "Advanced search",
    "ExtensiveSearch.isQualified": "Is qualified",
    "ExtensiveSearch.name": "Name",
    "ExtensiveSearch.sectors": "Functions",
    "ExtensiveSearch.qualifications": "Qualifications",
    "ExtensiveSearch.trainings": "Trainings",
    "Reports.unsubscribe.title": "Unsubscribe services",
    "Reports.unsubscribe.success": "You have successfully unsubscribed",
    "Reports.unsubscribe.whichOne": "Which reports do you want to disable?",
    "Reports.weeklyReport": "Weekly report",
    "Reports.dailyReport": "Daily report",
    "NewFreelancers.title": "New freelancers",
    "NewFreelancers.emptyState.title": "No new freelancers found or added",
    "NewFreelancers.emptyState.description": "Refresh the page and try again.",
    "NewFreelancers.first_name": "Name",
    "NewFreelancers.last_name": "Last name",
    "NewFreelancers.username": "Username",
    "NewFreelancers.email": "Email",
    "NewFreelancers.phoneNumber": "Phone number",
    "NewFreelancers.registrationDate": "Registration date",
    "NewFreelancers.contactTime": "Contact time",
    "NewFreelancers.all": "All",
    "NewFreelancers.verified": "{items} freelancers verified",
    "NewFreelancers.verify.confirmation": `You are about to verify {items, plural,
      one {# freelancer}
      other {# freelancers}    
    }, are you sure about executing this action?`,
    "Freelancers.title": "Freelancers",
    "Freelancers.emptyState.title": "No freelancers found or added",
    "Freelancers.emptyState.description": "Refresh the page and try again.",
    "Freelancers.first_name": "Name",
    "Freelancers.last_name": "Last name",
    "Freelancers.username": "Username",
    "Freelancers.email": "Email",
    "Freelancers.phoneNumber": "Phone number",
    "Freelancers.registrationDate": "Registration date",
    "Freelancers.all": "All",
    "Freelancers.about": "About the freelancer",
    "Freelancers.typeOfDocument": "Type of document",
    "Freelancers.documentNumber": "Document number",
    "Freelancers.expirationDate": "Expiration date",
    "Freelancers.frontsideDoc": "Frontside",
    "Freelancers.backsideDoc": "Backside",
    "Freelancers.document": "Document",
    "Freelancers.businessData": "Business data",
    "Freelancers.vat": "VAT",
    "Freelancers.kvk": "KvK",
    "Freelancers.workExperience": "Work experience",
    "Freelancers.declareValid": "Declare valid",
    "Freelancers.declareInvalid": "Declare invalid",
    "Freelancers.accept.confirmation": `You are about to accept {items, plural,
      one {# freelancer}
      other {# freelancers}    
    }, are you sure about executing this action?`,
    "Freelancers.cancel.confirmation": `You are about to cancel {items, plural,
      one {# freelancer}
      other {# freelancers}    
    }, are you sure about executing this action?`,
    "FreelancerCard.title": "Freelancer details",
    "NotificationsAndEmails.title.emails": "Emails",
    "NotificationsAndEmails.settings.jobApplied": "New application for a job",
    "NotificationsAndEmails.settings.jobRequest": "New job request",
    "NotificationsAndEmails.settings.proposedEmployee": "Proposed employee",
    "NotificationsAndEmails.settings.jobRequestAccepted":
      "Job request accepted",
    "NotificationsAndEmails.settings.jobRequestRejected":
      "Job request rejected",
    "NotificationsAndEmails.settings.proposedEmployeeAccepted":
      "Proposed employee accepted",
    "NotificationsAndEmails.settings.proposedEmployeeRejected":
      "Proposed employee rejected",
    "NotificationsAndEmails.settings.invitationAccepted": "Invitation accepted",
    "NotificationsAndEmails.settings.export": "Export file ready",
    "NotificationsAndEmails.settings.proposalAccepted": "Proposal accepted",
    "NotificationsAndEmails.settings.newApplication": "New application",
    "Pools.projects.poolDescription": `The jobs added to this project will be visible to the employees who meet the criteria below. Do you want to make the jobs visible to all the employees? Then do not add any pool. The jobs are now visible to {numberOfEmployees, plural,
      one {# employee}
      other {# employees}    
    }. Do you want to make an exception for a job? Add a pool add the job!`,
    "Pools.jobs.poolDescription": `Add a pool to the job, to specify the employees who are qualified to work here. To make the job visible to all employees or the projects pool, select no pool. This job is visible to {numberOfEmployees, plural,
      one {# employee}
      other {# employees}    
    }.`,
    "Pools.qualifiedEmployees": "Qualified employees",
    "NewApplicant.signUp": "Sign up",
    "NewApplicant.description": `Nice that you want to work for {tenant_name}! Fill in the form below and we will contact you as soon as possible.`,
    "NewApplicant.disclaimer":
      "By completing this form, I consent to the processing of personal data obtained by Fleks.",
    "NewApplicant.signMeIn": "Sign me in!",
    "NewApplicant.dear": `Dear {first_name}`,
    "NewApplicant.dearDescription":
      "We received your application. We are excited for it! Thanks for your time and interest. We will contact you back as soon as we can. You will receive a message from us.",
    "NewApplicant.sincerely": "Sincerely,",
  },
};
