import {
  USER_LOGIN,
  SET_DATABASE_TABLE_VALUES,
  SET_DATABASE_TABLE_FILTERS,
  SET_DATABASE_TABLE_APPLIED_FILTERS,
} from "fleksRedux/types";
import { combineReducers } from "redux";
import {rebuildSortKey} from "./rebuild";

const columns = (
  state = [
    "title",
    "project",
    "date",
    "times",
    "active_contract",
    "travel_time",
    "travel_distance",
    "status",
  ],
  action = {}
) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.singles !== "undefined"
        ? action.data.preferences.database.singles.columns
        : state;

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "singles" && action.data.columns
        ? action.data.columns
        : state;

    default:
      return state;
  }
};

const selectedTab = (state = "open", action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.singles !== "undefined" &&
        action.data.preferences.database.singles.selectedTab !== "incompleted"
        ? action.data.preferences.database.singles.selectedTab
        : state;

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "singles" && action.data.tab
        ? action.data.tab
        : state;

    default:
      return state;
  }
};

const page = (state = 1, action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.singles !== "undefined"
        ? action.data.preferences.database.singles.page
        : state;

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "singles" && action.data.page
        ? action.data.page
        : state;

    case SET_DATABASE_TABLE_APPLIED_FILTERS:
      return 1;

    default:
      return state;
  }
};

const size = (state = 25, action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.singles !== "undefined" &&
        action.data.preferences.database.singles.size <= 100
        ? action.data.preferences.database.singles.size
        : state;

    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "singles" && action.data.size
        ? action.data.size
        : state;

    default:
      return state;
  }
};

const sortKey = (state = "start_date", action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "singles" && action.data.sortKey
        ? action.data.sortKey
        : state;

    case USER_LOGIN:
      return action.data.preferences.database &&
        typeof action.data.preferences.database.singles !== "undefined"
        ? action.data.preferences.database.singles.sortKey
        : state;


    default:
      return state;
  }
};

const filters = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_FILTERS:
      return action.data.table === "singles"
        ? { ...state, ...action.data.filters }
        : state;

    case SET_DATABASE_TABLE_APPLIED_FILTERS:
      return action.data.table === "singles"
        ? { ...state, ...action.data.filters }
        : state;

    default:
      return state;
  }
};

const appliedFilters = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_APPLIED_FILTERS:
      return action.data.table === "singles"
        ? { ...state, ...action.data.filters }
        : state;

    default:
      return state;
  }
};

const selectedItems = (state = [], action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "singles" && action.data.items
        ? action.data.items
        : state;

    default:
      return state;
  }
};

const selectedFullItems = (state = [], action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "singles" && action.data.fullItems
        ? action.data.fullItems
        : state;

    default:
      return state;
  }
};

const reset = (state = false, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "singles" &&
        typeof action.data.reset === "boolean"
        ? action.data.reset
        : state;

    default:
      return state;
  }
};

const allListSelected = (state = false, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "singles" &&
        typeof action.data.allListSelected === "boolean"
        ? action.data.allListSelected
        : state;

    default:
      return state;
  }
};

const total = (state = 0, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "singles" &&
        typeof action.data.total === "number"
        ? action.data.total
        : state;

    default:
      return state;
  }
};

const listInternalPerEmployee = (state = false, action = {}) => {
  switch (action.type) {
    case SET_DATABASE_TABLE_VALUES:
      return action.data.table === "singles" &&
        typeof action.data.listInternalPerEmployee === "boolean"
        ? action.data.listInternalPerEmployee
        : state;

    default:
      return state;
  }
};

export const singles = combineReducers({
  columns,
  selectedTab,
  size,
  page,
  sortKey,
  filters,
  appliedFilters,
  selectedItems,
  selectedFullItems,
  reset,
  allListSelected,
  total,
  listInternalPerEmployee,
  rebuildSortKey: rebuildSortKey('singles')
});

export default singles;
